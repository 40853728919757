import { format } from "date-fns";
import { i18n } from "../translate/i18n";

const ConfigureDays = (date) => {

    let originalDate = new Date(date + ' 00:00:00');
    let today = new Date();

    let utc1 = Date.UTC(originalDate.getFullYear(), originalDate.getMonth(), originalDate.getDate());
    let utc2 = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());
    let diff = Math.floor((utc2 - utc1) / (1000 * 60 * 60 * 24));

    if (diff >= 0 && diff < 7) {
        let weekDaysToday = today.getDay();
        let weekDaysOriginal = originalDate.getDay();
        let weekDays = [i18n.t("weekDays.sun"), i18n.t("weekDays.mon"), i18n.t("weekDays.tue"), i18n.t("weekDays.wed"), i18n.t("weekDays.thu"), i18n.t("weekDays.fri"), i18n.t("weekDays.sat")];

        // verificar se a data é de hoje, ontem ou algum dia dos últimos 7
        if (weekDaysToday - weekDaysOriginal == 0) return i18n.t("weekDays.today");
        else if (weekDaysToday - weekDaysOriginal == 1 || weekDaysToday - weekDaysOriginal == -6) return i18n.t("weekDays.yesterday");
        else return weekDays[originalDate.getDay()];
    } else {
        return format((originalDate), "dd/MM/yyyy");
    }
}

export default ConfigureDays;