import { MenuItem } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

const ConfirmationModal = ({ title, open, userToDelete, onClose, onConfirm }) => {
	const { user } = useContext(AuthContext);
	const [options, setOptions] = useState([]);
	const [userToTransfer, setUserToTransfer] = useState("");
	const [deletingAllData, setDeletingAllData] = useState(false);

	const deleteUser = async () => {
		try {
			await api.post(`/usersDelete/${userToDelete}`, { userToTransfer: userToTransfer, deletingAllData: deletingAllData });
			toast.success(i18n.t("users.toasts.deleted"));
			onClose(false);
			onConfirm();
		} catch (err) {
			console.error(err);
		}
	}


	const deleteUserWithoutMigrateData = async () => {
		try {
			await api.post(`/usersDelete/${userToDelete}`, { userToTransfer: '', deletingAllData: true });
			toast.success(i18n.t("users.toasts.deleted"));
			onClose(false);
			onConfirm();
		} catch (err) {
			console.error(err);
		}
	}

	useEffect(() => {
		setUserToTransfer("")
		const delayDebounceFn = setTimeout(() => {
			const fetchUsers = async () => {
				try {
					const { data } = await api.get("/users/")
					setOptions(data.users);
				} catch (err) {
					toastError(err);
				}
			};

			fetchUsers();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [open]);

	const selectUserToTransfer = (e) => {
		setUserToTransfer(e.target.value)
	}

	return (
		<Dialog
			open={open}
			onClose={() => onClose(false)}
			aria-labelledby="confirm-dialog"
		>
			<div style={{ maxWidth: '400px' }} >
				<DialogTitle id="confirm-dialog">{title}</DialogTitle>
				<DialogContent dividers>
					<Typography>Todos os dados do usuário serão perdidos. Os atendimentos abertos deste usuário terão que ser movidos para outro usuário.</Typography>
					<div style={{ display: 'grid' }}>
						<FormControl variant="outlined" fullWidth>
							<label style={{ margin: "20px 0px 11px" }}>Perfil</label>
							<Select
								id="demo-simple-select"
								style={{ height: '38px' }}
								value={userToTransfer}
								onChange={selectUserToTransfer}
								variant="outlined"
							>

								{options.map((user, i) => {

									return (
										<MenuItem
											hidden={user.id === userToDelete ? true : false}
											style={{ padding: '10px' }}
											key={i}
											value={user.id}>
											{user.name}
										</MenuItem>
									);
								})}

							</Select>
						</FormControl>
					</div>
					<Typography style={{ marginTop: '10px' }}>Ou apague o contato sem migrar os dados.</Typography>
				</DialogContent>
				<DialogActions>
					<Button
						variant="contained"
						onClick={() => onClose(false)}
						color="default"
					>
						{i18n.t("confirmationModal.buttons.cancel")}
					</Button>
					<Button
						style={{ width: '250px' }}
						variant="contained"
						onClick={() => {
							setDeletingAllData(true); deleteUserWithoutMigrateData();
						}}
						color="secondary"
					>
						Deletar sem migrar
					</Button>
					<Button
						variant="contained"
						disabled={userToTransfer ? false : true}
						onClick={() => {
							deleteUser()
						}}
						color="primary"
					>
						Migrar
					</Button>
				</DialogActions>
			</div>

		</Dialog>
	);
};

export default ConfirmationModal;
