import { BrowserRouter, Redirect, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import LoggedInAtendimento from "../atendimento";
import { AuthProvider } from "../context/Auth/AuthContext";
import { WhatsAppsProvider } from "../context/WhatsApp/WhatsAppsContext";
import LoggedInLayout from "../layout";
import Campaign from "../pages/Campaign";
import BlackListCampaign from "../pages/Campaign/blackList";
import Chatbot from "../pages/Chatbot";
import Company from "../pages/Company/";
import CompanySignature from "../pages/CompanySignature";
import ConclusionMessages from "../pages/ConclusionMessages";
import Connections from "../pages/Connections/";
import Contacts from "../pages/Contacts/";
import Dashboard from "../pages/Dashboard/";
import DatabaseError from "../pages/Error/DatabaseError";
import PageNotFound from "../pages/Error/PageNotFound";
import Integrations from "../pages/Integration";
import Login from "../pages/Login/";
import MessagesAPI from "../pages/MessagesAPI/";
import Queues from "../pages/Queues/";
import QuickAnswers from "../pages/QuickAnswers/";
import RelatoryCampaign from "../pages/RelatoryCampaigns/";
import RelatoryGeneralCampaigns from "../pages/RelatoryGeneralCampaigns";
import RelatoryTags from "../pages/RelatoryTags";
import RelatoryTickets from "../pages/RelatoryTicket";
import Settings from "../pages/Settings/";
import Signup from "../pages/Signup/";
import Tags from "../pages/Tags";
import OpeningHours from "../pages/OpeningHours";
import Tickets from "../pages/Tickets/";
import Users from "../pages/Users";
import Route from "./Route";

const privateRoutes = [
  { path: '/', component: Dashboard },
  { path: '/chatbot', component: Chatbot },
  { path: '/connections', component: Connections },
  { path: '/contacts', component: Contacts },
  { path: '/conclusionMessages', component: ConclusionMessages },
  { path: '/companySignature', component: CompanySignature },
  { path: '/relatory/:id?', component: RelatoryCampaign },
  { path: '/ticketsRelatory', component: RelatoryTickets },
  { path: '/tagsRelatory', component: RelatoryTags },
  { path: '/company', component: Company },
  { path: '/users', component: Users },
  { path: '/tags', component: Tags },
  { path: '/openingHour', component: OpeningHours },
  { path: '/quickAnswers', component: QuickAnswers },
  { path: '/messages-api', component: MessagesAPI },
  { path: '/settings', component: Settings },
  { path: '/queues', component: Queues },
  { path: '/campaign', component: Campaign },
  { path: '/generalCampaignsRelatory', component: RelatoryGeneralCampaigns },
  { path: '/integrations', component: Integrations },
  { path: '/notFound', component: PageNotFound }
];
const ticketRoutes = [
  { path: '/tickets/:ticketId?', component: Tickets },
  { path: '/tickets/broadcast/:broadcastId?', component: Tickets }
];
const publicRoutes = [
  { path: '/login', component: Login },
  { path: '/signup', component: Signup },
  { path: '/database/notFound', component: DatabaseError },
  { path: '/blackList/:adminId?/:campaignContactId?', component: BlackListCampaign }
];

const notFound = () => {
  let to = window.location.pathname;
  if (to.substring(0, 1) == '/') to = to.substring(1);
  if (to == '') return;

  to = to.split('/');

  let tktIndex = ticketRoutes.findIndex(route => route.path.includes(to[0]));
  let pvIndex = privateRoutes.findIndex(route => route.path.includes(to[0]));
  let pbIndex = publicRoutes.findIndex(route => route.path.includes(to[0]));
  if (tktIndex == -1 && pvIndex == -1 && pbIndex == -1) return (<Redirect to={{ pathname: "/notFound" }} />);
};

const Routes = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Switch>
          {
            // verificar se a página existe
            notFound()
          }

          {
            // renderizar rotas publicas
            publicRoutes.map(route => {
              return <Route exact path={route.path} component={route.component} />
            })
          }

          <WhatsAppsProvider>
            <Route exact path={privateRoutes.map(pvRoutes => pvRoutes.path)} isPrivate>
              <LoggedInLayout>
                {privateRoutes.map(route => {
                  return <Route exact path={route.path} component={route.component} isPrivate />
                })}
              </LoggedInLayout>
            </Route>
            <Route path={['/tickets']} isPrivate>
              <LoggedInAtendimento>
                {ticketRoutes.map(route => {
                  return <Route exact path={route.path} component={route.component} isPrivate />
                })}
              </LoggedInAtendimento>
            </Route>
          </WhatsAppsProvider>
        </Switch>
        <ToastContainer autoClose={3000} />
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Routes;
