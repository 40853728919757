
import { Paper, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { AuthContext } from "../../context/Auth/AuthContext";
import { ReplyMessageProvider } from "../../context/ReplyingMessage/ReplyingMessageContext";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import BroadcastInfo from "../BroadcastInfo";
import BroadcastMessageInput from "../BroadcastMessageInput";
import BroadcastMessagesList from "../BroadcastMessagesList";
import TicketActionButtons from "../TicketActionButtons";
import TicketHeader from "../TicketHeader";


const drawerWidth = 0;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    position: "relative",
    overflow: "hidden",
  },
  ticketInfo: {
    maxWidth: "50%",
    flexBasis: "50%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "80%",
      flexBasis: "80%"
    },
  },
  buttonDesktop: {
    display: 'flex',
    position: "absolute",
    right: "5px",
    top: "20px",
    '@media(max-width: 810px)': {
      display: "none",
    }
  },
  buttonMobile: {
    display: 'none',
    position: "absolute",
    right: "5px",
    top: "20px",
    '@media(max-width: 810px)': {
      display: "flex",
    }
  },
  buttonsStyle: {
    borderRadius: "0px",
    borderColor: "black",
    color: "black",
    backgroundColor: "white",
    margin: "0px 5px 0px 5px",
  },
  ticketActionButtons: {
    maxWidth: "50%",
    flexBasis: "50%",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      flexBasis: "100%",
      marginBottom: "5px",
    },
  },

  mainWrapper: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeft: "0",
    marginRight: -drawerWidth,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  mainWrapperShift: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  filasTexto: {
    display: 'flex',
    padding: '27px',
    position: 'absolute',
    marginLeft: '28rem',
    '@media(max-width: 1250px)': {
      display: "none !important",
    }
  }
}));

const Ticket = ({ handleClose }) => {


  const { broadcastId } = useParams();

  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { user } = useContext(AuthContext);

  const [drawerContactInfoModalOpen, setDrawerContactInfoModalOpen] = useState(false);

  const [loading, setLoading] = useState(true);
  const [contacts, setContacts] = useState('');
  const [broadcast, setBroadcast] = useState({});


  //Modal Informacoes de Contato
  const handleContactInfoModalOpen = () => {
    handleCloseButton()
    setDrawerContactInfoModalOpen(true);
  };

  function handleCloseButton() {
    setAnchorEl(null);
  }

  function retornaDados(broadcast = null) {
    if (broadcast && broadcast['whatsapp.numberConnection'] && broadcast['Queue.name']) {
      document.getElementById("picConnection").src = '/media/avatars/blank.png';
      document.getElementById("definirFila").innerHTML = `${broadcast['Queue.name']} - ${broadcast['whatsapp.numberConnection']}`;
    }
  }

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchTicket = async () => {
        try {

          const { data } = await api.get(`/broadcast/${broadcastId}/true`);

          setContacts((data.contacts));
          setBroadcast(data);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };
      fetchTicket();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [broadcastId, user, history]);

  const renderMessagesList = () => {
    return (
      <>
        <BroadcastMessagesList
          broadcast={broadcast}
          broadcastId={broadcast.id}
          isGroup={false}
        >
        </BroadcastMessagesList>
        <BroadcastMessageInput broadcastId={broadcast.id} />
      </>
    )
  }

  return (
    <div className={classes.root} id="drawer-container">
      <Paper
        variant="outlined"
        elevation={0}
        className={clsx(classes.mainWrapper, {
          [classes.mainWrapperShift]: drawerContactInfoModalOpen,
        })}
      >
        <TicketHeader loading={loading}>
          {retornaDados(broadcast)}
          <div className={classes.ticketInfo} style={{ display: 'flex' }}>
            <BroadcastInfo
              contacts={contacts}
              broadcast={broadcast}
              onClick={handleContactInfoModalOpen}
            />
            <TicketActionButtons ticket={broadcast} />
          </div>
        </TicketHeader>
        <ReplyMessageProvider>
          {renderMessagesList()}
        </ReplyMessageProvider>
      </Paper>
    </div>
  );
};

export default Ticket;
