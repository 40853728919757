const CheckUrl = (str) => {
    if (!str || typeof str != 'string') return false;

    //verificar se a string é um link
    var status = false;
    var pattern_url = new RegExp('/((?:(?:http?|ftp)[s]*:\/\/)?[a-z0-9-%\/\&=?\.]+\.[a-z]{2,4}\/?([^\s<>\#%"\,\{\}\\|\\\^\[\]`]+)?)/', 'gi');
    if (pattern_url.test(str) == true) status = true;
    // verificar se é um link com ip
    if (status == false) {
        pattern_url = new RegExp(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?|^((http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])*/);
        if (pattern_url.test(str) == true) status = true;
        // verificar se é apenas um ip
        if (status == true) {
            let tmp = str.replaceAll('.', '');
            tmp = tmp.replaceAll(':', '');
            if (/^-?\d+$/.test(tmp) == true) status = false;
        }
    }

    return status;
}

export default CheckUrl;