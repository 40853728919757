import React from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n'
import App from "./app/App";
import "./style.css"


import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/style.react.scss'

ReactDOM.render(

	<CssBaseline>
		<App />
	</CssBaseline>
	,

	document.getElementById("root")
);

// ReactDOM.render(
// 	<React.StrictMode>
// 		<CssBaseline>
// 			<App />
// 		</CssBaseline>,
//   </React.StrictMode>

// 	document.getElementById("root")
// );
