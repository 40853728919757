import { Box } from '@material-ui/core';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import Highcharts from 'highcharts';
import { Pie } from 'react-chartjs-2';
import { i18n } from "../../translate/i18n";
ChartJS.register(ArcElement, Tooltip, Legend);

require("highcharts/modules/exporting")(Highcharts);

const CampaignChart = ({ data }) => {

    const chartContent = () => {
        const labels = [];
        const dataValues = [];
        const colors = [];

        data.map(d => {
            labels.push(d.status ? i18n.t(`relatoryGeneralCampaigns.campaigns.statusType.${d.status}`) : 'undefined');
            dataValues.push(d.quantity || 0);
            colors.push(d.color || '#e0e0e0')
        });

        const generalData = {
            labels,
            datasets: [
                {
                    label: '# of Votes',
                    data: dataValues,
                    backgroundColor: colors,
                    borderColor: colors,
                    borderWidth: 1,
                },
            ],
        };

        return (
            <Pie data={generalData} />
        );
    }

    return (
        <Box>
            {chartContent()}
        </Box>
    );
}

export default CampaignChart;