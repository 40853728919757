/* eslint-disable jsx-a11y/anchor-is-valid */
import ApexCharts, { ApexOptions } from 'apexcharts'
import React, { useEffect, useRef } from 'react'
import { getCSSVariableValue } from '../../../_metronic/assets/ts/_utils'

type Props = {
    className: string
    chartColor: string
    chartHeight: string
    relatorio: any
    totalContacts: string
}

const MixedWidget7: React.FC<Props> = ({ className, chartColor, chartHeight, relatorio, totalContacts }) => {
    const chartRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        if (!chartRef.current) {
            return
        }

        const chart = new ApexCharts(chartRef.current, chartOptions(chartColor, chartHeight, relatorio))
        if (chart) {
            chart.render()
        }

        return () => {
            if (chart) {
                chart.destroy()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartRef])

    return (
        <div className={`card ${className}`}>
            {/* begin::Beader */}
            <div className='card-header border-0 py-5 pb-0 pt-9'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-1'>Progresso da campanha</span>
                    <span className='text-muted fw-bold fs-7'>Relatório em tempo real</span>
                    {
                        totalContacts && 
                        <span className='text-muted fw-bold fs-6 mt-2'>
                            total de contatos: {totalContacts}
                        </span>
                    }
                </h3>
            </div>
            <div className='card-body d-flex flex-column pt-0'>
                <div className='flex-grow-1'>
                    <div ref={chartRef} className='mixed-widget-4-chart'></div>
                </div>
                <div className='pt-5'>
                    <p className='text-center fs-6 pb-5 '>
                        <span className='badge badge-light-danger fs-8'>Aviso:</span>&nbsp; As mensagens com erro de envio também entrarão na porcentagem.
                    </p>
                </div>
            </div>
            {/* end::Body */}
        </div>
    )
}

const chartOptions = (chartColor: string, chartHeight: string, relatorio: any): ApexOptions => {
    const baseColor = getCSSVariableValue('--bs-' + chartColor)
    const lightColor = getCSSVariableValue('--bs-light-' + chartColor)
    const labelColor = getCSSVariableValue('--bs-gray-700')

    return {
        series: [relatorio],
        chart: {
            fontFamily: 'inherit',
            height: chartHeight,
            type: 'radialBar',
        },
        plotOptions: {
            radialBar: {
                hollow: {
                    margin: 0,
                    size: '65%',
                },
                dataLabels: {
                    name: {
                        show: false,
                        fontWeight: '700',
                    },
                    value: {
                        color: labelColor,
                        fontSize: '30px',
                        fontWeight: '700',
                        offsetY: 12,
                        show: true,
                        formatter: function (val) {
                            return val + '%'
                        },
                    },
                },
                track: {
                    background: lightColor,
                    strokeWidth: '100%',
                },
            },
        },
        colors: [baseColor],
        stroke: {
            lineCap: 'round',
        },
        labels: ['Progress'],
    }
}

export default MixedWidget7 
