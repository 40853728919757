import { Box, Divider } from "@material-ui/core";
import generalStyles from "../GeneralValues/styles.json";
import RenderIcon from "../RenderIcon";

const StartElement = ({
    id,
    nameId,
    onEdit,
    props,
}) => {

    return (
        <Box
            onClick={(event) => { onEdit(event, nameId) }}
            component='div'
            className="showOptions"
            id={nameId}
            data-type="start"
            data-subtype="start"
        >
            <Box component='p' className="iconHeaderObject">
                <RenderIcon type={"start"} />
            </Box>
            <Box component="p" className="headerObject" style={generalStyles.title}>
                {props.isReceptive == false ? 'Mensagem Inicial - Campanha' : props?.title ? props.title : `Inicio do fluxo ${id}`}
            </Box>
            <Divider className="headerDivider" />

            <Box
                component='div'
                style={generalStyles.message}
            >
                <Box component='p' className="bodyObject" style={{ margin: '5px', display: "none" }}>
                    {props?.message || 'Inicio do fluxo'}
                </Box>
                <Box
                    component="p"
                    className="endOption"
                    style={{ display: 'none' }}
                >
                    {0}
                </Box>
            </Box>
        </Box>
    );
}

export default StartElement;