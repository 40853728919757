import JsonParse from "./JsonParse";

// Helper function to get the current URL path
function getUrlTicketPath() {
    const url = window.location.href;
    const partesDaURL = url.split('/');
    return partesDaURL[partesDaURL.length - 1];
}

const currentUUID = getUrlTicketPath();

// Event emitter
const eventEmitter = {
    events: {},
    on(eventName, listener) {
        if (!this.events[eventName]) {
            this.events[eventName] = [];
        }
        this.events[eventName].push(listener);
    },
    emit(eventName, data) {
        if (this.events[eventName]) {
            this.events[eventName].forEach(listener => listener(data));
        }
    }
};

// Helper function to clear expired sketches
function checkAndClearExpiredSketches(sketches) {
    const currentTime = Date.now();
    const twentyFourHours = 24 * 60 * 60 * 1000;

    return sketches.filter(sketch => {
        const timeDifference = currentTime - sketch.timestamp;
        return timeDifference <= twentyFourHours;
    });
}

// Function to initialize sketches from localStorage
function initializeSketches() {
    let savedSketches = JsonParse(localStorage.getItem('sketches')) || [];
    savedSketches = checkAndClearExpiredSketches(savedSketches);
    localStorage.setItem('sketches', JSON.stringify(savedSketches));
    return savedSketches;
}

// Function to save a sketch
function saveSketch(id, text) {
    let sketches = JsonParse(localStorage.getItem('sketches'));
    if (sketches == null) sketches = [];
    const timestamp = Date.now();

    if (text === "" || text === null) {
        // deleteSketch(id);
        // return;
    } else {
        const newSketch = { id, text, timestamp };
        const existingIndex = sketches.findIndex(sketch => sketch.id === id);
        if (existingIndex !== -1) {
            sketches[existingIndex] = newSketch;
        } else {
            sketches.push(newSketch);
        }
    }

    localStorage.setItem('sketches', JSON.stringify(sketches));
    eventEmitter.emit('sketchSaved', { id, text, timestamp });
}

// Function to delete a sketch
function deleteSketch(id) {
    let sketches = JSON.parse(localStorage.getItem('sketches')) || [];
    if (!Array.isArray(sketches) || sketches.length == 0) return;

    sketches = sketches.filter(sketch => sketch.id != id);
    localStorage.setItem('sketches', JSON.stringify(sketches));
    eventEmitter.emit('sketchDeleted', { id });
}

// Function to start auto clear
function startAutoClear(interval = 3600000) {
    const intervalId = setInterval(() => {
        const sketches = JsonParse(localStorage.getItem('sketches')) || [];
        const updatedSketches = checkAndClearExpiredSketches(sketches);
        localStorage.setItem('sketches', JSON.stringify(updatedSketches));
        eventEmitter.emit('sketchesUpdated', updatedSketches);
    }, interval);

    return () => clearInterval(intervalId); // Return a function to clear the interval
}

// Start auto clear on script load
const stopAutoClear = startAutoClear();

// Initialize sketches on script load
const initialSketches = initializeSketches();

// Expose functions and initial state for external usage
export { saveSketch, deleteSketch, eventEmitter, initialSketches, stopAutoClear };
