import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogTitle,
    makeStyles
} from "@material-ui/core";
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { green } from "@material-ui/core/colors";
import { withStyles } from '@material-ui/core/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { Box, DialogContent, Grid, IconButton, MenuItem, Select, Switch, TextField, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";
import IntegrationFieldTab from "./Tabs/Fields";
import IntegrationHeaderTab from "./Tabs/Headers";
import IntegrationCaptureTab from "./Tabs/Captures";

const useStyles = makeStyles((theme) => ({
    root: {
        flexWrap: "wrap",
        maxWidth: '500px'
    },
    textField: {
        marginRight: theme.spacing(1),
        width: "100%",
    },

    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    textTagContainer: {
        width: "100%",
    },
    tabs: {
        "& .MuiTabs-indicator": {
            backgroundColor: "#ffffff"
        }
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`interation-tabpanel-${index}`}
            aria-labelledby={`interations-${index}`}
            component="div"
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </Box>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `interation-tab-${index}`,
        'aria-controls': `interation-tabpanel-${index}`,
    };
}


const isObjectEmpty = (objectName) => {
    return (
        objectName &&
        Object.keys(objectName).length === 0 &&
        objectName.constructor === Object
    );
};

const ChatbotIntegrationModal = ({ integrationData, open, onClose, onConfirm, type = 'webhook' }) => {

    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(false);
    const [submittingContent, setSubmittingContent] = useState(false);
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const integrationNames = {
        webhook: ['SERVICE_INTERGATION'],
        api: []
    };

    const integrationMethods = {
        webhook: ['GET', 'POST'],
        api: ['GET', 'POST']
    };

    const initialState = {
        id: null,
        name: integrationNames[type][0],
        url: '',
        method: integrationMethods[type][0],
        body: 'JSON',
        type,
        auth: { option: 'NOAUTH', user: '', password: '' },
        headers: [
            { key: "Access-Control-Allow-Origin", value: "*" },
            { key: "Access-Control-Allow-Headers", value: "Authorization" },
        ],
        fields: [],
        captures: []
    };

    const [integration, setIntegration] = useState(!isObjectEmpty(integrationData) ? integrationData : initialState);


    useEffect(() => {

        const isIntegrationDataDifferent = Object.keys(integrationData).some(key => {
            return integrationData[key] !== initialState[key];
        });

        if (!isObjectEmpty(integrationData) && isIntegrationDataDifferent) {
            setIntegration(integrationData);
        }

    }, [integrationData]);

    // validação de campos
    const CheckWebhookSchema = Yup.object().shape({
        name: Yup.string()
            .required(i18n.t(`integrations.webhook.form.validations.nameRequired`))
            .min(5, i18n.t(`integrations.webhook.form.validations.nameMin`)),
        method: Yup.string()
            .required(i18n.t(`integrations.webhook.form.validations.methodRequired`))
            .min(2, i18n.t(`integrations.webhook.form.validations.methodMin`)),
        url: Yup.string()
            .required(i18n.t(`integrations.webhook.form.validations.urlRequired`))
            .min(15, i18n.t(`integrations.webhook.form.validations.urlMin`)),
        authOption: Yup.string()
            .required(i18n.t(`integrations.webhook.form.validations.authOptionRequired`))
            .min(4, i18n.t(`integrations.webhook.form.validations.authOptionMin`)),
        authUser: Yup.string()
            .test(
                "check-user",
                i18n.t(`integrations.webhook.form.validations.authUserRequired`),
                async value => {
                    if (integration.auth?.option == 'NOAUTH') return true;
                    return value;
                }
            ),
        authPassword: Yup.string()
            .test(
                "check-user",
                i18n.t(`integrations.webhook.form.validations.authPasswordRequired`),
                async value => {
                    if (integration.auth?.option == 'NOAUTH') return true;
                    return value;
                }
            ),
    });
    // reordenar para seguir sequencia correta
    if (CheckWebhookSchema['_nodes']) CheckWebhookSchema['_nodes'] = CheckWebhookSchema['_nodes'].reverse();

    // Handlers
    const handleClose = () => {
        setValue(0);
        onClose();
    };

    const handleSave = async () => {
        // validações
        let validate = false;
        try {
            if (type == 'webhook') {
                const validateData = {
                    name: integration.name,
                    method: integration.method,
                    url: integration.url,
                    authOption: integration.auth.option,
                    authUser: integration.auth.user,
                    authPassword: integration.auth.password,
                };
                await CheckWebhookSchema.validate(validateData);
                validate = true;
            }

            onConfirm(integration)

            toast.success(i18n.t(`integrations.webhook.form.validations.success`));
            handleClose();
        } catch (err) {
            if (validate == false) toastError({ response: { data: { error: err?.message || 'INTERNAL_ERROR' } } });
            else {
                toastError(err);
            }
            return;
        } finally {
            setSubmittingContent(false);
        }
    }

    // Switch customizado
    const AntSwitch = withStyles((theme) => ({
        root: {
            width: 28,
            height: 16,
            padding: 0,
            display: 'flex',
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            '&$checked': {
                transform: 'translateX(12px)',
                color: theme.palette.common.white,
                '& + $track': {
                    opacity: 1,
                    backgroundColor: theme.palette.primary.main,
                    borderColor: theme.palette.primary.main,
                },
            },
        },
        thumb: {
            width: 12,
            height: 12,
            boxShadow: 'none',
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    }))(Switch);

    // Renderização
    return (
        <div className={classes.root}>
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth scroll="paper" disableEscapeKeyDown={true} disableBackdropClick={true}>
                <Formik
                    initialValues={integration}
                    enableReinitialize={true}
                    onSubmit={(values, actions) => {
                        setSubmittingContent(true);
                        handleSave();
                    }}
                >
                    {({ values, errors, touched }) => (
                        <Form style={{ padding: "10px" }}>
                            <DialogContent>
                                <Field
                                    as={TextField}
                                    type="hidden"
                                    name="adminId"
                                    hidden={true}
                                />
                                {/* método e url */}
                                <Grid container spacing={2}>
                                    <Grid item xs={4} mb={2}>
                                        <Typography component="label">
                                            {i18n.t(`integrations.${type}.form.method`)}
                                            <Typography component="span" className="text-danger ml-2">*</Typography>
                                        </Typography>
                                        <Field
                                            style={{
                                                height: '45px'
                                            }}
                                            className='form-control'
                                            variant="outlined"
                                            as={Select}
                                            value={integration.method || ''}
                                            onChange={(e) => setIntegration({ ...integration, method: e.target.value })}
                                        >
                                            {integrationMethods[type].map(m => (
                                                <MenuItem value={m}>{m}</MenuItem>
                                            ))}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={8} mb={2}>
                                        <Typography component="label">
                                            {i18n.t(`integrations.${type}.form.url`)}
                                            <Typography component="span" className="text-danger ml-2">*</Typography>
                                        </Typography>
                                        <Field
                                            style={{
                                                borderColor: '#c4c4c4',
                                                borderRadius: '3px'
                                            }}
                                            className='form-control'
                                            placeholder={i18n.t(`integrations.${type}.form.url`)}
                                            maxLength={255}
                                            value={integration.url || ''}
                                            onChange={(e) => setIntegration({ ...integration, url: e.target.value })}
                                        />
                                    </Grid>
                                </Grid>

                                {/* autenticação */}
                                <Grid container spacing={2}>
                                    <Grid item xs={4} mb={2}>
                                        <Typography component="label">
                                            {i18n.t(`integrations.${type}.form.auth.option`)}
                                            <Typography component="span" className="text-danger ml-2">*</Typography>
                                        </Typography>
                                        <Field
                                            style={{
                                                height: '45px'
                                            }}
                                            className='form-control'
                                            variant="outlined"
                                            as={Select}
                                            value={integration.auth?.option || ''}
                                            onChange={(e) => setIntegration({ ...integration, auth: { ...integration.auth, option: e.target.value } })}
                                        >
                                            <MenuItem value="NOAUTH">NO AUTH</MenuItem>
                                            <MenuItem value="BASIC">BASIC</MenuItem>
                                        </Field>
                                    </Grid>
                                    <Grid item xs={4} mb={2}>
                                        <Typography component="label">
                                            {i18n.t(`integrations.${type}.form.auth.user`)}
                                        </Typography>
                                        <Field
                                            style={{
                                                borderColor: '#c4c4c4',
                                                borderRadius: '3px'
                                            }}
                                            className='form-control'
                                            placeholder={i18n.t(`integrations.${type}.form.auth.user`)}
                                            maxLength={50}
                                            value={integration.auth?.user || ''}
                                            onChange={(e) => setIntegration({ ...integration, auth: { ...integration.auth, user: e.target.value } })}
                                            disabled={integration.auth.option == 'NOAUTH'}
                                        />
                                    </Grid>
                                    <Grid item xs={4} mb={2} style={{ position: "relative" }}>
                                        <Typography component="label">
                                            {i18n.t(`integrations.${type}.form.auth.password`)}
                                        </Typography>
                                        <Field
                                            style={{
                                                borderColor: '#c4c4c4',
                                                borderRadius: '3px'
                                            }}
                                            className='form-control'
                                            placeholder={i18n.t(`integrations.${type}.form.auth.password`)}
                                            maxLength={50}
                                            type={showPassword ? 'text' : 'password'}
                                            value={integration.auth?.password || ''}
                                            onChange={(e) => setIntegration({ ...integration, auth: { ...integration.auth, password: e.target.value } })}
                                            disabled={integration.auth.option == 'NOAUTH'}
                                        />
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                            style={{
                                                position: "absolute",
                                                top: "38px",
                                                right: "10px",
                                            }}
                                        >
                                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </Grid>
                                </Grid>

                                {/* Tipo de envio dos campos */}
                                <Grid container spacing={2}>
                                    <Grid item xs={4} mb={2}>
                                        <Grid item style={{ display: "inline-block" }}>QueryString</Grid>
                                        <Grid item style={{ display: "inline-block" }}>
                                            <AntSwitch
                                                checked={integration.body == 'JSON'}
                                                onChange={(e) => setIntegration({ ...integration, body: e.target.checked ? 'JSON' : 'QueryString' })}
                                                name="body"
                                            />
                                        </Grid>
                                        <Grid item style={{ display: "inline-block" }}>JSON</Grid>
                                    </Grid>
                                </Grid>

                                {/* cabeçalhos e campos extras */}
                                <Grid container spacing={2}>
                                    <Grid item xs={12} mb={2}>
                                        <Box
                                            component="div"
                                            style={{ height: "350px", overflowY: "auto" }}
                                        >
                                            <AppBar
                                                position="sticky"
                                                className={classes.tabs}
                                            >
                                                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                                                    <Tab label={i18n.t(`integrations.${type}.form.capture`)} {...a11yProps(0)} />
                                                    <Tab label={i18n.t(`integrations.${type}.form.headers`)} {...a11yProps(1)} />
                                                    <Tab label={i18n.t(`integrations.${type}.form.fields`)} {...a11yProps(2)} />
                                                </Tabs>
                                            </AppBar>
                                            <TabPanel value={value} index={0}>
                                                <IntegrationCaptureTab integrationData={integration} setIntegrationData={setIntegration} />
                                            </TabPanel>
                                            <TabPanel value={value} index={1}>
                                                <IntegrationHeaderTab integrationData={integration} setIntegrationData={setIntegration} />
                                            </TabPanel>
                                            <TabPanel value={value} index={2}>
                                                <IntegrationFieldTab integrationData={integration} setIntegrationData={setIntegration} />
                                            </TabPanel>
                                        </Box>
                                    </Grid>
                                </Grid>

                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="secondary" disabled={submittingContent} variant="outlined">
                                    {i18n.t(`integrations.${type}.buttons.cancel`)}
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={submittingContent}
                                    variant="contained"
                                >
                                    {i18n.t(`integrations.${type}.buttons.save`)}
                                    {submittingContent && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    );
};

export default ChatbotIntegrationModal;