import { FormControlLabel, FormGroup } from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
	chips: {
		display: "flex",
		flexWrap: "wrap",
	},
	chip: {
		margin: 2,
	},
}));

const QueueSelect = ({ selectedQueueIds, onChange,}) => {
	const [queues, setQueues] = useState([]);
	const [selectedQueues, setSelectedQueues] = useState(selectedQueueIds);

	useEffect(() => {
		if(selectedQueueIds === selectedQueues) return;
		setSelectedQueues(selectedQueueIds);
	  }, [selectedQueueIds]);

	useEffect(() => {
		(async () => {
			try {
				const { data } = await api.get("/queue");
				setTimeout(() => {
					setQueues(data);
				}, 300);
			} catch (err) {
				toastError(err);
			}
		})();
	}, []);

	const handleChange = () => {
		var box = [];
		var boxHandle = document.querySelectorAll("#queue_select input[type=checkbox]");
		for (var i = 0; i < boxHandle.length; i++) {
			if (boxHandle[i].value != '') {
				var name = boxHandle[i].value;
				var value = boxHandle[i].checked;
				if (value) {
					box.push(name);
				}
			}
		}
		setSelectedQueues(box)
		onChange(box);
	}

	return (
		<div style={{ marginTop: 6 }}>
			<label style={{ margin: '0px' }}>
				{i18n.t("connections.form.queue")}
				<span className="text-danger ml-2">*</span>
			</label>
			<FormControl fullWidth margin="dense" required variant="outlined"
				style={{ width: '100%', margin: '0px' }}
			>
				{/*<InputLabel>{i18n.t("queueSelect.inputLabel")}</InputLabel>*/}
				<FormGroup id="queue_select" style={{ display: 'contents' }}>
					{
						queues.map(queue => (
							<FormControlLabel 
								color="success" 
								style={{ margin: '0px' }} 
								control={
									<Checkbox 
										defaultChecked={selectedQueues.includes(queue.id)} 
										id={queue.name} 
										name={queue.name} 
										onChange={e => handleChange(e)}
										value={queue.id} />
									}
								label={queue.name} />
						))
					
					}
				</FormGroup>
			</FormControl>
		</div>

		
	);
	}; 
	


export default QueueSelect;
