const FilteredTickets = (tickets, tagsFilter, whatsappNumbers, user, queues) => {

    return tickets.filter(t => {

        // filtrar etiquetas
        if ((t.Contact.tagsId && t.Contact.tagsId.length > 0) || t.Contact.tagsId == null) {
            const hasMatchingTag = t.Contact.tagsId ? t.Contact.tagsId.some(tag => tagsFilter.includes(tag.id)) : tagsFilter.includes(null);
            if (!hasMatchingTag) return false;
        }

        // verificar atendente
        if (t?.userId && t?.userId != user.id) return false;
        
        // verificar conexão
        if (whatsappNumbers.includes(t.numberConnection) == false) return false;

        // verificar fila
        if (queues.includes(t.queueId) == false) return false;

        return true;
    });
}

export default FilteredTickets;