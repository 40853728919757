/* eslint-disable jsx-a11y/anchor-is-valid */
import ApexCharts, { ApexOptions } from 'apexcharts';
import { format } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { getCSSVariableValue } from '../../../_metronic/assets/ts/_utils';

type Props = {
  className: string
  chartColor: string
  chartHeight: string
  relatorio: string
  title: string
  subtitle: string,
  all_relatory: Array<any>
}

function MontarDias(dataEnvio: any) {
  var semana = ["Domingo", "Segunda-Feira", "Terça-Feira", "Quarta-Feira", "Quinta-Feira", "Sexta-Feira", "Sábado"];
  var diaSemana = [6, 5, 4, 3, 2, 1, 0];
  var dia = new Date();
  var dias = diaSemana[dia.getDay()]
  for (let i = 0; diaSemana[i] !== dias; i + 1) {
    diaSemana.push(diaSemana.splice(0, 1)[0])
  }
  var diasDaSemana = ['Hoje', 'Ontem', semana[diaSemana[2]], semana[diaSemana[3]], semana[diaSemana[4]], semana[diaSemana[5]], semana[diaSemana[6]]
  ];

  function dateDiferencaEmDias(a: any, b: any) {
    a = new Date(a)
    b = new Date(b)
    var utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    var utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
    var diasTotais = Math.floor((utc2 - utc1) / (1000 * 60 * 60 * 24));
    var diasRetorno;
    if (diasTotais >= 0 && diasTotais < 7) {
      diasRetorno = diasDaSemana[diasTotais]
    } else {
      diasRetorno = format((new Date(dataEnvio)), "dd/MM/yyyy")
    }
    return diasRetorno
  }
  var formatarData = new Date(dataEnvio)
  return dateDiferencaEmDias(formatarData, dia)
}

const MixedWidget11: React.FC<Props> = ({ className, chartColor, chartHeight, all_relatory, relatorio, title, subtitle }) => {
  const [relatory, setRelatory] = useState([{ 'open': 0, 'closed': 0, 'pending': 0, 'dates': 0 }]);

  const chartRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(chartColor, chartHeight, relatorio, all_relatory))
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef])

  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body p-0 d-flex justify-content-evenly flex-column overflow-hidden'>
        {/* begin::Hidden */}
        <div className='d-flex flex-stack flex-wrap px-9 pt-9 pb-3'>
          <div className='me-2'>
            <span className='fw-bolder text-gray-800 d-block fs-3'>{title}</span>
            <span className='text-gray-400 fw-bold'>{subtitle}</span>
          </div>

          <div className={`fw-bolder fs-3 text-${chartColor}`}></div>
        </div>
        {/* end::Hidden */}

        {/* begin::Chart */}
        <div ref={chartRef} className='mixed-widget-10-chart'></div>
        {/* end::Chart */}
      </div>
    </div>
  )
}

const chartOptions = (chartColor: string, chartHeight: string, relatorio: string, all_relatory: Array<any>): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const secondaryColor = getCSSVariableValue('--bs-gray-300')
  const baseColor = getCSSVariableValue('--bs-' + chartColor)



  var open: any[] = [];
  var pending: any[] = [];
  var closed: any[] = [];
  var dates: any[] = [];

  if (all_relatory.length > 0) {
    all_relatory.forEach(relatorio => {
      dates.push(relatorio.day)
      open.push(relatorio.status.open)
      pending.push(relatorio.status.pending)
      closed.push(relatorio.status.closed)
    });
  }

  return {
    series: [
      {
        name: 'Caixa de entrada',
        data: open,
      },
      {
        name: 'Aguardando retorno',
        data: pending,
      },
      {
        name: 'Finalizados',
        data: closed,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: chartHeight,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '50%',
        borderRadius: 5,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: dates,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      type: 'solid',
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return '' + val + '';
        },
      },
    },
    colors: ["#0068D6", "#E00000", "#BDBDBD"],
    grid: {
      padding: {
        top: 10,
      },
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}

export default MixedWidget11
