import Avatar from "@material-ui/core/Avatar";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Drawer from "@material-ui/core/Drawer";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import useQueues from "../../hooks/useQueues";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import ButtonWithSpinner from "../ButtonWithSpinner";
import { Tooltip } from "@mui/material";

const filterOptions = createFilterOptions({
	trim: true,
});

const drawerWidth = 320;
const useStyles = makeStyles(theme => ({
	drawer: {
		width: "0px",
		flexShrink: 0,
		transitionDuration: "0.3s"
	},
	maxWidth: {
		width: "100%",
	},
	drawerPaper: {
		width: drawerWidth,
		display: "flex",
		borderTop: "1px solid rgba(0, 0, 0, 0.12)",
		borderRight: "1px solid rgba(0, 0, 0, 0.12)",
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		borderTopRightRadius: 4,
		borderBottomRightRadius: 4,
	},


	header: {
		display: "flex",
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		backgroundColor: "#eee",
		alignItems: "center",
		padding: theme.spacing(0, 1),
		minHeight: "73px",
		justifyContent: "flex-start",
	},
	content: {
		display: "flex",
		backgroundColor: "#eee",
		flexDirection: "column",
		padding: "8px 0px 8px 8px",
		height: "100%",
		overflowY: "scroll",
		...theme.scrollbarStyles,
	},

	contactAvatar: {
		margin: 15,
		width: 100,
		height: 100,
	},

	contactHeader: {
		display: "flex",
		padding: 8,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		"& > *": {
			margin: 4,
		},
	},

	contactDetails: {
		marginTop: 8,
		padding: 8,
		display: "flex",
		flexDirection: "column",
	},
	contactExtraInfo: {
		marginTop: 4,
		padding: 6,
	},
	button100: {
		width: "100%"
	},
	userState: {
		height: '7px', 
		padding: '7px', 
		borderRadius: '100%', 
		marginRight: '5px',
		boxShadow: 'box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;background-color: rgb(66, 66, 66)'
	}

}));

const ContactDrawer = ({ open, handleDrawerClose, onClose, contact, ticket, userTranfer, }) => {
	const history = useHistory();
	const [options, setOptions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [queues, setQueues] = useState([]);
	const [allQueues, setAllQueues] = useState([]);
	const [selectedQueue, setSelectedQueue] = useState('disabled');
	const [searchParam, setSearchParam] = useState("");
	const [selectedQueueFromFilas, setSelectedQueueFromFilas] = useState('disabled');
	const [mensagemDoSistema, setMensagemDoSistema] = useState("Selecione um usuário");
	const [selectedUser, setSelectedUser] = useState(null);
	const { findAll: findAllQueues } = useQueues();
	const [filasUsuario, setFilasUsuario] = useState([]);
	const [filas, setFilas] = useState([]);
	const [loadingQueues, setLoadingQueues] = useState(true);
	const [loadingQueuesFind, setLoadingQueuesFind] = useState(true);
	const [queuesList, setQueuesList] = useState([]);
	const [value, setValue] = useState('1');
	const classes = useStyles();

	useEffect(() => {
		const loadQueues = async () => {
			const list = await findAllQueues();
			setAllQueues(list);
			setQueues(list)
		}
		loadQueues();

	}, []);

	useEffect(() => {
		if (!open) {
			setLoading(false);
			return;
		}
		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchUsers = async () => {
				try {
					const { data } = await api.get("/usersList/");
					setOptions(data);
					setLoading(false);
				} catch (err) {
					setLoading(false);
					toastError(err);
				}
			};
			fetchUsers();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [searchParam, open]);


	const handleChange = (event, newValue) => {
		setValue(newValue);
	};


	const handleSaveTicket = async e => {
		e.preventDefault();
		if (!ticket.id) return;
		if (selectedQueue === "disabled" && filasUsuario.length !== 1) {
			toast.error("Selecione uma fila para transferir.")
			return;
		}
		setLoading(true);
		try {
			let data = {};
			data.transfer = 1
			if (selectedUser) {
				data.userId = selectedUser.id
			}
			if (selectedQueue && selectedQueue !== null) {
				data.queueId = filasUsuario.length === 1 ? filasUsuario[0].id : selectedQueue

				if (!selectedUser) {
					data.status = 'open';
					data.userIdTranfer = userTranfer
				}
			}

			await api.put(`/tickets/${ticket.id}`, data);
			document.getElementById("definirFila").innerHTML = "";
			setLoading(false);
			history.push(`/tickets`);
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
	};
	if (open) {
		if (window.innerWidth > 560) {
			document.querySelector("#ModalTransfer").style = "width: 320px !important;"
		}
	}
	function modalTransferClose() {
		document.querySelector("#ModalTransfer").style = "width: 0px !important;"
	}
	function isOnline(check) {
		let statusColor;
		let key = new Date().getTime();

		switch (String(check).toLocaleLowerCase()) {
			case 'online':
				statusColor = '#4caf50';
				break;
			case 'out':
				statusColor = '#ff9800';
				break;
			default:
				statusColor = '#f44336'; 
				break;
		}

		return <Typography
			component="span"
			key={key}
			className={classes.userState}
			style={{ background: statusColor }} 
		/>;
	}

	const findQueuesForUser = (user) => {
		setLoadingQueues(true);
		setMensagemDoSistema("Selecione um usuário");
		if (!user) return;
		setTimeout(async () => {
			try {
				const { data } = await api.get(`/userQueues/${user.id}`);
				setFilasUsuario(data.queues);
				setLoadingQueues(false)
				setMensagemDoSistema("Selecione uma Fila");
			} catch (err) {
				toastError(err);
			}
		}, 500);
	}

	const findQueues = async (queues) => {
		setQueuesList(queues);
		try {
			const { data } = await api.get("/all_queue/");
			setFilas(data);
			setLoadingQueuesFind(true);
			setMensagemDoSistema("Selecione uma Fila");

		} catch (err) {
			toastError(err);
		}
	};

	useEffect(() => {
		findQueues().then((queues) => {
			setQueuesList(queues);
		});
	}, []);

	useEffect(() => {
		if (open) {
			document.addEventListener("mouseup", function (e) {
				var obj = document.getElementById("ModalTransfer");
				if (obj) {

					var clickedElement = (obj.contains(e.target) || e.target.id === `ModalTransfer` || e.target.tagName === 'LI');
					if (clickedElement === false) {
						handleDrawerClose(e);
						modalTransferClose(e);
					}
				}
			});
		}
	}, [open]);

	const selecionarFila = (e) => {
		setSelectedQueue(e.target.value);
	}


	const handleSelectChange = (value) => {
		setSelectedQueue(value);
	};

	return (
		<Drawer
			className={classes.drawer}
			variant="persistent"
			anchor="right"
			id="ModalTransfer"
			open={open}
			PaperProps={{ style: { position: "absolute" } }}
			BackdropProps={{ style: { position: "absolute" } }}
			ModalProps={{
				container: document.getElementById("drawer-container"),
				style: { position: "absolute" },
			}}
			classes={{
				paper: classes.drawerPaper,
			}}
		>
			<div className={classes.header}>
				<IconButton
					id="closeModalTransfer"
					onClick={(e) => {
						handleDrawerClose(e);
						modalTransferClose(e);
					}
					}>
					<CloseIcon />
				</IconButton>
				<Typography style={{ justifySelf: "center" }}>
					{i18n.t("contactDrawer.headerTransfer")}
				</Typography>
			</div>
			<div className={classes.content}>
				<Paper square variant="outlined" className={classes.contactHeader}>
					<Avatar
						alt={contact.name}
						src={contact.profilePicUrl}
						className={classes.contactAvatar}
					></Avatar>
					<Typography>{contact.name}</Typography>
					<Typography>
						<Link href={`https://api.whatsapp.com/send?phone=${contact.number}`}>{contact.number}</Link>
					</Typography>
				</Paper>
				<TabContext value={value}>
					<Paper square variant="outlined" className={classes.contactDetails} scroll="paper">
						<Box style={{ borderBottom: 1, borderColor: 'divider' }}>
							<TabList onChange={handleChange} aria-label="lab API tabs example" >
								<Tab label=" Fila" value="2" />
								<Tab label=" Atendente" value="1" />
							</TabList>
						</Box>
						<TabPanel value="1" >

							<DialogTitle id="form-dialog-title"
								style={{ padding: '25px', marginTop: -40, marginLeft: -20 }}>
								{i18n.t("Transferir usuário e fila")}
							</DialogTitle>
							<form onSubmit={handleSaveTicket}>
								<DialogContent
									style={{ padding: '6px', marginTop: -20 }}
								>
									<label style={{ marginBottom: '10px' }}>Digite para buscar usuários</label>
									<Autocomplete
										style={{ width: '100%', marginBottom: 5, }}
										getOptionLabel={option => `${option.name}`}
										options={options}
										freeSolo
										id="ModalOpening"
										autoHighlight
										padding={5}
										loading={loading}
										onChange={(e, newValue) => {
											findQueuesForUser(newValue);
											setSelectedUser(newValue);
											findQueues(newValue);
										}}
										renderOption={(option) => (
											<Tooltip
												title={<Typography gutterBottom color="inherit">{i18n.t(`users.states.${option.state}`)}</Typography>}
												placement="bottom"
											>
												<React.Fragment>
													{isOnline(option.state)}{option.name}
												</React.Fragment>
											</Tooltip>
										)}
										renderInput={(params) =>
											<TextField
												{...params}
												variant="outlined"
												InputProps={{
													style: {
														padding: '0px 0px 25px 0px',
														height: '30px',
														fontSize: '13px',
													},
													...params.InputProps,
													endAdornment: (
														<React.Fragment>
															{loading ? (
																<CircularProgress color="inherit" size={20} />
															) : null}
															{params.InputProps.endAdornment}
														</React.Fragment>
													),
												}}
											/>}
									/>
									<FormControl variant="outlined" required className={classes.maxWidth}>
										<label style={{ margin: '5px 0px 25px 0px' }}>Selecione uma fila</label>
										{filasUsuario.length === 1 ?
											<>
												<div style={{ border: '1px solid #c4c4c4', borderRadius: '5px', marginTop: '-20px' }}>
													<p style={{ padding: '15px 0px 0px 10px', fontSize: '15px' }}>
														{filasUsuario[0].name}
													</p>
												</div>
											</> :
											<>
												<Select
													disabled={loadingQueues}
													value={selectedQueue}
													defaultValue={'disabled'}
													onChange={selecionarFila}
													placeholder='Selecione uma fila'
													inputProps={{ padding: 0 }}
													style={{
														padding: '0px 0px 4px 0px',
														margin: '-15px 1px -2px',
														height: '30px',
														width: '100%',
														fontSize: '13px'
													}}
												>
													<MenuItem value={'disabled'} disabled>{mensagemDoSistema}</MenuItem>
													{filasUsuario.map((fila) => (
														<MenuItem value={fila.id}>{fila.name}</MenuItem>
													))}
												</Select>

											</>
										}
									</FormControl>
								</DialogContent>
								<DialogActions dividers>
									<ButtonWithSpinner
										className={classes.button100}
										variant="contained"
										type="submit"
										color="primary"
										loading={loading}
									>
										{i18n.t("transferTicketModal.buttons.ok")}
									</ButtonWithSpinner>
								</DialogActions>
							</form>
						</TabPanel>
						<TabPanel value="2">
							<form onSubmit={handleSaveTicket}>
								<DialogTitle id="form-dialog-title"
									style={{ padding: '25px', marginTop: -40, marginLeft: -20 }}>
									{i18n.t("Transferir fila")}
								</DialogTitle>
								<DialogContent
									style={{ padding: '6px', marginTop: -20 }}>


									<FormControl variant="outlined" required className={classes.maxWidth}>
										<label style={{ margin: '5px 0px 25px 0px' }}>Selecione uma fila</label>
										{filas.length === 1 ?
											<>
												<div style={{ border: '1px solid #c4c4c4', borderRadius: '5px', marginTop: '-20px' }}>
													<p style={{ padding: '15px 0px 0px 10px', fontSize: '15px' }}>
														{filas[0].name}
													</p>
												</div>

											</> :
											<>
												<Select
													disabled={false}
													value={selectedQueue}
													defaultValue={'disabled'}
													onChange={(e) => handleSelectChange(e.target.value)}
													placeholder='Selecione uma fila'
													inputProps={{ padding: 0 }}
													style={{
														padding: '0px 0px 4px 0px',
														margin: '-15px 1px -2px',
														height: '30px',
														width: '100%',
														fontSize: '13px'
													}}
												>
													<MenuItem value={'disabled'} disabled>{mensagemDoSistema}</MenuItem>
													{filas.map((queue) => (
														<MenuItem value={queue.id}>{queue.name}</MenuItem>
													))}
												</Select>
											</>
										}
										<br>
										</br>


									</FormControl>
								</DialogContent>
								<DialogActions dividers>
									<ButtonWithSpinner
										className={classes.button100}
										variant="contained"
										type="submit"
										color="primary"
										loading={loading}
									>
										{i18n.t("transferTicketModal.buttons.ok")}
									</ButtonWithSpinner>
								</DialogActions>
							</form>
						</TabPanel>

					</Paper>
				</TabContext>
			</div>
		</Drawer >
	);
};

export default ContactDrawer;
