const States = () => {
    const states = [
        { name: 'Acre', uf: 'ac' },
        { name: 'Alagoas', uf: 'al' },
        { name: 'Amapá', uf: 'ap' },
        { name: 'Amazonas', uf: 'am' },
        { name: 'Bahia', uf: 'ba' },
        { name: 'Ceará', uf: 'ce' },
        { name: 'Distrito Federal', uf: 'df' },
        { name: 'Espírito Santo', uf: 'es' },
        { name: 'Goiás', uf: 'go' },
        { name: 'Maranhão', uf: 'ma' },
        { name: 'Mato Grosso', uf: 'mt' },
        { name: 'Mato Grosso do Sul', uf: 'ms' },
        { name: 'Minas Gerais', uf: 'mg' },
        { name: 'Pará', uf: 'pa' },
        { name: 'Paraíba', uf: 'pb' },
        { name: 'Paraná', uf: 'pr' },
        { name: 'Pernambuco', uf: 'pe' },
        { name: 'Piauí', uf: 'pi' },
        { name: 'Rio de Janeiro', uf: 'rj' },
        { name: 'Rio Grande do Norte', uf: 'rn' },
        { name: 'Rio Grande do Sul', uf: 'rs' },
        { name: 'Rondônia', uf: 'ro' },
        { name: 'Roraima', uf: 'rr' },
        { name: 'Santa Catarina', uf: 'sc' },
        { name: 'São Paulo', uf: 'sp' },
        { name: 'Sergipe', uf: 'se' },
        { name: 'Tocantins', uf: 'to' }
    ];

    return states;
}

export default States;