import { Button, Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BackspaceIcon from '@material-ui/icons/Backspace';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';
import ScheduleIcon from '@material-ui/icons/Schedule';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  menuButton: {
    background: '#0277bd',
    color: 'white',
    textTransform: 'capitalize',
    height: '20px',
    marginRight: '10px'
  },
  optionIcon: {
    marginRight: '10px'
  }
}));

const SelectAction = ({ status, onTransferToAwait, onConcludeTicket, onOpen, onTagModal, onRemoveTags }) => {
  // const SelectAction = ({ status }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const options = [
    { value: 'open', label: 'Mover para Caixa de Entrada', icon: <MoveToInboxIcon className={classes.optionIcon} /> },
    { value: 'tag', label: 'Adicionar Etiqueta(s)', icon: <LocalOfferIcon className={classes.optionIcon} /> },
    { value: 'removeTags', label: 'Remover Etiquetas', icon: <BackspaceIcon className={classes.optionIcon} /> },
    { value: 'pending', label: 'Mover para Aguardando Retorno', icon: <ScheduleIcon className={classes.optionIcon} /> },
    { value: 'closed', label: 'Finalizar Atendimento(s)', icon: <CheckBoxIcon className={classes.optionIcon} /> },
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (value) => {

    setAnchorEl(null);

    if (value === 'pending' && onTransferToAwait) {
      onTransferToAwait();
    } else if (value === 'closed' && onConcludeTicket) {
      onConcludeTicket();
    } else if (value === 'open' && onOpen) {
      onOpen();
    } else if (value === 'tag' && onTagModal) {
      onTagModal();
    } else if (value === 'removeTags' && onRemoveTags) {
      onRemoveTags();
    }
  };



  return (
    <>
      <Button
        className={classes.menuButton}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        variant="contained"
      >
        Ações
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* validacao so aparece as opcoes para cada staus determinado */}
        {options.map((option) => {
          if (
            (option.value === 'tag' || option.value === 'removeTags') ||
            (status !== 'open' && option.value === 'open') ||
            (status !== 'closed' && option.value === 'closed') ||
            (status === 'open' && option.value === 'pending')
          )  {
            return (
              <MenuItem key={option.value} onClick={() => handleMenuItemClick(option.value)}>
                {option.icon} {option.label}
              </MenuItem>
            );
          }
          return null;
        })}

      </Menu>
    </>
  );
};

export default SelectAction;
