import {
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  Select
} from "@material-ui/core";
import { i18n } from "../../translate/i18n";

const TicketsQueueSelect = ({ userQueues, selectedQueueIds = [], onChange }) => {
  const handleChange = (val) => {
    onChange(val);
  };

  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center", margin: 0, padding: 0 }}>
      <FormControl
        fullWidth
        margin="none"
        style={{ padding: "0px", margin: "5px 0px 0px 0px", borderRadius: "0px" }}
      >
        <Select
          multiple
          style={{ padding: "0px", display: "flex", border: "none", margin: 0, borderRadius: "5px", width: "100%" }}
          displayEmpty
          variant="outlined"
          value={selectedQueueIds}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
          renderValue={() => i18n.t("ticketsQueueSelect.placeholder")}
        >
          {userQueues?.length > 0 && (
            <>
              {userQueues.map((queue) => (
                <MenuItem 
                  dense 
                  key={queue.id} 
                  value={queue.id}
                  onClick={() => handleChange(queue.id)}
                >
                  <Checkbox
                    style={{
                      color: queue.color,
                    }}
                    size="small"
                    color="primary"
                    checked={selectedQueueIds.indexOf(queue.id) > -1}
                    key={`ckb${queue.id}`}
                  />
                  <ListItemText primary={queue.name} />
                </MenuItem>
              ))}
            </>
          )}
        </Select>
      </FormControl>
    </div>
  );
};

export default TicketsQueueSelect;