/*
    Objetivo: retornar data atual
    Parametros:
        formato: 1-> dd/mm/YYYY H:i:s:m, 2 -> YYYY/mm/dd H:i:s:m.
*/

const GetDate = (formato = 1) => {
    // resgatar informações básicas e padronizar com 0 a esquerda
    let d = new Date();
    let day = d.getDate();
    day = ('00' + day).slice(-2);
    let month = d.getMonth() + 1;
    month = ('00' + month).slice(-2);
    let year = d.getFullYear();
    let hour = d.getHours();
    hour = ('00' + hour).slice(-2);
    let minute = d.getMinutes();
    minute = ('00' + minute).slice(-2);
    let second = d.getSeconds();
    second = ('00' + second).slice(-2);
    let milliseconds = d.getSeconds();
    milliseconds = ('000' + milliseconds).slice(-3);

    // definir formato
    if (formato == 1) {
        return day + '/' + month + '/' + year + ' ' + hour + ':' + minute + ':' + second + ':' + milliseconds;
    }
    else {
        return year + '/' + month + '/' + day + ' ' + hour + ':' + minute + ':' + second + ':' + milliseconds;
    }
}

export default GetDate;