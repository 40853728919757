import Button from "@material-ui/core/Button";
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { Field, Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import CheckEmail from "../../helpers/CheckEmail";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import { Can } from "../Can";
import QueueSelect from "../QueueSelect";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	formControlAdminSuper: {
		width: "100%"
	}
}));

const UserSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Muito Curto(a)!")
		.max(50, "Muito Longo(a)!")
		.required("Obrigatório"),
	password: Yup.string().min(5, "Muito Curto(a)!").max(50, "Muito Longo(a)!"),
});

const UserModal = ({ open, onClose, userId, userEditing }) => {
	const classes = useStyles();
	const { user: loggedInUser } = useContext(AuthContext);
	const initialState = {
		adminId: loggedInUser.adminId,
		name: "",
		email: "",
		password: "",
		profile: "user",
		queueManagement: false,
		campaignManagement: false,
		signature: true
	};
	const [user, setUser] = useState(initialState);
	const [Companies, setCompanies] = useState([]);
	const [selectedQueueIds, setSelectedQueueIds] = useState([]);
	useEffect(() => {
		const fetchUser = async () => {
			if (!userId) return;
			try {
				const { data } = await api.get(`/users/${userId}`);
				setUser(prevState => {
					return { ...prevState, ...data };
				});
				const userQueueIds = data.queues?.map(queue => queue.id);
				setSelectedQueueIds(userQueueIds);
			} catch (err) {
				toastError(err);
			}
		};
		const fetchCompanies = async () => {
			try {
				const { data } = await api.get("/company");

				setCompanies(prevState => {
					return { ...prevState, ...data };
				});
			} catch (err) {
				toastError(err);
			}
		}
		fetchCompanies();
		fetchUser();
	}, [userId, open]);

	const handleClose = () => {
		onClose();
		setUser(initialState);
		setSelectedQueueIds([])
	};

	const handleSaveUser = async values => {

		// verificar se e-mail é válido
		if (CheckEmail(values.email) == false) return toast.error('insira um email válido')
		// verificar se alguma fila foi selecionada
		if (!selectedQueueIds || selectedQueueIds.length < 1) return toast.error('selecione uma fila');

		const userData = { ...values, queueIds: selectedQueueIds };
		try {
			if (userId) {
				await api.put(`/users/${userId}`, userData);
				// if (loggedInUser.id === userId) {
				// 	window.location.reload()
				// }
			} else {
				await api.post("/users", userData);
			}
			toast.success(i18n.t("userModal.success"));
			setTimeout(() => {
				handleClose();
			}, 1000);
		} catch (err) {
			toastError(err);
		}
	};
	function checkUser() {
		if (loggedInUser.id === userId)
			return <a title="A página ira recarregar sempre que editar o próprio usuário." style={{
				margin: '20px 30px 0px 0px',
				color: 'red'
			}}>
				<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-exclamation-triangle" viewBox="0 0 16 16">
					<path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"></path>
					<path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"></path>
				</svg>
			</a>;
		return null;
	}
	function renderItemCompany(company) {
		return <MenuItem key={company.id} value={company.id}>{company.name}</MenuItem>
	}
	function RenderSelectCompanies() {
		var i = 0;
		var rows = [];
		for (var empresa in Companies) {
			rows.push(renderItemCompany(Companies[empresa]));
		}
		return rows;
	}
	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth
				scroll="paper"
			>
				<div style={{
					display: 'flex',
					justifyContent: 'space-between'
				}}>
					<DialogTitle id="form-dialog-title">
						{userId
							? `${i18n.t("userModal.title.edit")}`
							: `${i18n.t("userModal.title.add")}`}
					</DialogTitle>
					{/* {checkUser()} */}
				</div>

				<Formik
					initialValues={user}
					enableReinitialize={true}
					validationSchema={UserSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							var filas = false
							var campanhas = false
							if (values.profile === "admin") {
								filas = true;
								campanhas = true;
							} else {
								filas = values.queueManagement;
								campanhas = values.campaignManagement;
							}
							var array = {
								"adminId": values.adminId,
								"name": values.name,
								"email": values.email,
								"password": values.password,
								"profile": values.profile,
								"queueManagement": filas,
								"campaignManagement": campanhas,
								"queueIds": values.queueIds,
								"signature": values.signature
							}
							handleSaveUser(array);
							actions.setSubmitting(false);
						}, 1000);
					}}
				>
					{({ values, touched, errors, isSubmitting }) => (
						<Form style={{ padding: "10px" }}>
							<DialogContent dividers>
								<div className="form-row">
									<div class="form-group col-md-6">
										<label>Nome</label>
										<Field
											style={{
												borderColor: '#c4c4c4',
												borderRadius: '3px'
											}}
											className='form-control '
											placeholder={i18n.t("userModal.form.name")}
											autoFocus
											name="name"
											error={touched.name && Boolean(errors.name)}
											helperText={touched.name && errors.name}
											required
										/>
									</div>
									<div class="form-group col-md-6">
										<label>Senha</label>
										<Field
											style={{
												borderColor: '#c4c4c4',
												borderRadius: '3px'
											}}
											className='form-control '
											placeholder={i18n.t("userModal.form.password")}
											type="password"
											name="password"
											error={touched.password && Boolean(errors.password)}
											helperText={touched.password && errors.password}
										/>
									</div>
									<div class="form-group col-md-8">
										<label>Email</label>
										<div>
											<Field
												style={{
													borderColor: '#c4c4c4',
													borderRadius: '3px'
												}}
												type="email"
												variant="outlined"
												className='form-control'
												placeholder={i18n.t("userModal.form.email")}
												required
												name="email"
											/>
										</div>
									</div>
									<FormControl
										variant="outlined"
										className="form-group col-md-4"
										style={{ marginTop: '5px !important' }}
									>
										<Can
											role={loggedInUser.profile}
											perform="user-modal:editProfile"
											yes={() => (
												<>
													<label style={{ margin: '0px 0px 11px 0px' }}>Perfil</label>
													<Field
														style={{ height: '38px' }}
														variant="outlined"
														as={Select}
														name="profile"
														required
													>
														<MenuItem value="admin">Admin</MenuItem>
														<MenuItem value="user">User</MenuItem>
													</Field>
												</>
											)}
										/>
									</FormControl>
									<>
										{loggedInUser.company.usersManagement === true ?
											<>
												{values.profile != 'user' ? ''
													: <div class="form-group col-md-12">
														<div style={{ marginTop: '10px' }}>
															<FormControl>
																<div style={{ display: 'flex' }}>
																	<Field
																		style={{ width: 30 }}
																		as={Checkbox}
																		color="primary"
																		name="queueManagement"
																		checked={values.queueManagement}
																	/>
																	<label style={{ display: 'flex', alignItems: 'center' }}>Permitir gerenciamento de Conexões e Filas?</label>
																</div>
																<div style={{ display: 'flex' }}>
																	<Field
																		style={{ width: 30 }}
																		as={Checkbox}
																		color="primary"
																		name="campaignManagement"
																		checked={values.campaignManagement}
																	/>
																	<label style={{ display: 'flex', alignItems: 'center' }}>Permitir gerenciamento de Campanhas?</label>
																</div>
															</FormControl>
														</div>
													</div>
												}
											</>
											: ''}
									</>
									<div class="form-group col-md-12">
										<div style={{ marginTop: user.profile === "admin" ? '7px' : '-12px' }}>
											<FormControl>
												<div style={{ display: 'flex' }}>
													<Field
														style={{ width: 30 }}
														as={Checkbox}
														color="primary"
														name="signature"
														checked={values.signature}
													/>
													<label style={{ display: 'flex', alignItems: 'center' }}>Assinatura de identificação do usuário? <Tooltip title="As mensagens serão enviadas com o nome do usuário.">
														<IconButton>
															<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
																<path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
															</svg>
														</IconButton>
													</Tooltip>
													</label>
												</div>
											</FormControl>
										</div>
									</div>
									<>
										{values.queueManagement === true && values.profile === 'user' ? ''
											: <>
												<div class="form-group col-md-12">
													<Can
														role={loggedInUser.profile}
														perform="user-modal:editQueues"
														yes={() => (
															<QueueSelect
																selectedQueueIds={selectedQueueIds}
																onChange={values => setSelectedQueueIds(values)}
															/>
														)}
													/>
												</div>
											</>
										}
									</>
								</div>
								<Field
									as={TextField}
									type="hidden"
									name="adminId"
								/>
								<Can
									role={loggedInUser.profile}
									perform="user-modal:editProfileCompany"
									yes={() => (
										<>
											<div>
												<label>Empresa</label>
												<FormControl
													variant="outlined"
													margin="dense"
													className={classes.formControlAdminSuper}
												>
													<Field
														as={Select}
														variant="outlined"
														name="adminId"
														id="profile-selection"
														required
													>
														{
															RenderSelectCompanies()
														}

													</Field>
												</FormControl>
											</div>
										</>
									)}
								/>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("userModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{userId
										? `${i18n.t("userModal.buttons.okEdit")}`
										: `${i18n.t("userModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog >
		</div >
	);
};

export default UserModal;
