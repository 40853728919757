/* eslint-disable jsx-a11y/anchor-is-valid */
import { AccessTime, Done, DoneAll } from '@material-ui/icons';
import { format, isSameDay, parseISO } from "date-fns";
import xlsx from "json-as-xlsx";
import React from 'react';
import { toast } from "react-toastify";
import { KTSVG } from '../../../_metronic/helpers';
import api from "../../services/api";



type Props = {
    className: string
    relatoryData: any
}



function formatDateCorrect(date1: any, date2: any) {
    if (date1 === undefined) { if (date2 === undefined) { return new Date() } else { return date2 } }
    const x = new Date(date1);
    const y = new Date(date2);
    return x <= y ? date2 : date1;
};

const translateRelatory = {
    value: (obj: any) => {

        var translateValue: any[] = []
        obj.map((value: any) => {
            const translate = value
            if (translate.status === 'error') {
                translate.status = 'Erro ao enviar';
            } else if (translate.status === 'answered') {
                translate.status = 'Respondida';
            } else if (translate.status === 'blacklist') {
                translate.status = 'Bloqueado';
            } else {
                translate.status = 'Enviada'
            }
            var typeMessage = translate.message_sent
            if (typeMessage === 'undefinedForUser') { translate.message_sent = 'Arquivo' }
            translateValue.push(translate)
        })
        return translateRelatory.object(translateValue);
    },
    object: (obj: any[]) => {
        var translateObj: any[] = [];
        obj.map((map) => {
            const obj = map;

            var atualizacao = obj.updatedAt === undefined ? obj.createdAt : obj.updatedAt;

            obj['Nome'] = obj['name'];
            delete obj['name'];
            obj['Número'] = obj['number'];
            delete obj['number'];
            obj['Mensagem'] = obj['message_sent'];
            delete obj['message_sent'];
            obj['Data'] = format(parseISO(formatDateCorrect(obj.updatedAt, obj.createdAt)), "dd/MM/yyyy");
            obj['Horario'] = format(parseISO(formatDateCorrect(obj.updatedAt, obj.createdAt)), "HH:mm");
            delete obj['updatedAt'];
            obj['Atualizacao'] = obj['status'];
            delete obj['status'];

            translateObj.push(obj)
        })
        return translateObj;
    }
}

function exportRelatory(dataExport: any) {

    var relatoryComplete = translateRelatory.value(dataExport)//traduzir todos os cam

    let data = [{
        sheet: "Relatório",
        columns: [
            { label: "Nome", value: "Nome" },
            { label: "Número", value: "Número" },
            { label: "Mensagem", value: "Mensagem" },
            { label: "Data", value: "Data" },
            { label: "Horario", value: "Horario" },
            { label: "Atualizacao", value: "Atualizacao" },

        ],
        content: relatoryComplete,
    }]

    let settings = {
        fileName: "Relatório", // Name of the resulting spreadsheet
        // extraLength: 3, // A bigger number means that columns will be wider
        // writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
    }

    xlsx(data, settings) // Will download the excel file
}



const widgetTableRelatory: React.FC<Props> = ({ className, relatoryData }) => {


    const renderMessageAck = (message: any) => {
        if (message.ack === 0) {
            return <AccessTime fontSize="small" style={{
                fontSize: 18,
                verticalAlign: "middle",
                marginLeft: 4,
            }} />;
          }
          if (message.ack === 1) {
            return <Done fontSize="small" style={{
                fontSize: 18,
                verticalAlign: "middle",
                marginLeft: 4,
            }} />;
          }
          if (message.ack === 2) {
            return <Done fontSize="small" style={{
                fontSize: 18,
                verticalAlign: "middle",
                marginLeft: 4,
            }} />;
          }
          if (message.ack === 3) {
            return <DoneAll fontSize="small" style={{
                fontSize: 18,
                verticalAlign: "middle",
                marginLeft: 4,
            }} />;
          }
          if (message.ack === 4 || message.ack === 5) {
            return <DoneAll fontSize="small" style={{
                fontSize: 18,
                verticalAlign: "middle",
                marginLeft: 4,
            }} />;
          }
    };

    const resentAll = () => {
        var allErrors = document.querySelectorAll(".badge-light-danger");
        for (let i = 0; i < allErrors.length; i++) {
            if (allErrors[i].id) {

                setTimeout(() => {
                    reenviarMensagem(allErrors[i].id);
                }, 1500);

            }
        }
    }

    const reenviarMensagem = async (id: any) => {

        try {

            var buttonResent = document.getElementById(`resent_${id}`);
            var messageResent = document.getElementById(`message_${id}`);
            var divResent = document.querySelectorAll(`#div_of_${id} > span`)

            const reenviar = await api.post("/resendInCampaing", { id: id });

            if (reenviar && buttonResent && messageResent && divResent) {
                buttonResent.remove();
                divResent[0].classList.remove("badge-light-danger");
                divResent[0].classList.add("badge-light-success");
                divResent[0].textContent = "Enviado";
                messageResent.innerText = 'Reenviando...';
            }

        } catch (err: any) {
            if (err?.response?.data?.error?.message) {
                return toast.error(err.response.data.error.message)
            } else {
                return toast.error(`Ocorreu um erro ao processar a sua solicitação.`)
            }
        }

    }

    function getErrorQty(contacts: any) {
        var count: any[] = [];
        contacts.forEach((x: { status: string; }) => {
            if (x.status === 'error') {
                count.push(x);
            }
        });
        return count.length;
    }

    var formating = (values: any) => {
        var data: any[] = [];

        values['lastMessageAndStatus'].forEach((contato: any) => {
            var returned = {
                id: contato.id,
                name: contato.name,
                number: contato.number,
                message_sent: contato.message_sent,
                status: contato.status,
                updatedAt: formatDateCorrect(contato.updatedAt, contato.createdAt),
            }
            data.push(returned);
        });
        return data;
    }

    var relatorio = formating(JSON.parse(relatoryData));

    return (
        <>
            <div className={`card ${className}`}>
                {/* begin::Header */}
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bolder fs-3 mb-1'>Relatório Completo - Envio</span>
                    </h3>
                    <div
                        className='card-toolbar'
                        data-bs-toggle='tooltip'
                        data-bs-placement='top'
                        data-bs-trigger='hover'
                        title='Click to add a user'
                    >
                        <button
                            className='btn btn-sm btn-light-primary'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_invite_friends'
                            onClick={() => { exportRelatory(relatorio) }}
                        >
                            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                            Exportar Relatório
                        </button>
                        {/* <button
                            className='btn btn-sm btn-light-danger'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_invite_friends'
                            onClick={() => { resentAll() }}
                        >
                            <>
                                <>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-send" viewBox="0 0 16 16">
                                        <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
                                    </svg>
                                </>
                                &nbsp; {`Reenviar com falha (${getErrorQty(relatorio)})`}
                            </>
                        </button> */}
                    </div>
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body py-3' style={{ overflow: 'auto', maxHeight: '25rem' }}>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                        {/* begin::Table */}
                        <table id="relatoryTable" className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            {/* begin::Table head */}
                            <thead>
                                <tr className='fw-bolder text-muted'>
                                    <th className='min-w-150px'>Nome do Contato</th>
                                    <th className='min-w-140px'>Número do Contato</th>
                                    <th className='min-w-120px'>Ultima mensagem trocada</th>
                                    <th className='min-w-120px'>Ultima atualização</th>
                                    <th className='min-w-120px'>Horario</th>
                                    <th className='min-w-100px text-end'>status</th>
                                </tr>
                            </thead>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody>
                                {relatorio.map((dados: { id: any; name: any; number: any; message_sent: any; status: string; updatedAt: any }) =>
                                    <>
                                        <tr>
                                            <td>
                                                <div className='d-flex align-items-center'>

                                                    <div className='d-flex justify-content-start flex-column'>
                                                        <a href='#' className='text-dark fw-bolder d-block text-hover-primary d-block fs-6'>
                                                            {dados.name}
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <a href='#' className='text-dark fw-bolder d-block text-hover-primary d-block fs-6'>
                                                    {dados.number}
                                                </a>
                                            </td>
                                            <td>
                                                <a href='#' className='text-dark fw-bolder d-block text-hover-primary d-block fs-6'>

                                                    {dados.message_sent == 'undefinedForUser' ?
                                                        <div title='Esta campanha tem um arquivo' style={{ display: 'flex', marginTop: '10px' }}>
                                                            Arquivo
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-zip" viewBox="0 0 16 16">
                                                                <path d="M5 7.5a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v.938l.4 1.599a1 1 0 0 1-.416 1.074l-.93.62a1 1 0 0 1-1.11 0l-.929-.62a1 1 0 0 1-.415-1.074L5 8.438V7.5zm2 0H6v.938a1 1 0 0 1-.03.243l-.4 1.598.93.62.929-.62-.4-1.598A1 1 0 0 1 7 8.438V7.5z" />
                                                                <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1h-2v1h-1v1h1v1h-1v1h1v1H6V5H5V4h1V3H5V2h1V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z" />
                                                            </svg>
                                                        </div> : dados.message_sent}
                                                </a>
                                            </td>
                                            <td>
                                                <a href='#' className='text-dark fw-bolder d-block text-hover-primary d-block fs-6'>
                                                    {dados.updatedAt &&
                                                        <>
                                                            {isSameDay(parseISO(dados.updatedAt), new Date()) ? (
                                                                <>Hoje</>
                                                            ) : (
                                                                <>{format(parseISO(dados.updatedAt), "dd/MM/yyyy")}</>
                                                            )}
                                                        </>
                                                    }
                                                </a>
                                            </td>
                                            <td>
                                                <a href='#' className='text-dark fw-bolder d-block text-hover-primary d-block fs-6'>
                                                    {dados.updatedAt &&
                                                        <>
                                                            {
                                                                <>{format(parseISO(dados.updatedAt), "HH:mm")}</>
                                                            }
                                                        </>
                                                    }
                                                </a>
                                            </td>
                                            <td>
                                                <div className='text-end' id={`div_of_${dados.id}`}>
                                                    {dados.status == 'error' ?
                                                        <>
                                                            <span id={`${dados.id}`} className='badge badge-light-danger fs-7 fw-bold'>Erro ao enviar</span>
                                                            {/* <Button
                                                                variant="outlined"
                                                                size="small"
                                                                color="primary"
                                                                id={`resent_${dados.id}`}
                                                                style={{ height: '22px' }}
                                                                onClick={e => reenviarMensagem(dados.id)}
                                                            >
                                                                <p style={{ margin: '0px' }} id={`message_${dados.id}`}>
                                                                    Reenviar
                                                                </p>
                                                            </Button> */}
                                                        </>
                                                        : dados.status == 'blacklist' ?
                                                            <span className='badge badge-light-danger fs-7 fw-bold'>Bloqueado</span>
                                                        : dados.status == 'answered' ?
                                                            <span className='badge badge-light-primary fs-7 fw-bold'>Respondido</span>
                                                            :
                                                            <span className='badge badge-light-success fs-7 fw-bold'>Enviado</span>
                                                    }
                                                    <>
                                                        {renderMessageAck(dados)}
                                                    </>
                                                </div>
                                            </td>
                                        </tr>
                                    </>)}
                            </tbody>
                            {/* end::Table body */}
                        </table>
                        {/* end::Table */}
                    </div>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>
        </>
    )
}

export default widgetTableRelatory;
