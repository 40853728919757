import { makeStyles } from "@material-ui/core";
import { useContext, useEffect, useReducer, useState } from "react";
import { toast } from "react-toastify";
import openSocket from "socket.io-client";
import { KTSVG } from '../../../_metronic/helpers';
import ConfirmationModal from "../../components/ConfirmationModal";
import MainContainer from "../../components/MainContainer";
import QueueModal from "../../components/QueueModal";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

import FormControl from "@material-ui/core/FormControl";
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import socket from "../../hooks/useSocket";


const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  customTableCell: {
    display: "flex",
    alignItems: "center",
  },
  modalStyle: {
    padding: '90px',
    minWidth: '310px',
    overflowY: 'scroll',
    "@media (max-width: 740px)": {
      padding: '0px !important',
      marginTop: '65px'
    }
  }
}));

const reducer = (state, action) => {

  if (action.type === "LOAD_QUEUES") {
    const queues = action.payload;
    const newQueues = [];

    queues.forEach((queue) => {
      const queueIndex = state.findIndex((q) => q.id === queue.id);
      if (queueIndex !== -1) {
        state[queueIndex] = queue;
      } else {
        newQueues.push(queue);
      }
    });

    return [...state, ...newQueues];
  }

  if (action.type === "UPDATE_QUEUES") {
    const queue = action.payload;
    const queueIndex = state.findIndex((u) => u.id === queue.id);

    if (queueIndex !== -1) {
      state[queueIndex] = queue;
      return [...state];
    } else {
      return [queue, ...state];
    }
  }

  if (action.type === "DELETE_QUEUE") {
    const queueId = action.payload;
    const queueIndex = state.findIndex((q) => q.id === queueId);
    if (queueIndex !== -1) {
      state.splice(queueIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const Queues = () => {

  const classes = useStyles();
  const [queues, dispatch] = useReducer(reducer, []);
  const [loading, setLoading] = useState(false);
  const [queueModalOpen, setQueueModalOpen] = useState(false);
  const [selectedQueue, setSelectedQueue] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  
  const { user } = useContext(AuthContext);
  const { adminId, profile } = user;


  const [getUsers, setGetUsers] = useState([]);
  const [selectedUserFilter, setSelectedUserFilter] = useState("");

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const { data } = await api.get("/usersList/");
          setGetUsers(data)
        } catch (err) {
          toastError(err);
        }
      };

      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, []);


  const handleChange = async (event) => {
    if (event.target.value === "") {
      setSelectedUserFilter("");
      (async () => {
        setLoading(true);
        try {
          const { data } = await api.get("/queue");
          dispatch({ type: "RESET" });
          dispatch({ type: "LOAD_QUEUES", payload: data });
          setLoading(false);
        } catch (err) {
          toastError(err);
          setLoading(false);
        }
      })();

    } else {
      setSelectedUserFilter(event.target.value);
      (async () => {
        setLoading(true);
        try {
          const { data } = await api.get(`/queueList/${event.target.value}`);
          dispatch({ type: "RESET" });
          dispatch({ type: "LOAD_QUEUES", payload: data });
          setLoading(false);
        } catch (err) {
          toastError(err);
          setLoading(false);
        }
      })();
    }
  };


  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { data } = await api.get("/queue");
        dispatch({ type: "LOAD_QUEUES", payload: data });
        setLoading(false);
      } catch (err) {
        toastError(err);
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    socket.on(`queue-${user.adminId}`, (data) => {
      if ((data.action === "update" || data.action === "create") && adminId == data.queue.adminId) {
        dispatch({ type: "UPDATE_QUEUES", payload: data.queue });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_QUEUE", payload: data.queueId });
      }
    });

    return () => {};
  }, []);

  const handleOpenQueueModal = () => {
    setQueueModalOpen(true);
    setSelectedQueue(null);
  };

  const handleCloseQueueModal = () => {
    setQueueModalOpen(false);
    setSelectedQueue(null);
  };

  const handleEditQueue = (queue) => {
    setSelectedQueue(queue);
    setQueueModalOpen(true);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmModalOpen(false);
    setSelectedQueue(null);
  };

  const handleDeleteQueue = async (queueId) => {
    try {
      await api.delete(`/queue/${queueId}`);
      toast.success(i18n.t("Fila deletada com sucesso!"));
    } catch (err) {
      toastError(err);
    }
    setSelectedQueue(null);
  };



  return (
    <MainContainer>
      <ConfirmationModal
        title={
          selectedQueue &&
          `${i18n.t("queues.confirmationModal.deleteTitle")} ${selectedQueue.name
          }?`
        }
        open={confirmModalOpen}
        onClose={handleCloseConfirmationModal}
        onConfirm={() => handleDeleteQueue(selectedQueue.id)}
      >
        {i18n.t("queues.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <QueueModal
        open={queueModalOpen}
        onClose={handleCloseQueueModal}
        queueId={selectedQueue?.id}
      />
      <div class='row gy-5 gx-xl-8'
        className={classes.modalStyle}
      >
        <div className='col-xl-15'>
          <div className='card card-xxl-stretch-5 mb-15 mb-xl-8'>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>Filas</span>
                {/*<span className='text-muted mt-1 fw-bold fs-7'>Over 500 members</span>*/}
              </h3>
              <div
                className='card-toolbar '
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
              >
                <FormControl variant="outlined" hidden={user.profile != 'admin'}>
                  <Select
                    value={selectedUserFilter}
                    displayEmpty
                    style={{
                      padding: '1px 0px 4px',
                      margin: '4px 10px -2px 0px',
                      height: '30px',
                      width: '173px',
                      fontSize: '15px'
                    }}
                    inputProps={{
                      style: {
                        padding: 5
                      }
                    }}
                    onChange={e => handleChange(e)}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}

                  >
                    <MenuItem value="">
                      <em>Todos os usuários</em>
                    </MenuItem>
                    {getUsers.map((user, key) => (
                      <MenuItem dense key={key} value={user.id}>
                        <ListItemText primary={user.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <button
                  className='btn btn-sm btn-success'
                  type="button"
                  style={{
                    marginLeft: "1.5px",
                    backgroundColor: "#00a884"
                  }}
                  // data-bs-toggle='modal'
                  // data-bs-target='#kt_modal_invite_friends'
                  onClick={handleOpenQueueModal}
                >
                  <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                  Adicionar Fila
                </button>
              </div>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body py-3'>
              {/* begin::Table container */}
              <div className='table-responsive'>
                {/* begin::Table */}
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='fw-bolder text-muted' >
                      <th className='min-w-150px'>Nome</th>
                      <th className='min-w-140px'>Cor</th>
                      <th className='min-w-120px'>Mensagem de saudação</th>
                      <th className='min-w-100px text-end'>Ações</th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                    <>
                      {queues.map((queue) => (
                        <>
                          <tr>
                            <td>
                              <div>
                                <div className='d-flex justify-content-start flex-column'>
                                  <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                                    {queue.name}
                                  </a>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className={classes.customTableCell}>
                                <span
                                  style={{
                                    backgroundColor: queue.color,
                                    width: 20,
                                    height: 20,
                                    borderRadius: 20,
                                    alignSelf: "center",
                                  }}
                                />
                              </div>
                            </td>
                            <td>
                              <a className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                                {queue.greetingMessage}
                              </a>
                            </td>
                            <td>
                              <div className='d-flex justify-content-end flex-shrink-0'>

                                <button

                                  title='Editar'
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                  onClick={() => handleEditQueue(queue)}
                                >
                                  <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                </button>
                                <button

                                  title='Excluir'
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                  onClick={() => {
                                    setSelectedQueue(queue);
                                    setConfirmModalOpen(true);
                                  }}
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/general/gen027.svg'
                                    className='svg-icon-3'
                                  />
                                </button>
                              </div>
                            </td>
                          </tr>
                        </>
                      ))}
                      {loading && <TableRowSkeleton columns={4} />}
                    </>
                  </tbody>
                  {/* end::Table body */}
                </table>
                {/* end::Table */}
              </div>
              {/* end::Table container */}
            </div>
            {/* begin::Body */}
          </div>
        </div>
      </div>
    </MainContainer>
  );
};

export default Queues;
