import { Container, Grid, Typography, Button } from "@mui/material";
import { Can } from "../../components/Can";
import { i18n } from "../../translate/i18n";
import { KTSVG } from "../../../_metronic/helpers";
import { useContext } from "react";
import { AuthContext } from "../../context/Auth/AuthContext";
import WhatsAppTable from "../../components/WhatsApp/table";
import { useState, useEffect, useCallback } from "react";
import WhatsAppModal from "../../components/WhatsApp/modal";

const Connections = () => {
	const { user } = useContext(AuthContext);
	const [selectedWhatsApp, setSelectedWhatsApp] = useState(null);
	const [whatsAppModalOpen, setWhatsAppModalOpen] = useState(false);
	const [qrModalOpen, setQrModalOpen] = useState(false);

	const handleOpenWhatsAppModal = () => {
		setSelectedWhatsApp(null);
		setWhatsAppModalOpen(true);
	};

	const handleCloseWhatsAppModal = useCallback(() => {
		// resetWpp()
		setWhatsAppModalOpen(false);
		setSelectedWhatsApp(null);
	}, [setSelectedWhatsApp, setWhatsAppModalOpen]);

	return (
		<Container
			style={{
				marginTop: "100px",
				background: "#fff",
				borderRadius: "10px",
				padding: "20px"
			}}
		>
			<WhatsAppModal
				open={whatsAppModalOpen}
				onClose={handleCloseWhatsAppModal}
				whatsAppId={!qrModalOpen && selectedWhatsApp?.id}
				userProfile={user.profile}
			/>
			<Grid container spacing={5}>
				<Grid item sm={12} xs={12} mb={2}>
					<Typography variant="h6">{i18n.t("connections.title")}</Typography>
					<Can
						role={user.profile}
						perform="connections-page:addConnection"
						yes={() => (
							<Button
								className='btn btn-sm btn-success'
								style={{
									marginLeft: "1.5px",
									backgroundColor: "#00a884",
									float: "right"
								}}
								onClick={handleOpenWhatsAppModal}
							>
								<KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
								{i18n.t("connections.buttons.add")}
							</Button>
						)}
					/>
				</Grid>
			</Grid>

			<WhatsAppTable />
		</Container>
	);
}

export default Connections;