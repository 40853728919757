import { DialogTitle } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useState } from "react";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { toast } from "react-toastify";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

const fileTypes = ["doc", "docx", "xls", "xlsx", "ppt", "pptx", "pdf", "txt", "mp4", "mkv", "mov", "avi", "jpg", "jpeg", "png", "gif"];

const useStyles = makeStyles((theme) => ({
  field: {
    margin: '10px 0',
  },
  countryList: {
    ...theme.typography.body1,
  },
}));

const TestMessageCampaignModal = ({ title, open, onClose, loading, campaignId, onConfirm, }) => {
  const classes = useStyles();

  const [number, setNumber] = useState("");
  const [messageKeys, setMessageKeys] = useState([]);
  const [keys, setKeys] = useState([]);
  const [file, setFile] = useState(null);

  useEffect(() => {
    const element = document.querySelector(".sc-fqkvVR.kFhUBM");
    if (element) {
      const successSpan = element.querySelector("span");
      const innerSpans = element.querySelectorAll("span");
      if (successSpan && successSpan.textContent.includes("Successfully")) {
        successSpan.textContent = "please!";
      }
      if (element.textContent.includes("another")) {
        element.textContent = "Arquivo enviado com sucesso!";
      }
    }
  }, [file]);

  const handleChange = (file) => {
    setFile(file);
  };

  const handleButtonClick = () => {

    if (loading) return;

    const formattedNumber = `${number.replace(/[^\d]/g, "")}`;

    if (formattedNumber.length < 12) {
      toast.error("O número selecionado não está correto.");
    } else {
      setTimeout(() => {
        setNumber("");
        onConfirm(campaignId, formattedNumber, messageKeys, file);
      }, 1000);
    }
  };

  const renderKey = (e) => {
    const { name, value } = e.target;

    setMessageKeys((prevKeys) => {
      const existingKeyIndex = prevKeys.findIndex((key) => Object.keys(key)[0] === name);

      if (existingKeyIndex !== -1) {
        // Atualiza o valor da chave se ela já existir
        const updatedKeys = [...prevKeys];
        updatedKeys[existingKeyIndex][name] = value;
        return updatedKeys;
      } else {
        // Adiciona uma nova entrada se a chave não existir
        return [...prevKeys, { [name]: value }];
      }
    });
  };

  const phoneFormatter10 = (phone) => {
    phone = phone.replace(/[^\d]/g, "");
    return phone.replace(/(\d{1,3})(\d{4})(\d{4})/, "+$1 ($2) $3-$4");
  };

  const phoneFormatter11 = (phone) => {
    phone = phone.replace(/[^\d]/g, "");
    return phone.replace(/(\d{1,3})(\d{5})(\d{4})/, "+$1 ($2) $3-$4");
  };


  const formatNumber = (value) => {
    const inputValue = String(value).replace(/[^\d]/g, "");
    const formattedNumber = inputValue;
    setNumber(formattedNumber);
  };

  useEffect(() => {
    const fetchSession = async () => {
      if (!campaignId) return;
      try {
        const { data } = await api.get(`/campaignTest/${campaignId}`);
        setKeys(data)
      } catch (err) {
        console.error(err)
      }
    };
    fetchSession();
  }, [campaignId])

  const handleClose = () => {
    setNumber("");
    setKeys([]);
    setFile(null);
    onClose(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          width: "100%",
          maxWidth: "720px!important",
        },
      }}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>
        <div className="form-group col-md-12">
          <label>Selecione um número para enviar o teste desta campanha</label>

          <PhoneInput
            onlyCountries={['br']}
            inputStyle={{ width: '100%', height: '40px', fontSize: '15px' }}
            country={'br'}
            value={number}
            onChange={formatNumber}
          />


          {/*
          <input
            style={{
              borderColor: '#c4c4c4',
              borderRadius: '3px',
            }}
            value={number}
            onChange={formatNumber}
            maxLength="16"
            placeholder="(00) 0 0000-0000"
            name="number"
            className="form-control"
          />
         */}
        </div>
        {/*
        <div className="form-group col-md-12">
          <FileUploader
            id="DragAndDrop"
            label="Selecione um arquivo para ser enviado."
            handleChange={handleChange}
            name="file"
            types={fileTypes}
          />
          {file && (
            <div>
              <p>Nome do arquivo: {file.name}</p>
              <p>Tamanho do arquivo: {file.size} bytes</p>
            </div>
          )}
        </div>*/}
        <div className="row" style={{ padding: '0px 15px' }}>
          {keys && keys.map(({ key }, i) => (
            <div key={i} className={`form-group col-md-6${i % 2 === 1 ? ' clear-left' : ''}`}>
              <label>{key}</label>
              <input
                style={{
                  borderColor: '#c4c4c4',
                  borderRadius: '3px',
                }}

                onChange={e => renderKey(e)}
                placeholder={key}
                name={key}
                className="form-control"
              />
            </div>
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="default">
          {i18n.t("confirmationModal.buttons.cancel")}
        </Button>
        <Box style={{ m: 1, position: 'relative' }}>
          <Button variant="contained" disabled={loading} onClick={handleButtonClick}>
            Enviar mensagem
          </Button>
          {loading && (
            <CircularProgress
              size={24}
              style={{
                color: 'green[500]',
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default TestMessageCampaignModal;
