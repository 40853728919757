import React, { useState, useRef, useEffect } from 'react';
import { Box, Typography, Menu, MenuItem, List, ListItem, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        maxHeight: 300,
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    listItem: {
        display: 'flex',
        width: '100%',
        minWidth: '210px',
        justifyContent: 'space-between',
        padding: '8px 16px',
        alignItems: 'center',
    },
    emoji: {
        fontSize: '15px',
    },
    extraCount: {
        fontSize: '15px',
        padding: '0 2px',
    }
}));

const EmojiGroup = ({ reactions, max = 0, message, onRemove }) => {
    const classes = useStyles({ fromMe: message.fromMe });
    const [anchorEl, setAnchorEl] = useState(null);
    const boxRef = useRef(null);

    // Conta a frequência de cada reação
    const reactionCountMap = reactions.reduce((acc, { reaction }) => {
        acc[reaction] = (acc[reaction] || 0) + 1;
        return acc;
    }, {});

    // Converte o mapa em uma lista de objetos { reaction, count }
    const reactionList = Object.entries(reactionCountMap).map(([reaction, count]) => ({ reaction, count }));

    const displayedReactions = reactionList.slice(0, max);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickOutside = (event) => {
        if (boxRef.current && !boxRef.current.contains(event.target)) {
            handleClose();
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div style={{
            borderRadius: '25px',
            border: '1px solid #bbb6b6',
            position: 'absolute',
            zIndex: Boolean(anchorEl) ? '0' : '1200',
            backgroundColor: "white",
            padding: '2px 5px',
            left: message.fromMe ? '10px' : 'auto',
            right: message.fromMe ? 'auto' : '10px'
        }}>
            <Box ref={boxRef} display="flex" alignItems="center" onClick={handleClick} style={{ cursor: 'pointer' }}>
                {displayedReactions.map((item, index) => (
                    <>
                        <Typography key={index} variant="h5" component="span" className={classes.emoji}>
                            {item.reaction}
                        </Typography>
                        {
                            (displayedReactions.length > 1 || item.count > 1) && 
                            <Typography variant="h6" component="span" color="textSecondary" className={classes.extraCount}>
                                {item.count}
                            </Typography>
                        }
                    </>
                ))}
                <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                    <List className={classes.root}>
                        {reactions.map((item, index) => (
                            <ListItem key={index} className={classes.listItem}>
                                <Typography
                                    component="span"
                                    variant="body2"
                                    color="textPrimary"
                                >
                                    {item.name}
                                </Typography>
                                <Box>
                                    <Typography
                                        component="span"
                                        variant="body2"
                                        color="textPrimary"
                                    >
                                        {item.reaction}
                                    </Typography>
                                    {item.fromMe && <IconButton onClick={() => onRemove()} aria-label="delete"><DeleteIcon /></IconButton>}
                                </Box>
                            </ListItem>
                        ))}
                    </List>
                </Menu>
            </Box>
        </div>
    );
};

export default EmojiGroup;
