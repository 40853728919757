function findImageInAd(obj) {
    if (typeof obj !== 'object' || obj === null) return null;

    if (obj.hasOwnProperty('jpegThumbnail')) {
        return `data:image/png;base64,${obj.jpegThumbnail}`;
    } else if (obj.hasOwnProperty('thumbnail')) {
        return `data:image/png;base64,${obj.thumbnail}`;
    } else if (obj.hasOwnProperty('mediaUrl')) {
        return obj.mediaUrl;
    }

    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const result = findImageInAd(obj[key]);
            if (result) {
                return result;
            }
        }
    }

    return null; // caso nenhuma das propriedades seja encontrada
}

function findTitleInAd(obj) {
    if (typeof obj !== 'object' || obj === null) return null;

    if (obj.hasOwnProperty('title')) {
        return obj.title;
    }

    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const result = findTitleInAd(obj[key]);
            if (result) {
                return result;
            }
        }
    }

    return null; // caso nenhuma propriedade "title" seja encontrada
}

function findDescriptionInAd(extendedTextMessage) {
    if (!extendedTextMessage) return "";

    const description =
        extendedTextMessage.description ||
        extendedTextMessage.contextInfo?.externalAdReply?.description ||
        extendedTextMessage.contextInfo?.externalAdReply?.body ||
        null;

    return description;
}

function findUrlInAd(obj) {
    if (typeof obj !== 'object' || obj === null) return null;

    if (obj.hasOwnProperty('canonicalUrl') && obj.canonicalUrl !== "") {
        return obj.canonicalUrl;
    } else if (obj.hasOwnProperty('sourceUrl') && obj.sourceUrl !== "") {
        return obj.sourceUrl;
    } else if (obj.hasOwnProperty('text') && obj.text !== "") {
        const regex = /(https?:\/\/[^\s]+)/g;
        const matches = obj.text.match(regex);
        if (matches?.length > 0) {
            return matches[0];
        }
    } else if (obj.hasOwnProperty('matchedText') && obj.matchedText !== "") {
        const regex = /(https?:\/\/[^\s]+)/g;
        const matches = obj.matchedText.match(regex);
        if (matches?.length > 0) {
            return matches[0];
        }
    }

    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const result = findUrlInAd(obj[key]);
            if (result) {
                return result;
            }
        }
    }

    return null;
}

export { findImageInAd, findTitleInAd, findDescriptionInAd, findUrlInAd };