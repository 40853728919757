import { useEffect, useState } from "react";

import { Select } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import States from "../../helpers/States";
import api from "../../services/api";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	
	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	}
	
}));

const CompanyUserModal = ({ open, onClose, companyId }) => {
	const classes = useStyles();
	const initialState = {
		name: "",
		fantasyName: "",
		stateRegistration: "",
		cnpj: "",
		status: true,
		address: "",
		number: "",
		complement: "",
		cep: "",
		district: "",
		city: "",
		uf: "",
		usersManagement: true
	};

	const [successCreate, setSuccessCreate] = useState(false)
	const [successMsgCreate, setMsgSuccessCreate] = useState({})
	const [company, setCompany] = useState(initialState);
	const [valor, setValor] = useState('')
	useEffect(() => {
		const fetchUser = async () => {
			if (!companyId) {
				setValor("");
				setSuccessCreate("")
				return;
			};
			try {
				setValor("");
				const { data } = await api.get(`/companySignature/${companyId}`);
				var array = {
					"cnpj": setValor(data.cnpj),
					"fantasyName":data.fantasyName,
					"stateRegistration":data.stateRegistration,
					"address":data.address,
					"number":data.number,
					"complement":data.complement,
					"cep":data.cep,
					"district":data.district,
					"city":data.city,
					"uf":data.uf,
					"id": data.id,
					"status": data.status,		
					"name": data.name,
				}
				setCompany(prevState => {
					return { ...prevState, ...array };
				});
			} catch (err) {
				toastError(err);
			}
		};

		fetchUser();
	}, [companyId, open]);

	const handleClose = () => {
		onClose();
		setCompany({...initialState});
	};

	const handleSaveCompany = async values => {
		const CompanyData = { ...values };

		try {
			if (companyId) {
				await api.put(`/company/${companyId}`, CompanyData);
				toast.success('Atualizado com sucesso!');
				handleClose();
			} else {
				
			}
			// handleClose();
		} catch (err) {
			toastError(err);
		}

	};

	const mask = (v) => {
		v = v.replace(/\D/g, "")
		v = v.replace(/^(\d{2})(\d)/, "$1.$2")
		v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
		v = v.replace(/\.(\d{3})(\d)/, ".$1/$2")
		v = v.replace(/(\d{4})(\d)/, "$1-$2")
		return v.substring(0, 18)
	}

	function handleChangeMask(event) {
		const { value } = event.target

		setValor(mask(value))
	}
	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="md"
				fullWidth
				scroll="paper"
			>
				<DialogTitle id="form-dialog-title">
					{companyId
						? `${'Editar Empresa'}`
						: `${'Cadastrar Empresa'}`}
				</DialogTitle>
				<Formik
					initialValues={company}
					enableReinitialize={true}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							var array = {
								"cnpj": valor,
								"fantasyName":values.fantasyName,
								"stateRegistration":values.stateRegistration,
								"address":values.address,
								"number":values.number,
								"complement":values.complement,
								"cep":values.cep,
								"district":values.district,
								"city":values.city,
								"uf":values.uf,
								"id": values.id,
								"status": values.status,
								"name": values.name,
							}
							handleSaveCompany(array);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ values, touched, errors, isSubmitting }) => (
						<>

							{successCreate ?
								<>
									<DialogContent dividers>
										<div>
	
										</div>
									</DialogContent>
									<DialogActions dividers>
										<Button
											onClick={handleClose}
											color="primary"
											variant="outlined"
										>
											{'FINALIZAR'}
										</Button>
									</DialogActions>
								</>
								:
								<Form style={{ padding: "10px"}}>

									<DialogContent dividers>
										<div className="form-row">
											<Field
												type="hidden"
												name="adminId"
											/>
											<div class="form-group col-md-8">
												<label>Razão social</label>
												<Field
													style={{
														borderColor: '#c4c4c4',
														borderRadius: '3px'
													}}
													className='form-control'
													placeholder={'Razão social'}
													type="text"
													name="name"
													disabled
												/>
											</div>
											<div class="form-group col-md-4">
												<label>CNPJ</label>
												<Field
													style={{
														borderColor: '#c4c4c4',
														borderRadius: '3px'
													}}
													className='form-control '
													placeholder={'00.000.000/0000-00'}
													maxlength="18"
													onChange={handleChangeMask} 
													value={valor}
													type="text"
													name="cnpj"
													disabled
												/>
											</div>
											<div class="form-group col-md-8">
												<label>Nome fantasia</label>
												<Field
													style={{
														borderColor: '#c4c4c4',
														borderRadius: '3px'
													}}
													className='form-control'
													placeholder={'Nome fantasia'}
													type="text"
													name="fantasyName"
												/>
											</div>
											<div class="form-group col-md-4">
												<label>Inscrição estadual</label>
												<Field
													style={{
														borderColor: '#c4c4c4',
														borderRadius: '3px'
													}}
													className='form-control '
													placeholder={'Inscrição estadual'}
													maxlength="18"
													type="text"
													name="stateRegistration"
												

												/>
											</div>							
											<div class="form-group col-md-8">
												<label>Endereço</label>
												<Field
													style={{
														borderColor: '#c4c4c4',
														borderRadius: '3px'
													}}
													className='form-control'
													placeholder={'Endereço'}
													type="text"
													name="address"
												/>
											</div>
											<div class="form-group col-md-2">
												<label>Número</label>
												<Field
													style={{
														borderColor: '#c4c4c4',
														borderRadius: '3px'
													}}
													className='form-control'
													placeholder={'Número'}
													type="text"
													name="number"
												/>
											</div>
											<div class="form-group col-md-2">
												<label>Complemento</label>
												<Field
													style={{
														borderColor: '#c4c4c4',
														borderRadius: '3px'
													}}
													className='form-control'
													placeholder={'Complemento'}
													type="text"
													name="complement"
												/>
											</div>
											<div class="form-group col-md-6">
												<label>Cep</label>
												<Field
													style={{
														borderColor: '#c4c4c4',
														borderRadius: '3px'
													}}
													className='form-control'
													placeholder={'CEP'}
													type="text"
													name="cep"
												/>
											</div>
											<div class="form-group col-md-2">
												<label>Bairro</label>
												<Field
													style={{
														borderColor: '#c4c4c4',
														borderRadius: '3px'
													}}
													className='form-control'
													placeholder={'Bairro'}
													type="text"
													name="district"
												/>
											</div>
											<div class="form-group col-md-2">
												<label>Cidade</label>
												<Field
													style={{
														borderColor: '#c4c4c4',
														borderRadius: '3px'
													}}
													className='form-control'
													placeholder={'Cidade'}
													type="text"
													name="city"
												/>
											</div>
											<div class="form-group col-md-2">
												<label>UF</label>
												<Field
													as={Select}
													native
												style={{
													padding:'6px',
													borderColor: '#c4c4c4',
													borderRadius: '3px'
												}}
												className='form-control'
												type="text" 
												name="uf"
												>
												{States().map((state) => (
													<option
														style={{ cursor: 'pointer' }}
														key={state.uf} 
														value={state.uf}
													>
														{state.name}
													</option>
												))}
												</Field>
											</div>
										</div>
										<div className={classes.multFieldLine}>
											<FormControl
												variant="outlined"
												className={classes.formControl}
												margin="dense"
											>
											</FormControl>
										</div>
									</DialogContent>
									<DialogActions>
										<Button
											onClick={handleClose}
											color="secondary"
											disabled={isSubmitting}
											variant="outlined"
										>
											{'cancelar'}
										</Button>
										<Button
											type="submit"
											color="primary"
											disabled={isSubmitting}
											variant="contained"
											className={classes.btnWrapper}
										>
											{companyId
												? `${'Salvar'}`
												: `${'Cadastrar'}`}
											{isSubmitting && (
												<CircularProgress
													size={24}
													className={classes.buttonProgress}
												/>
											)}
										</Button>
									</DialogActions>
								</Form>
							}
						</>
					)}
				</Formik>

			</Dialog>
		</div>
	);
};

export default CompanyUserModal;
