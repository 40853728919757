import { Box, Divider } from "@material-ui/core";
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Tooltip, Typography } from '@mui/material';
import generalStyles from "../GeneralValues/styles.json";
import RenderIcon from "../RenderIcon";

const EndElement = ({
    id,
    nameId,
    subtype,
    onEdit,
    onDelete,
    props
}) => {

    return (
        <Box 
            onClick={(event) => {onEdit(event, nameId);}}
            component='div' 
            className="showOptions" 
            id={nameId} 
            data-type="end" 
            data-subtype={subtype} 
        >
            <Tooltip title={<Typography gutterBottom color="inherit">Deletar</Typography>} placement="top">
                <IconButton
                    className="configButton"
                    style={generalStyles.deleteButton}
                    onClick={() => onDelete(nameId)}
                    data-delete="1"
                >
                    <DeleteIcon />
                </IconButton>
            </Tooltip>

            <Box component='p' className="iconHeaderObject">
                <RenderIcon type={subtype == 'transfer' ? 'transfer' : "end"} />
            </Box>
            <Box component="p" className="headerObject" style={generalStyles.title}>
                {props?.title ? props.title : subtype == 'transfer' ? 'Transferir' : `Fim do fluxo ${id}`}
            </Box>
            <Divider className="headerDivider" />

            <Box
                component='div'
                style={generalStyles.message}
            >
                <Box component='p' className="bodyObject" style={{ margin: '5px' }}>
                    {props?.message || ''}
                </Box>

                <Box
                    component="p"
                    className="endOption"
                    style={{ display: 'none' }}
                >
                    {props?.endOptionProps || 0}
                </Box>
            </Box>
        </Box>
    );
}

export default EndElement;