import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import toastError from "../../errors/toastError";


import Select from "@material-ui/core/Select";

import api from "../../services/api";


import { toast } from "react-toastify";
import { i18n } from "../../translate/i18n";
import JsonParse from "../../helpers/JsonParse";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},

	extraAttr: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	select: {
		padding: 0,
		"& li": {
			padding: 5,
			fontSize: 12,
		},
	},
}));


const ConfirmationImportModal = ({ title, children, open, onClose, onConfirm, isDelete }) => {
	const classes = useStyles();
	const [whatsapp, setWhatsapp] = useState();
	const [connection, setConnection] = useState('');
	const [queues, setQueues] = useState([]); // filas sem filtro buscadas na base
	const [filteredQueues, setFilteredQueues] = useState([]); // filas filtradas para mostrar no select
	const [selectedQueue, setSelectedQueue] = useState('');

	useEffect(() => {
		const fetchSession = async () => {
			try {
				await getQueues();
				const { data } = await api.get("/whatsapp/");
				setWhatsapp(JSON.stringify(data))
			} catch (err) {
				toastError(err);
			}
		};
		fetchSession();
	}, []);

	function renderItemConnection(conexao) {
		if (!conexao.numberConnection) {
			return
		}
		return <MenuItem 
					value={conexao.id}
				>
					{conexao.name} - {conexao.numberConnection}
				</MenuItem>
	}

	function RenderSelectConnection(Connections) {

		var rows = [];
		for (var conexao in Connections) {
			rows.push(renderItemConnection(Connections[conexao]));
		}

		return rows;
	}
	const SelectedConnection = (event) => {
		setConnection(event.target.value);
		filterQueues(event.target.value);
	};

	const VerifyConnection = () => {
		if (isDelete) {
			onClose(false);
			onConfirm()
		} else {
			if (!connection) return toast.error('Selecione uma conexão.');
			if (!selectedQueue) return toast.error('Selecione uma fila.');
			
			onClose(false);
			onConfirm(
				{
					"idConnection": connection,
					"queueId": selectedQueue
				}
			);
		}
	}

	function clearInputs() {
		setWhatsapp('');
		setQueues([]);
		setFilteredQueues([]);
		setSelectedQueue('');
	}

	// função para resgatar as filas e suas conexões
	async function getQueues() {
		// resetar lista
		setQueues([]);
		// resgatar lista de filas da base de dados
		const resp = await api.get(`/queueListWithConnections/`);
		let response_tmp = resp.data;
		if (typeof response_tmp == 'undefined' || response_tmp == 'null' || response_tmp == '' || response_tmp == null || response_tmp.length == 0 || resp.status !== 200) {
			return toast.error('Erro ao resgatar filas, contate o administrador.');
		}
		// se o whatsappId não for nulo, percorrer retorno retirando as filas sem whatsappId, pois não estavam no filtro
		setQueues(response_tmp);
		return;
	}
	// função para filtrar filas de acordo com a conexão
	function filterQueues(whatsappId) {
		setFilteredQueues([]);
		setSelectedQueue('');
		if (!whatsappId || typeof whatsappId == 'undefined' || whatsappId == '' || whatsappId == null || whatsappId == 'null') {
			return toast.error('Erro ao resgatar filas localmente, contate o administrador.');
		}
		whatsappId = String(whatsappId);
		queues.map((item) => {
			// se a conexão bater com o parametro local, adicionar ao array
			if (item.whatsappConnections) {
				let whatsappIds = item.whatsappConnections.split(';');
				if (whatsappIds.indexOf(whatsappId) >= 0) {
					setFilteredQueues(oldFilteredQueues => [
						...oldFilteredQueues,
						{
							id: item.id, 
							name: item.name,
						}
					]);
				}
			}
		});
		return;
	}

	return (
		<Dialog
			open={open}
			onClose={() => onClose(false)}
			aria-labelledby="confirm-dialog"
		>
			<DialogTitle id="confirm-dialog">{title}</DialogTitle>
			<DialogContent dividers>
				<Typography>{children}</Typography>
			</DialogContent>
			{!isDelete &&
				<>
					{whatsapp &&
						<>
							<div class="form-group col-md-12" style={{ padding: '25px 25px 0px 25px', maxWidth: '330px' }}>
								<label style={{ marginBottom: '11px' }}>
									Importar para a conexão
									<span className="text-danger ml-2">*</span>
								</label>
								<Select
									variant="outlined"
									style={{ width: '100%', height: '38px' }}
									MenuProps={{ classes: { paper: classes.select } }}
									value={connection}
									onChange={SelectedConnection}
								>
									{RenderSelectConnection(JsonParse(whatsapp))}
								</Select>
							</div>
							<div class="form-group col-md-12" style={{ padding: '0 25px', maxWidth: '330px' }}>
								<label style={{ marginBottom: '11px' }}>
									Fila
									<span className="text-danger ml-2">*</span>
								</label>
								<Select
									variant="outlined"
									style={{ width: '100%', height: '38px' }}
									MenuProps={{ classes: { paper: classes.select } }}
									disabled={filteredQueues == null || filteredQueues.length == 0 ? true: false}
									value={selectedQueue}
									onChange={function(e) {
										setSelectedQueue(e.target.value);
									}}
									required
								>
									{
										filteredQueues.map((item) => {
											return <MenuItem value={item.id}>{item.name}</MenuItem>
										})
									}
								</Select>
							</div>
						</>
					}
				</>
			}
			<DialogActions>
				<Button
					variant="contained"
					onClick={() => onClose(false)}
					color="default"
				>
					{i18n.t("confirmationModal.buttons.cancel")}
				</Button>
				<Button
					variant="contained"
					onClick={() => { VerifyConnection() }}
					color="secondary"
				>
					{i18n.t("confirmationModal.buttons.confirm")}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ConfirmationImportModal;
