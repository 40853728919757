import { Tooltip, Typography, withStyles } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { Field } from "formik";
import React from 'react';


const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 350,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

const GroupedInputStyled = ({ label, inputsProps, primaryClass, primaryStyle, separator, footer, options, disabled, tooltip, ...props }) => {
    return (
        <div style={primaryStyle} className={primaryClass}>
            {label && <>
                <label style={{
                    margin: '0px 0px -25px 0px',
                    width: '100%',
                    margin: '0px',
                    display: 'flex'
                }}>
                    <div style={{ display: 'flex', marginBottom: '6px' }}>
                        {label}
                        <div>
                            <span className="text-danger ml-2">*</span>
                            {tooltip && <HtmlTooltip
                                title={
                                    <React.Fragment>
                                        <Typography color="inherit">{label}</Typography>
                                        {tooltip}
                                    </React.Fragment>
                                }
                            >
                                <InfoIcon />
                            </HtmlTooltip>}
                        </div>
                    </div>
                </label>
            </>}
            <div className="input-group mb-2" >
                {inputsProps.map((input, i) => (<>
                    {i > 0 && <>
                        <div
                            className="input-group-prepend">
                            <div
                                style={{ height: '35px', padding: '5px' }}
                                className="input-group-text">{separator}</div>
                        </div>
                    </>}
                    <Field
                        {...props}
                        id={input.name}
                        name={input.name}
                        value={input.value}
                        placeholder={input.placeholder}
                        required={!!input?.required}
                        disabled={disabled}
                        onChange={e => input.onChange(e)}
                        onBlur={e => {input.onBlur && input.onBlur(e)}}
                    />
                </>))}
            </div>
            {footer && <div style={{
                margin: '-5px 0px 0px 0px',
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                {inputsProps.map((input, i) => (<>
                    <small style={{ marginLeft: '15px' }}>{input.name}</small>
                </>))}
            </div>}
        </div>
    );
}

export default GroupedInputStyled;
