import { CardHeader } from "@material-ui/core";
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useState } from "react";
import api from "../../services/api";
import JsonParse from "../../helpers/JsonParse";

function MountAvatar({ contatosArray }) {

	const [contatos, setContatos] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const contactsArray = JsonParse(contatosArray);
		const forLoop = async () => {
			setLoading(false);
			contactsArray.forEach(async (contatoId) => {
				try {
					const { data } = await api.get(`/contacts/${contatoId}`);
					setContatos(oldArray => [...oldArray, data]);
				} catch (err) {
					console.warn(err);
				}
			});
			setLoading(true);
		}
		forLoop();
	}, []);


	return (
		<div style={{ display: 'flex' }}>
			{
				loading ? <>
					{contatos.map((contato) => (
						<div style={{ padding: '0px 2px 0px 2px' }}>
							<Chip
								avatar={<Avatar src={contato?.profilePicUrl} alt="contact_image" />}
								label={contato.name}
								variant="outlined"
							/>
						</div>
					))}
				</>
					:
					<>
						<CircularProgress color="inherit" />
					</>
			}

		</div>
	);
}


export default function broadcastInfo({ contacts, broadcast }) {

	return (
		<div style={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}>
			<p style={{ margin: '0px 10px 0px 0px' }}>
				{broadcast.name}
			</p>
			<div style={{ padding: '45px 1px 0px 0px', backgroundColor: '#19a784' }}></div>
			<CardHeader
				style={{ cursor: "pointer" }}
				titleTypographyProps={{ noWrap: true }}
				subheaderTypographyProps={{ noWrap: true }}
				avatar={<MountAvatar contatosArray={contacts} />}
			/>
			{/*<IconButton title={'Adicionar participantes'} aria-label="delete">
				<GroupAddIcon />
			</IconButton>*/}
		</div>
	);
};


