
import { Button, IconButton, Menu, MenuItem, Paper, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { KTSVG } from '../../../_metronic/helpers';
import profileNoPic from "../../assets/blank.png";
import { AuthContext } from "../../context/Auth/AuthContext";
import { ReplyMessageProvider } from "../../context/ReplyingMessage/ReplyingMessageContext";
import toastError from "../../errors/toastError";
import socket from "../../hooks/useSocket";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import ContactConcludeTicket from "../ContactConcludeTicket"; //Concluir Atendimento
import ContactCreateTicketModal from "../ContactCreateTicketModal"; //Criar Ticket **DESABILITADO AINDA**
import ContactFlashMessenger from "../ContactFlashMessenger"; //Mensagens Rapidas
import ContactInfoModal from "../ContactInfoModal"; //informacoes de contato
import ContactTransferModal from "../ContactTransferModal"; //Transferencia de atendimento
import MessageInput from "../MessageInput";
import MessagesList from "../MessagesList";
import ForContactsModal from "../Tag/ForContactsModal";
import TicketActionButtons from "../TicketActionButtons";
import TicketHeader from "../TicketHeader";
import TicketInfo from "../TicketInfo";
import './style.scss';

function getUrlTicketPath() {
  var url = window.location.href;
  var partesDaURL = url.split('/');
  var codigo = partesDaURL[partesDaURL.length - 1];
  return codigo;
};

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    position: "relative",
    overflow: "hidden",
  },
  ticketInfo: {
    maxWidth: "50%",
    flexBasis: "50%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "80%",
      flexBasis: "80%"
    },
  },
  buttonDesktop: {
    display: 'flex',
    position: "absolute",
    right: "5px",
    top: "63px",
    '@media(max-width: 810px)': {
      display: "none",
    }
  },
  buttonMobile: {
    display: 'none',
    position: "absolute",
    right: "5px",
    top: "20px",
    '@media(max-width: 810px)': {
      display: "flex",
    }
  },
  buttonsStyle: {
    borderRadius: "0px",
    borderColor: "black",
    color: "black",
    backgroundColor: "white",
    margin: "0px 5px 0px 5px",
  },
  ticketActionButtons: {
    maxWidth: "50%",
    flexBasis: "50%",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      flexBasis: "100%",
      marginBottom: "5px",
    },
  },

  mainWrapper: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeft: "0",
    marginRight: -drawerWidth,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  mainWrapperShift: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  filasTexto: {
    display: 'flex',
    padding: '27px',
    position: 'absolute',
    marginLeft: '28rem',
    '@media(max-width: 1250px)': {
      display: "none !important",
    }
  },
}));


const Ticket = () => {

  const { ticketId } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { user } = useContext(AuthContext);
  const [contactTicket, setContactTicket] = useState({})
  const [drawerContactInfoModalOpen, setDrawerContactInfoModalOpen] = useState(false);
  const [drawerTransferModalOpen, setDrawerTransferModalOpenOpen] = useState(false);
  const [drawerConcludeTicketOpen, setDrawerConcludeTicketOpen] = useState(false);
  const [drawerFlashMessengerOpen, setDrawerFlashMessengerOpen] = useState(false);
  const [drawerCreateTicketModalOpen, setDrawerCreateTicketModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [contact, setContact] = useState({});
  const [ticket, setTicket] = useState({});
  const [forContactsModalOpen, setForContactsModalOpen] = useState(false);
  const urlTicketUUID = getUrlTicketPath();

  const { adminId, profile, queues } = user;

  //Modal Informacoes de Contato
  const handleContactInfoModalOpen = () => {
    handleCloseButton()
    setDrawerContactInfoModalOpen(true);
  };
  const handleContactInfoModalClose = () => {
    setDrawerContactInfoModalOpen(false);
  };

  //Modal Transferencia
  const handleDrawerTransferTicketOpen = () => {
    handleCloseButton()
    setDrawerTransferModalOpenOpen(true);
  };
  const handleTransferModalClose = () => {
    setDrawerTransferModalOpenOpen(false);
  };

  const handleDrawerConcludeTicketClose = () => {
    setDrawerConcludeTicketOpen(false);
  };

  //Modal Mensagens Rapidas

  const handleDrawerFlashMessengerOpen = () => {
    handleCloseButton()
    setDrawerFlashMessengerOpen(true);

  };
  const handleDrawerFlashMessengerClose = () => {
    setDrawerFlashMessengerOpen(false);
  };

  //Modal Criat Ticket **Desabilitado**
  const handleDrawerCreateTicketModalClose = () => {
    setDrawerCreateTicketModalOpen(false);
  };

  const handleOpenForContactsModal = () => {
    setForContactsModalOpen(true);
  };

  const handleCloseForContactsModal = () => {
    setForContactsModalOpen(false);
  };

  const handleDrawerConcludeTicketOpen = () => {
    handleCloseButton()
    setDrawerConcludeTicketOpen(true);
  };

  const handleAddForContactsModal = tagsIdReturn => {
    if (!tagsIdReturn || tagsIdReturn.length < 1) setContact({ ...contact, tagsId: null });
  };

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchTicket = async () => {
        try {
          const { data } = await api.get("/tickets/u/" + ticketId);
          const ct = data.Contact;
          setContact(ct);
          setTicket(data);
        } catch (err) {
          toastError(err);
        } finally {
          setLoading(false);
        }
      };
      fetchTicket();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [ticketId, user, history]);

  useEffect(() => {

    socket.on("connect", () => socket.emit("joinChatBox", `${ticket.id}`));

    socket.on(`ticket-${adminId}`, (data) => {
      if (data.action === "update") {
        if (data.message?.ticket?.uuid === urlTicketUUID) {
          if (data.message?.ticket) setTicket(data.message.ticket);
          else if (data.ticket) setTicket(data.ticket);
        }
      }

      if (data.action === "delete" && ticket.id === data.ticketId) {
        if (data.message?.ticket?.uuid === urlTicketUUID) {
          history.push("/tickets");
        }
      }
    });

    socket.on(`contact-${adminId}`, (data) => {
      if (data.action === "update") {
        setContact((prevState) => {
          if (prevState.id === data.contact?.id) {
            return { ...prevState, ...data.contact };
          }
          return prevState;
        });
      }
    });

    return () => { };
  }, [ticketId, ticket, history]);

  const transferToAwait = async () => {

    setLoading(true);

    let data = {};
    data.isCampaign = false;
    data.status = 'pending';
    // data.userId = user.id;
    // data.userIdTranfer = user.id;
    // data.transfer = 1;

    try {
      await api.put(`/tickets/${ticket.id}`, data);
      toast.success("Movido com sucesso!")
    } catch (err) {
      console.err(err)
    }
    setLoading(false);
    history.push(`/tickets`);
  }

  function handleMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleCloseButton() {
    setAnchorEl(null);
  }

  const RenderMessagesList = useMemo(() => {
    return (
      <>
        <MessagesList
          ticket={ticket}
          ticketId={ticket.id}
          isGroup={ticket.isGroup}
        >
        </MessagesList>
        <MessageInput ticketId={ticket.id} uuid={ticket.uuid} ticketStatus={ticket.status} contactId={ticket?.Contact?.id || ticket.contactId} whatsapp={ticket.whatsapp} ticketQueue={ticket.queueId} />
      </>
    )
  }, [ticket]);

  function retornaDados(ticket) {

    if (ticket.Contact) {
      var montarFila = "";
      var pegarImg = "";
      if (ticket) {
        if (ticket.Queue) {
          montarFila = `${ticket.Queue.name} - ${ticket.whatsapp.numberConnection || ticket.numberConnection}`;
        } else {
          montarFila = `Sem fila - ${ticket.whatsapp.numberConnection || ticket.numberConnection}`;
        }
        if (ticket.whatsapp) {
          pegarImg = ticket.whatsapp.picConnection;
        } else {
          pegarImg = profileNoPic;
        }

      } else {
        montarFila = `Sem fila - ${ticket.whatsapp.numberConnection || ticket.numberConnection}`
        pegarImg = profileNoPic;
      }

      if (!pegarImg || pegarImg == '' || pegarImg == null) pegarImg = '/media/avatars/blank.png';

      document.getElementById("picConnection").src = pegarImg;
      document.getElementById("definirFila").innerHTML = montarFila;
    }
  }

  return (
    <div className={classes.root} id="drawer-container">
      <Paper
        variant="outlined"
        elevation={0}
        className={clsx(classes.mainWrapper, {
          [classes.mainWrapperShift]: drawerContactInfoModalOpen,
        })}
      >
        <TicketHeader loading={loading}>
          {retornaDados(ticket)}
          <div className={classes.ticketInfo} style={{ display: 'flex' }}>
            <TicketInfo
              contact={contact}
              ticket={ticket}
              onClick={handleContactInfoModalOpen}
            />
            <TicketActionButtons ticket={ticket} />
            {(ticket.status == "open" || ticket.status == "pending") && (
              <>
                <div className={classes.buttonDesktop}>
                  <Button
                    className='btn btn-sm btn-secundary'
                    loading={loading}
                    size="small"
                    variant="contained"
                    color="primary"
                    style={{
                      fontSize: "9px",
                      letterSpacing: ".5px",
                      fontWeight: "700",
                      padding: "1px 5px!important",
                      margin: "0 3px",
                      backgroundColor: '#5191c1'
                    }}
                    onClick={handleOpenForContactsModal}
                  // onClick={e => transferToAwait(e)}
                  >
                    {i18n.t("ticketOptionsMenu.linkTags")}
                  </Button>
                  {
                    ticket.status == "open" &&
                    <Button
                      className='btn btn-sm btn-secundary'
                      loading={loading}
                      size="small"
                      variant="contained"
                      color="primary"
                      style={{
                        fontSize: "9px",
                        letterSpacing: ".5px",
                        fontWeight: "700",
                        padding: "1px 5px!important",
                        margin: "0 3px",
                        backgroundColor: '#21b981'
                      }}
                      onClick={e => transferToAwait(e)}
                      disabled={!ticket?.whatsapp?.status || String(ticket.whatsapp.status).toLowerCase() != 'connected'}
                    >
                      {i18n.t("ticketOptionsMenu.transferToAwait")}
                    </Button>
                  }
                  <Button
                    className='btn btn-sm btn-secundary'
                    loading={loading}
                    size="small"
                    variant="contained"
                    color="primary"
                    style={{
                      fontSize: "9px",
                      letterSpacing: ".5px",
                      fontWeight: "700",
                      padding: "1px 5px!important",
                      margin: "0 3px",
                      backgroundColor: '#5191c1'
                    }}
                    onClick={handleDrawerFlashMessengerOpen}
                    disabled={!ticket?.whatsapp?.status || String(ticket.whatsapp.status).toLowerCase() != 'connected'}
                  >
                    {i18n.t("messagesList.header.buttons.autoMessage")}
                  </Button>
                  <Button
                    className='btn btn-sm btn-secundary'
                    loading={loading}
                    size="small"
                    variant="contained"
                    color="primary"
                    style={{
                      fontSize: "9px",
                      letterSpacing: ".5px",
                      fontWeight: "700",
                      padding: "1px 5px!important",
                      margin: "0 3px",
                    }}
                    onClick={handleDrawerTransferTicketOpen}
                    disabled={!ticket?.whatsapp?.status || String(ticket.whatsapp.status).toLowerCase() != 'connected'}
                  >
                    {i18n.t("ticketOptionsMenu.transfer")}
                  </Button>

                  <Button
                    className='btn btn-sm btn-secundary'
                    loading={loading}
                    size="small"
                    variant="contained"
                    color="primary"
                    style={{
                      fontSize: "9px",
                      letterSpacing: ".5px",
                      fontWeight: "700",
                      padding: "1px 5px!important",
                      margin: "0 3px",
                      backgroundColor: '#2b73a9'

                    }}
                    onClick={handleDrawerConcludeTicketOpen}
                  >
                    {i18n.t("messagesList.header.buttons.conclude")}
                  </Button>
                </div >
                <div className={classes.buttonMobile}>
                  <IconButton
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                  >
                    <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right"
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right"
                    }}
                    open={open}
                    onClose={handleCloseButton}
                  >
                    <MenuItem onClick={handleDrawerConcludeTicketOpen}>{i18n.t("messagesList.header.buttons.conclude")}</MenuItem>
                    <MenuItem onClick={handleDrawerTransferTicketOpen}>{i18n.t("ticketOptionsMenu.transfer")}</MenuItem>
                    <MenuItem onClick={e => transferToAwait(e)}>{i18n.t("ticketOptionsMenu.transferToAwait")}</MenuItem>
                    <MenuItem onClick={handleDrawerFlashMessengerOpen}>{i18n.t("messagesList.header.buttons.autoMessage")}</MenuItem>
                  </Menu>
                </div>
              </>
            )}
          </div>
        </TicketHeader>
        <ReplyMessageProvider>
          {RenderMessagesList}
        </ReplyMessageProvider>
      </Paper>
      <ContactInfoModal
        open={drawerContactInfoModalOpen}
        handleDrawerClose={handleContactInfoModalClose}
        contact={contact}
        loading={loading}
        contactId={contact.id}
      />
      <ContactTransferModal
        open={drawerTransferModalOpen}
        handleDrawerClose={handleTransferModalClose}
        contact={contact}
        ticket={ticket}
        loading={loading}
        userTranfer={user.id}
      />
      <ContactConcludeTicket
        open={drawerConcludeTicketOpen}
        handleDrawerClose={handleDrawerConcludeTicketClose}
        contact={contact}
        ticketid={ticketId}
        ticket={ticket}
        loading={loading}
      />
      <ContactFlashMessenger
        open={drawerFlashMessengerOpen}
        handleDrawerClose={handleDrawerFlashMessengerClose}
        contact={contact}
        ticketid={ticket.id}
        loading={loading}
      />
      <ContactCreateTicketModal
        open={drawerCreateTicketModalOpen}
        handleDrawerClose={handleDrawerCreateTicketModalClose}
        contact={contact}
        ticketid={ticketId}
        initialContact={contactTicket}
        loading={loading}
      />
      <ForContactsModal
        open={forContactsModalOpen}
        onClose={handleCloseForContactsModal}
        onSave={handleAddForContactsModal}
        aria-labelledby="form-dialog-title"
        tagsId={contact?.tagsId}
        contactId={contact.id}
      />
    </div>
  );
};

export default Ticket;
