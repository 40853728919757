import React from "react";
import ReactDOM from "react-dom";
import $ from 'jquery';
import Highcharts, { Pointer } from 'highcharts'
import Chart from "./index.js";

require("highcharts/modules/exporting")(Highcharts);

const RelatoryTicket = ({ status, tickets, title, subtitle }) => {
  
  if (status === undefined) { // Para qualquer erro que acontecer nao crashar a aplicacao.
    status = {
      "open": 0,
      "closed": 0,
      "pending": 0
    }
  }

  return (
    <div className='card-body p-0 d-flex justify-content-evenly flex-column overflow-hidden' style={{ borderRadius: '20px' }}>
      <div className='d-flex flex-stack flex-wrap px-9 pt-9 pb-3'>
        <div className='me-2'>
          <span className='fw-bolder text-gray-800 d-block fs-3'>{title}</span>
          <span className='text-gray-400 fw-bold'>{subtitle}</span>
        </div>
        <div className={`fw-bolder fs-3 text-${'info'}`}></div>
      </div>
      <div className='mixed-widget-10-chart' style={{
        display: 'flex',
        justifyContent: 'center'
      }}>
        <div className="App" style={{
          width: '100%',
          height: '100%',
          maxWidth: '395px',
          maxHeight: '400px'
        }}>
          <div
            style={{
              padding: '10px'
            }}>
            <Chart
              pending={status.pending}
              open={status.open}
              closed={status.closed}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RelatoryTicket;
