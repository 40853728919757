const DatePickerBootstrap = ({ primaryClass, label, secondClass, onClickElement, onChangeDate, minDate, value, required = true, ...props }) => {
    return (<>
        <div className={primaryClass}>
            <label>
                {label}
                <span className="text-danger ml-2">*</span>
            </label>
            <div className={secondClass}
                onClick={e => onClickElement(e)}>
                <input
                    type="date"
                    ariant="outlined"
                    {...props}
                    onChange={e => onChangeDate(e)}
                    style={{ height: '35px', width: '85px' }}
                    maxLength="2"
                    className="form-control"
                    min={minDate ? (minDate) : '20/01/2023'}
                    value={value ? (value) : '20/01/2023'}
                    placeholder="dd-mm-yyyy"
                    required={required} />
            </div>
        </div>
    </>)
}

export default DatePickerBootstrap