import React from 'react';
import { Field } from "formik";
import { FormControl, InputLabel, Typography, Tooltip, Select, MenuItem, withStyles } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';


const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 350,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

const InputStyled = ({ label, name, primaryClass, options, tooltip, ...props }) => {
    return (
        <div className={primaryClass}>
            {label && <>
                <label>
                    <div style={{ display: 'flex' }}>
                        {label}
                        <span className="text-danger ml-2">*</span>
                        {tooltip && <HtmlTooltip
                            title={
                                <React.Fragment>
                                    <Typography color="inherit">{label}</Typography>
                                    {tooltip}
                                </React.Fragment>
                            }
                        >
                            <InfoIcon />
                        </HtmlTooltip>}
                    </div>
                </label>
            </>}
            {
                options
                    ? (
                        <Field
                            id={name}
                            name={name}
                            {...props}
                        >
                            {options.map(option => (
                                <MenuItem 
                                    key={option.value} 
                                    value={option.value} 
                                    disabled={option.disabled == undefined || option.disabled == 'disabled' || typeof option.disabled != "boolean" ? false : option.disabled}
                                    title={option.title || ''}
                                >
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Field>
                    )
                    : (
                        <Field
                            id={name}
                            name={name}
                            {...props}
                        />
                    )
            }
        </div>
    );
}

export default InputStyled;
