import React, { useState } from 'react';
import { IconButton, Popover } from '@material-ui/core';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import EmojiPicker from 'emoji-picker-react';

const EmojiDataPicker = ({ onEmojiSelect, disabled = false }) => {
    const [emojiAnchorEl, setEmojiAnchorEl] = useState(null);

    const handleEmojiClick = (event) => {
        setEmojiAnchorEl(event.currentTarget);
    };

    const handleEmojiClose = () => {
        setEmojiAnchorEl(null);
    };

    const handleEmojiSelect = (event, emojiObject) => {

        onEmojiSelect(event.emoji);

        handleEmojiClose();
    };

    const isOpen = Boolean(emojiAnchorEl);

    return (
        <>
            <IconButton
                disabled={disabled}
                aria-label="emoji"
                onClick={handleEmojiClick}
            >
                <EmojiEmotionsIcon />
            </IconButton>
            <Popover
                PaperProps={{
                    style: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                    },
                }}
                open={isOpen}
                anchorEl={emojiAnchorEl}
                onClose={handleEmojiClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <EmojiPicker onEmojiClick={handleEmojiSelect} />
            </Popover>
        </>
    );
};

export default EmojiDataPicker;
