import { Field, Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	Switch,
	TextField,
	Grid,
	Typography,
	Select,
	MenuItem,
	Tooltip
} from "@material-ui/core";

import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import QueueSelect from "../QueueSelect";
import HelpIcon from '@material-ui/icons/Help';


const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},

	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},

	type: {
		"& .MuiOutlinedInput-notchedOutline": {
			border: "none"
		}
	}
}));

const SessionSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Muito Curto(a)!")
		.max(50, "Muito Longo(a)!")
		.required("Obrigatório"),
});

const WhatsAppModal = ({ open, onClose, whatsAppId, userProfile }) => {
	const { user: loggedInUser } = useContext(AuthContext);

	const classes = useStyles();
	const initialState = {
		adminId: loggedInUser.adminId,
		name: "",
		greetingMessage: "",
		isDefault: false,
		token: "",
		option: "receptive"
	};
	const [whatsApp, setWhatsApp] = useState(initialState);
	const [selectedQueueIds, setSelectedQueueIds] = useState([]);
	const [selectedChatbotId, setSelectedChatbotId] = useState([]);
	const [getContactOnHold, setGetContactOnHold] = useState(true);
	const [msgNotification, setMsgNotification] = useState(true);

	useEffect(() => {
		const fetchSession = async () => {
			if (!whatsAppId) return;

			try {
				const { data } = await api.get(`whatsapp/${whatsAppId}`);
				setWhatsApp(data);
				const whatsQueueIds = data.Queues?.map(queue => queue.id);
				setSelectedQueueIds(whatsQueueIds);
				setGetContactOnHold(typeof data.getContactOnHold != 'undefined' ? data.getContactOnHold : false);
				setMsgNotification(typeof data.msgNotification != 'undefined' ? data.msgNotification : false);
			} catch (err) {
				toastError(err);
			}
		};
		fetchSession();
	}, [whatsAppId]);

	const handleSaveWhatsApp = async values => {
		const whatsappData = { ...values, queueIds: selectedQueueIds, chatbotId: selectedChatbotId, getContactOnHold, msgNotification };
		// verificar se alguma fila foi selecionada
		if (!selectedQueueIds || selectedQueueIds.length < 1) return toast.error('selecione uma fila');
		try {
			if (whatsAppId) {
				await api.put(`/whatsapp/${whatsAppId}`, { ...whatsappData, greetingMessage: String(whatsappData.greetingMessage).trim() });
			} else {
				await api.post("/whatsapp", { ...whatsappData, greetingMessage: String(whatsappData.greetingMessage).trim() });
			}
			toast.success(i18n.t("whatsappModal.success"));
			handleClose();
		} catch (err) {
			toastError(err);
		}
	};

	const handleClose = () => {
		onClose();
		setWhatsApp(initialState);
		setSelectedQueueIds([]);
		setSelectedChatbotId([]);
	};

	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="sm"
				fullWidth
				scroll="paper"
			>
				<DialogTitle>
					{whatsAppId
						? i18n.t("whatsappModal.title.edit")
						: i18n.t("whatsappModal.title.add")}
				</DialogTitle>
				<Formik
					initialValues={whatsApp}
					enableReinitialize={true}
					validationSchema={SessionSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveWhatsApp(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ values, touched, errors, isSubmitting }) => (
						<Form style={{ padding: "10px" }}>
							<Field
								as={TextField}
								type="hidden"
								name="adminId"
							/>
							<DialogContent dividers>
								<div class="form-group">
									<label>
										{i18n.t("connections.form.name")}
										<span className="text-danger ml-2">*</span>
									</label>
									<div class="form-group" style={{ display: 'flex' }}>
										<Field
											style={{
												borderColor: '#c4c4c4',
												borderRadius: '3px',
											}}
											className='form-control '
											placeholder={i18n.t("whatsappModal.form.name")}
											name="name"
											autoFocus
											required
											error={touched.name && Boolean(errors.name)}
											helperText={touched.name && errors.name}

										/>
										{userProfile === 'user' ? ''
											:
											<FormControlLabel
												hidden
												control={
													<Field

														as={Switch}
														color="primary"
														name="isDefault"
														checked={values.isDefault}
													/>
												}
												label={i18n.t("whatsappModal.form.default")}
											/>
										}
									</div>
								</div>
								<div class="form-group">
									<label>
										{i18n.t("connections.form.message")}
									</label>
									<Field
										as="textarea"
										style={{
											borderColor: '#c4c4c4',
											borderRadius: '3px'
										}}
										rows='4'
										className='form-control '
										type="greetingMessage"
										placeholder={i18n.t("queueModal.form.greetingMessage")}
										name="greetingMessage"
										error={
											touched.greetingMessage && Boolean(errors.greetingMessage)
										}
										helperText={
											touched.greetingMessage && errors.greetingMessage
										}
									/>
								</div>
								<div hidden>
									<label>Token</label>
									<Field
										style={{
											borderColor: '#c4c4c4',
											borderRadius: '3px'
										}}
										as="textarea"
										className='form-control '
										name="token"
										type="token"

										multiline
										fullWidth
										rows='3'
										placeholder={i18n.t("queueModal.form.token")}

									/>
								</div>
								<Grid container spacing={2}>
									<Grid item xs={12} mb={2}>
										<Typography component="label">
											{i18n.t("whatsappModal.form.option.title")}
											<Typography component="span" style={{ marginLeft: '5px' }} color="secondary">*</Typography>
											<Tooltip 
												title={
													<>
														<Typography gutterBottom color="inherit">{i18n.t("whatsappModal.form.option.receptivePhrase")}</Typography>
														<Typography gutterBottom color="inherit">{i18n.t("whatsappModal.form.option.massivePhrase")}</Typography>
													</>
												}
												placement="bottom"
												style={{marginLeft: "10px"}}
											>
												<HelpIcon />
											</Tooltip>
										</Typography>
										<Field
											style={{ height: '42px', marginTop: '10px', borderColor: 'rgba(0, 0, 0, 0.23)' }}
											variant="outlined"
											as={Select}
											name="option"
											className={`form-control ${classes.type}`}
											required
										>
											<MenuItem value="receptive">{i18n.t("whatsappModal.form.option.receptive")}</MenuItem>
											<MenuItem value="massive">{i18n.t("whatsappModal.form.option.massive")}</MenuItem>
										</Field>
									</Grid>
								</Grid>
								<FormControlLabel
									control={<Switch checked={msgNotification} color="primary" onChange={() => setMsgNotification(!msgNotification)} name="msgNotification" />}
									label={i18n.t("connections.form.msgNotification")}
								/>
								<FormControlLabel
									control={<Switch checked={getContactOnHold} color="primary" onChange={() => setGetContactOnHold(!getContactOnHold)} name="getContactOnHold" />}
									label={i18n.t("connections.form.getContactOnHold")}
								/>
								<QueueSelect
									selectedQueueIds={selectedQueueIds}
									onChange={selectedIds => setSelectedQueueIds(selectedIds)}
								/>
								{/*
								<ChatbotSelect
									selectedChatbotId={selectedChatbotId}
									onChange={selectedId => setSelectedChatbotId(selectedId)} />
								*/}
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("whatsappModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{whatsAppId
										? i18n.t("whatsappModal.buttons.okEdit")
										: i18n.t("whatsappModal.buttons.okAdd")}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default React.memo(WhatsAppModal);
