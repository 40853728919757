import QRCode from "qrcode.react";
import React, { useContext, useEffect, useState } from "react";
import openSocket from "socket.io-client";
import toastError from "../../errors/toastError";

import { CircularProgress, Dialog, DialogContent, Paper, Typography } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import socket from "../../hooks/useSocket";

const useStyles = makeStyles(theme => ({
	buttonProgress: {
		color: green[500],
	},
	paperFlex: {
		display: "flex",
		flexDirection: "column",
		gap: "20px",
		alignItems: "center",
		paddingBottom: "15px"
	}
}));

const QrcodeModal = ({ open, onClose, whatsAppId }) => {
	const classes = useStyles();
	const [qrCode, setQrCode] = useState("");
	const [isLoading, setLoading] = useState(false);
	const { user } = useContext(AuthContext);

	const handleRequestNewQrCode = async whatsAppId => {
		try {
			setLoading(true);
			await api.put(`/whatsappsession/${whatsAppId}`);
		} catch (err) {
			toastError(err);
		}
	};

	useEffect(() => {
		const fetchSession = async () => {
			if (!whatsAppId) return;

			try {
				const { data } = await api.get(`/whatsapp/${whatsAppId}`);
				setQrCode(data.qrcode);
			} catch (err) {
				toastError(err);
			}
		};
		fetchSession();
	}, [whatsAppId]);

	useEffect(() => {
		if (!whatsAppId) return;
		socket.on(`whatsappSession-${user.adminId}`, data => {
			if (data.action === "update" && data.session.id === whatsAppId && (data.session.qrcode !== qrCode && data.session.qrcode !== "")) {
				setQrCode(data.session.qrcode);
				setLoading(false);
			}

			if (data.action === "update" && data.session.qrcode === "") {
				onClose();
			}
		});
		return () => {};
	}, [whatsAppId, onClose, qrCode]);

	return (
		<Dialog open={open} onClose={onClose} maxWidth="lg" scroll="paper">
			<DialogContent>
				<Paper elevation={0} className={classes.paperFlex}>
					<Typography color="primary" gutterBottom>
						{i18n.t("qrCode.message")}
					</Typography>
					{qrCode && !isLoading ? (
						<>
							<QRCode value={qrCode} size={256} />
							{/* <Button
								size="small"
								variant="outlined"
								color="primary"
								onClick={() => handleRequestNewQrCode(whatsAppId)}
							>
								{i18n.t("connections.buttons.newQr")}
							</Button> */}
						</>
					) : (
						<CircularProgress size={24} className={classes.buttonProgress} />
					)}
				</Paper>
			</DialogContent>
		</Dialog>
	);
};

export default React.memo(QrcodeModal);
