import { Button, IconButton } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { makeStyles } from "@material-ui/core/styles";
import AccessTime from "@material-ui/icons/AccessTime";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";
import SearchIcon from "@material-ui/icons/Search";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddCommentIcon from '@mui/icons-material/AddComment';
import BroadcastOnPersonalIcon from '@mui/icons-material/BroadcastOnPersonal';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import profileNoPic from "../../assets/blank.png";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import ContactModal from "../ContactModal";
import ListBroadcastModal from "../ListBroadcastModal";
import NewTicketModal from "../NewTicketModal";
import TabPanel from "../TabPanel";
import TicketSearch from "../TicketSearch/TicketSearch";
import TicketsList from "../TicketsList";
import FilteredTickets from "../../helpers/FilteredTickets";
import socket from "../../hooks/useSocket";
import format from "date-fns/format";
import useSound from "use-sound";
import alertSound from "../../assets/sound.mp3";
import JsonParse from "../../helpers/JsonParse";

const useStyles = makeStyles((theme) => ({
  ticketsWrapper: {
    position: "relative",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflow: "hidden",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },

  tabsHeader: {
    display: "flex",
    backgroundColor: "#eee",
    padding: "16px",
    alignItems: " center"
  },

  settingsIcon: {
    alignSelf: "center",
    marginLeft: "auto",
    padding: 8,
  },

  tab: {
    minWidth: '21%',
    width: '21%',
    fontSize: '9px',
    height: '63px',
    minHeight: '35px !important'
  },

  ticketOptionsBox: {
    marginTop: '5px',
    height: '30px',
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#fafafa",
    padding: '0px 0px 0px 0px'
  },

  serachInputWrapper: {
    flex: 1,
    background: "#fff",
    display: "flex",
    borderRadius: 40,
    padding: 4,
    marginRight: theme.spacing(1),
  },

  searchIcon: {
    color: "grey",
    marginLeft: 6,
    marginRight: 6,
    alignSelf: "center",
  },

  searchInput: {
    flex: 1,
    border: "none",
    borderRadius: 30,
  },
  atendimentoUsuario: {
    display: "flex",

  },
  buttonAdd: {
    border: "none",
    padding: 0,
    position: "absolute",
    right: "0px",
    // '@media(max-width: 1500px)': {
    //   display: "none"
    // }
  },
  buttonBroadcast: {
    border: "none",
    padding: 0,
    right: "5px",
    position: "absolute",
  },
  buttonAddMenu: {
    '@media(min-width: 1500px)': {
      display: 'none !important'
    }
  },
}));


function setPageFind() {
  var path = window.location.pathname
  var tab = 'open'
  if (path.includes('broadcast')) {
    tab = 'broadcast'
  }
  return tab;
}

const TicketsManager = ({ handleDrawerClose }) => {
  const classes = useStyles();
  const history = useHistory();
  const [searchParam, setSearchParam] = useState("");
  const [tab, setTab] = useState(setPageFind());
  const [newContact, setNewContact] = useState({});
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [broadcastModalOpen, setBroadcastModalOpen] = useState(false);
  const [showAllTickets, setShowAllTickets] = useState(false);
  const searchInputRef = useRef();
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const { user } = useContext(AuthContext);
  const { adminId } = user;
  const [selectedQueueIds, setSelectedQueueIds] = useState(user.queues.filter(q => q.name != 'FILA_CHATBOT').map(q => q.id) || []);
  const [anchorEl, setAnchorEl] = useState(null);
  const [tags, setTags] = useState([]);
  const [tagsFilter, setTagsFilter] = useState([]);
  const [notificationTicketIds, setNotificationTicketIds] = useState([]);
  const [play] = useSound(alertSound);
  const soundAlertRef = useRef();
  const historyRef = useRef(history);

  // resgatar as conexões do atendente de acordo com as filas em que está vinculado
  let connections = [];
  if (user?.queues) {
    for (const q of user.queues) {
      for (const w of q.Whatsapps) {
        if (w?.state != true) continue;
        const idx = connections.findIndex(conn => conn.id == w.id);
        if (idx == -1) connections.push(w);
      }
    }
  }

  let searchTimeout;
  const handleSearch = (searchedTerm) => {

    if (!searchedTerm || searchedTerm == 'undefined') return;

    searchedTerm = searchedTerm.toLowerCase();

    clearTimeout(searchTimeout);
    if (searchedTerm === "") {
      setSearchParam(searchedTerm);
      setTab("open");
    } else {
      setTab("search");
    }

    searchTimeout = setTimeout(() => {
      setSearchParam(searchedTerm);
    }, 750);
  };

  const excludeMessage = () => {
    document.getElementById("searchTickets").value = "";
    setTab("open");
  };

  const handleChangeTab = (e, newValue) => {
    setTab(newValue);
  };

  const handleCloseOrOpenTicket = (ticket) => {
    setNewTicketModalOpen(false);
    if (ticket !== undefined && ticket.uuid !== undefined) {
      history.push(`/tickets/${ticket.uuid}`);
    }
  }

  const handleCloseContactModal = (ticket) => {
    setContactModalOpen(false);
    if (ticket !== undefined && ticket.uuid !== undefined) {
      history.push(`/tickets/${ticket.uuid}`);
    }
  };

  const createNewContact = (contact) => {
    setNewContact({ name: contact });
    setContactModalOpen(true);
  }

  const handleAddNewContactTicket = contact => {
    handleSaveTicket(contact.id);
    setSearchParam("");
    excludeMessage();
  };

  const handleSaveTicket = async contactId => {
    if (!contactId) return;
    try {
      const { data: ticket } = await api.post("/tickets", {
        contactId: contactId,
        queueId: null,
        userId: user.id,
        status: "open",
      });
      handleCloseContactModal(ticket);
    } catch (err) {
      toastError(err);
    }
  };

  function primeiraLetraMaiuscula(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handleCloseBroadcastModal = (e) => {
    if (e) { setTab("broadcast"); }
    setBroadcastModalOpen(false);
  }

  const handleSelectedQueueId = (queueId) => {
    // minimizar atendimento ao trocar filtro de filas
    const btnClose = document.querySelector('#btnCloseTicketOnHeader');
    if (btnClose) btnClose.click();
    // resgatar array atual
    let queueIds = selectedQueueIds || [];
    const index = queueIds.indexOf(queueId);
    // verificar se id existe e remover, senão adicionar
    if (index == -1) setSelectedQueueIds([...queueIds, queueId]);
    else queueIds = setSelectedQueueIds(queueIds.filter((q) => q != queueId));
  }

  const getTags = async () => {
    try {
      const { data } = await api.get("/tags");
      return data.tags;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        let [tagsData] = await Promise.all([getTags()]);
        if (tagsData) {
          tagsData.unshift({ id: null, name: i18n.t("ticketsSearch.noTags"), status: 1, color: "#000000" });
          tagsData.unshift({ id: 'selectAll', name: i18n.t("ticketsSearch.selectAllTags"), status: 1, color: "#00a884" });
        }
        setTags(tagsData);
        const tagsMapped = tagsData.map(t => t.id);
        setTagsFilter(tagsMapped);
      } catch (error) { }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // utilizando o html para controlar as etiquetas marcadas e utlizar no websocket, a veriavel local mantem o estado inicial
    const elementId = 'tagsFilterHtmlController';
    document.body.dataset[elementId] = JSON.stringify(tagsFilter);

  }, [tagsFilter]);

  useEffect(() => {
    // minimizar atendimento ao trocar de aba
    const btnClose = document.querySelector('#btnCloseTicketOnHeader');
    if (btnClose) btnClose.click();
    // limpar campo de pesquisa ao trocar de aba
    if (tab != 'search' && tab != 'broadcast') {
      setSearchParam("");
      searchInputRef.current.value = "";
    }
  }, [tab]);

  useEffect(() => {
    if (notificationTicketIds.length > 0) {
      document.title = `(${notificationTicketIds.length}) Conexa Digital`
    } else {
      document.title = "Conexa Digital";
    }
  }, [notificationTicketIds]);

  useEffect(() => {
    soundAlertRef.current = play;

    if (!("Notification" in window)) {
      console.log("This browser doesn't support notifications");
    } else {
      Notification.requestPermission();
    }
  }, [play]);

  useEffect(() => {
    socket.on("connect", () => socket.emit("joinNotification"));

    socket.on(`ticket-${adminId}`, (data) => {
      // verificar se é uma transferência de atendimento da mesma empresa
      const { action, ticket, transfer } = data;
      if (!action || !ticket || transfer != true) return;
      if (ticket?.whatsapp?.adminId != adminId) return;

      handleNotifications({ message: { body: 'Você recebeu uma transferência...'}, contact: ticket.Contact, ticket });
    });

    socket.on(`appMessage-${adminId}`, (data) => {
      const ticket = data?.ticket || data.Ticket;

      if (
        data.action === "create" &&
        !data.message.read &&
        !data.message.fromMe &&
        adminId == ticket.whatsapp.adminId
      ) {
        handleNotifications(data);
      }
    });

    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        setNotificationTicketIds([]);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {document.removeEventListener("visibilitychange", handleVisibilityChange);};
  }, []);

  const findInArray = (obj) => {
		const numbers = [];
		obj.map(item => {
			item.Whatsapps.map(w => {
				if (w.numberConnection && w.numberConnection.trim() != '' && !numbers.includes(w.numberConnection.trim())) numbers.push(w.numberConnection.trim());
			})
		});
		return numbers;
	}

	const findQueueId = (obj) => {
		return obj.map(item => item.id);
	}

  const handleNotifications = async (data) => {
		const { message, contact, ticket } = data;
    
    // se o ticket não tiver fila, não notificar
    if (ticket.Queue === null) return;

    //só mostra as notificações caso o usuario não esteja na tela.
    if (document.visibilityState == "visible") return;

    // verificar se o atendente está logado e se o atendimento é da mesma empresa
    if (!user?.adminId || !ticket?.whatsapp?.adminId || user.adminId != ticket.whatsapp.adminId || !localStorage.getItem('token')) return;

    // verificar se o atendimento passa nos filtros do atendente de userId, numero da conexão e filas
    const numbers = findInArray(user.queues);
		const queues = findQueueId(user.queues);
    
    let tagsTmp = document.body.dataset['tagsFilterHtmlController'];
    tagsTmp = tagsTmp && tagsTmp != '' ? JsonParse(tagsTmp) : [];
    const filteredTickets = FilteredTickets([ticket], tagsTmp, numbers, user, queues);

    if (!filteredTickets || filteredTickets.length == 0) return;

    // atualização do titulo da aba
    setNotificationTicketIds(prevState => {
      const ticketIndex2 = prevState.findIndex(ti => ti.id === ticket.id);
      if (ticketIndex2 !== -1) {
        prevState[ticketIndex2] = ticket;
        return [...prevState];
      }
      return [ticket, ...prevState];
    });

    // atualização sonora e de push
		const options = {
			body: `${message.body} - ${format(new Date(), "HH:mm")}`,
			icon: contact.profilePicUrl,
			tag: ticket.uuid,
			renotify: true,
		};

		const notification = new Notification(
			`${i18n.t("tickets.notification.message")} ${contact.name}`,
			options
		);

		notification.onclick = e => {
			e.preventDefault();
			window.focus();
			historyRef.current.push(`/tickets/${ticket.uuid}`);
		};

		soundAlertRef.current();		
	};

  return (
    <>
      <ContactModal
        open={contactModalOpen}
        initialValues={newContact}
        onClose={handleCloseContactModal}
        onSave={handleAddNewContactTicket}
        byContactPage={false}
      ></ContactModal>
      <Paper elevation={0} variant="outlined" className={classes.ticketsWrapper}>
        <NewTicketModal
          modalOpen={newTicketModalOpen}
          onClose={(ticket) => { handleCloseOrOpenTicket(ticket); }}
          connections={connections}
          queues={user?.queues || []}
        />
        <ListBroadcastModal
          modalOpen={broadcastModalOpen}
          onClose={(e) => { handleCloseBroadcastModal(e) }}
        />
        <Paper
          elevation={0}
          square
          className={classes.tabsHeader}
          style={{
            position: "relative",
            display: "flex"
          }}
        >
          <Avatar style={{ marginBottom: '-5px' }} >
            <img id="picConnection" src={!profileNoPic || profileNoPic == null ? '/media/avatars/blank.png' : profileNoPic} style={{ width: '40px' }}></img>
          </Avatar>
          <div style={{ display: "flex", maxWidth: "calc(100% - 70px)" }}>
            <p id="definirFila" style={{ marginLeft: '15px', marginBottom: "-5px" }}></p>
          </div>
          <IconButton
            className={classes.buttonAdd}
            title="Novo Atendimento"
            style={{
              float: "right"
            }}
            onClick={() => setNewTicketModalOpen(true)}
          >
            <AddCommentIcon style={{
              width: '50px',
              height: '50px',
              padding: '10px',
              "&::-moz-transform": 'scaleX(-1)',
              "&::-o-transform": 'scaleX(-1)',
              "&::-webkit-transform": 'scaleX(-1)',
              transform: 'scaleX(-1)'
            }} />
          </IconButton>
          {/* <div 
            className={classes.buttonBroadcast}
          >
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? 'long-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem className={classes.buttonAddMenu} onClick={e => { handleClose(e); setNewTicketModalOpen(true); }}>
                <> <AddCommentIcon /> &nbsp; Novo atendimento </>
              </MenuItem>
              <MenuItem onClick={e => { handleClose(e); setTab("broadcast"); }}><BroadcastOnPersonalIcon /> &nbsp; Listas de transmissão</MenuItem>
              <MenuItem onClick={e => { handleClose(e); setBroadcastModalOpen(true); }}>
                <> <AddCircleIcon /> &nbsp; Criar lista de transmissão </>
              </MenuItem>
            </Menu>
          </div> */}
        </Paper>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Paper square elevation={0} className={classes.ticketOptionsBox}>
            <SearchIcon className={classes.searchIcon} />
            <InputBase
              id="searchTickets"
              className={classes.searchInput}
              inputRef={searchInputRef}
              placeholder={i18n.t("tickets.search.placeholder")}
              type="search"
              onKeyUp={(e) => {
                if (e.target.value == '') excludeMessage();
                const key = e.charCode || e.keyCode || 0;
                if (key === 13) {
                  e.preventDefault();
                  handleSearch(e.target.value);
                }
              }}
            />
            {tab !== "search" ? '' :
              <Button onClick={excludeMessage} class="btn btn-default" title="Apagar" type="button" style={{ padding: '5px' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                </svg>
              </Button>
            }
            <Button style={{ minWidth: "30px", width: "30px" }} onClick={() => handleSearch(searchInputRef.current.value)}>
              <SearchIcon />
            </Button>
          </Paper>
          <TicketSearch
            selectedQueueIds={ selectedQueueIds }
            userQueues={user?.queues}
            onChange={(queueId) => handleSelectedQueueId(queueId)}
            tags={tags}
            tagsFilter={tagsFilter}
            setTagsFilter={setTagsFilter}
          />

        </div>
        <Paper elevation={0} square style={{ padding: '0px', height: '65px' }} className={classes.tabsHeader}>
          <Tabs
            value={tab}
            onChange={handleChangeTab}
            variant="fullWidth"
            style={{ width: '100%' }}
            indicatorColor="primary"
            textColor="primary"
            aria-label="icon label tabs example"
            id="ticketTabOptions"
          >
            <Tab
              id="openTab"
              value={"open"}
              icon={<MoveToInboxIcon />}
              label={i18n.t("tickets.tabs.open.title")}
              classes={{ root: classes.tab }}
            />
            <Tab
              id="pendingTab"
              value={"pending"}
              icon={<AccessTime />}
              label={i18n.t("tickets.tabs.pending.title")}
              classes={{ root: classes.tab }}
            />
            <Tab
              id="closedTab"
              value={"closed"}
              icon={<CheckBoxIcon />}
              label={i18n.t("tickets.tabs.closed.title")}
              classes={{ root: classes.tab }}
            />
            {/* {user.profile === "admin" && (
              <Tab
                value={"allTickets"}
                icon={<FolderSharedIcon />}
                label={i18n.t("tickets.tabs.managedBox.title")}
                classes={{ root: classes.tab }}
              />
            )} */}
          </Tabs>
        </Paper>
        <TabPanel value={tab} name="open" className={classes.ticketsWrapper}>
          <TicketsList
            status="open"
            showAll={showAllTickets}
            selectedQueueIds={selectedQueueIds}
            usuarioId={user.id}
            tags={tags}
            tagsFilter={tagsFilter}
            setTagsFilter={setTagsFilter}
          />
        </TabPanel>
        <TabPanel value={tab} name="pending" className={classes.ticketsWrapper}>
          <TicketsList
            status="pending"
            showAll={showAllTickets}
            selectedQueueIds={selectedQueueIds}
            usuarioId={user.id}
            tags={tags}
            tagsFilter={tagsFilter}
            setTagsFilter={setTagsFilter}
          />
        </TabPanel>
        <TabPanel value={tab} name="closed" className={classes.ticketsWrapper}>
          <TicketsList
            status="closed"
            showAll={showAllTickets}
            selectedQueueIds={selectedQueueIds}
            tags={tags}
            tagsFilter={tagsFilter}
            setTagsFilter={setTagsFilter}
          />
        </TabPanel>
        {/*
        <TabPanel value={tab} name="broadcast" className={classes.ticketsWrapper}>
          <BroadcastList />
        </TabPanel>
        */}
        {searchParam.length > 2 && <TabPanel value={tab} name="search" className={classes.ticketsWrapper} style={{ width: '100%' }}>


          <>
            <div style={{
              padding: '10px',
              border: 'solid 1px #ebebeb',
            }}>
              <Button onClick={e => createNewContact(primeiraLetraMaiuscula(searchParam))} color="primary">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-rolodex" viewBox="0 0 16 16">
                  <path d="M8 9.05a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                  <path d="M1 1a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h.5a.5.5 0 0 0 .5-.5.5.5 0 0 1 1 0 .5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5.5.5 0 0 1 1 0 .5.5 0 0 0 .5.5h.5a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H6.707L6 1.293A1 1 0 0 0 5.293 1H1Zm0 1h4.293L6 2.707A1 1 0 0 0 6.707 3H15v10h-.085a1.5 1.5 0 0 0-2.4-.63C11.885 11.223 10.554 10 8 10c-2.555 0-3.886 1.224-4.514 2.37a1.5 1.5 0 0 0-2.4.63H1V2Z" />
                </svg>
                &nbsp;
                {i18n.t('buttons.add')} {searchParam}
              </Button>
            </div>
          </>


          <TicketsList
            searchParam={searchParam}
            showAll={true}
            selectedQueueIds={selectedQueueIds}
            tags={tags}
            tagsFilter={tagsFilter}
            setTagsFilter={setTagsFilter}
          />
        </TabPanel>
        }
      </Paper>
    </>
  );
};

export default TicketsManager;