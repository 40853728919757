const accentsMap = {
    'á': 'a',
    'à': 'a',
    'â': 'a',
    'ã': 'a',
    'ä': 'a',
    'é': 'e',
    'è': 'e',
    'ê': 'e',
    'ë': 'e',
    'í': 'i',
    'ì': 'i',
    'î': 'i',
    'ï': 'i',
    'ó': 'o',
    'ò': 'o',
    'ô': 'o',
    'õ': 'o',
    'ö': 'o',
    'ú': 'u',
    'ù': 'u',
    'û': 'u',
    'ü': 'u',
    'ç': 'c',
    'ñ': 'n',
};

const noSpecialCharacter = (string) => {
    if (!string || typeof string !== 'string') return '';
    string = string.replace(/[áàâãäéèêëíìîïóòôõöúùûüçñ]/g, (accentuatedLetter) => accentsMap[accentuatedLetter] || accentuatedLetter);
    return string.replace(/[^a-zA-Z0-9 ]/g, '');
}

export default noSpecialCharacter;