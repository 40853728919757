import { useEffect, useState } from 'react';
import { saveSketch, eventEmitter, initialSketches, stopAutoClear, deleteSketch } from '../../helpers/Sketches';

function useSketch() {
    const [sketches, setSketches] = useState(initialSketches);

    useEffect(() => {
        const handleSketchSaved = (newSketch) => {
            setSketches((prevSketches) => {
                const existingIndex = prevSketches.findIndex((sketch) => sketch.id === newSketch.id);
                if (existingIndex !== -1) {
                    const updatedSketches = [...prevSketches];
                    updatedSketches[existingIndex] = newSketch;
                    return updatedSketches;
                } else {
                    return [...prevSketches, newSketch];
                }
            });
        };

        const handleSketchesUpdated = (updatedSketches) => {
            setSketches(updatedSketches);
        };

        const handleSketchDeleted = (id) => {
            const tmp = (!Array.isArray(sketches) || sketches.length == 0) ? [] : sketches.filter(sketch => sketch.id != id);
            setSketches(tmp);
        };

        eventEmitter.on('sketchSaved', handleSketchSaved);
        eventEmitter.on('sketchesUpdated', handleSketchesUpdated);
        eventEmitter.on('sketchDeleted', handleSketchDeleted);

        return () => {
            eventEmitter.events.sketchSaved = eventEmitter.events.sketchSaved.filter((listener) => listener !== handleSketchSaved);
            eventEmitter.events.sketchesUpdated = eventEmitter.events.sketchesUpdated.filter((listener) => listener !== handleSketchesUpdated);
            eventEmitter.events.sketchDeleted = eventEmitter.events.sketchDeleted.filter((listener) => listener !== handleSketchDeleted);
        };
    }, []);

    return { sketches, saveSketch, stopAutoClear, deleteSketch };
}

export default useSketch;
