import React, { createContext, useContext, useEffect, useRef } from "react";

import useWhatsApps from "../../hooks/useWhatsApps";
import socket from "../../hooks/useSocket";
import { AuthContext } from "../Auth/AuthContext";

const WhatsAppsContext = createContext();

const WhatsAppsProvider = ({ children }) => {
	const { loading, whatsApps } = useWhatsApps();
	const { user, handleStatus, state } = useContext(AuthContext);
	const statusRef = useRef('offline');
	const activityTimeout = useRef(null);
	let initialized = false;

	// Tempo para defiir ausência de usuário
	const outMinutes = 10;
	const handleActivity = () => {
		if (activityTimeout.current) clearTimeout(activityTimeout.current);

		if (statusRef.current !== 'online') {
			socket.emit('change_status', { userId: user.id, state: 'online', adminId: user.adminId });
			statusRef.current = 'online';
		}

		activityTimeout.current = setTimeout(() => {
			if (statusRef.current !== 'out') {
				socket.emit('change_status', { userId: user.id, state: 'out', adminId: user.adminId });
				statusRef.current = 'out';
			}
		}, outMinutes * 60 * 1000); // Usuário fica ausente após x minutes
	};


	useEffect(() => {
		if (initialized == true) return;
		initialized = true;

		if (user?.adminId) {
			socket.connect(user.adminId);
		};

		socket.on('connect', () => {
			socket.emit('register', { userId: user.id });
			handleActivity();
		});

		socket.on('disconnect', () => {
			if (activityTimeout.current) clearTimeout(activityTimeout.current);
			statusRef.current = 'offline';
		});

		socket.on(`user_status_${user.adminId}`, (data) => {
			console.info(`Usuário: [${data.id}]:[${data.name}] está ${data.state}`);
			if (data.id === user.id) {
				handleStatus(data.state)
			}
		});

		const handleMouseMove = () => handleActivity();
		const handleKeyDown = () => handleActivity();
		const handleBeforeUnload = () => socket.emit('change_status', { userId: user.id, state: 'online', adminId: user.adminId });

		window.addEventListener('mousemove', handleMouseMove);
		window.addEventListener('keydown', handleKeyDown);
		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('mousemove', handleMouseMove);
			window.removeEventListener('keydown', handleKeyDown);
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};

	}, [user])

	return (
		<WhatsAppsContext.Provider value={{ whatsApps, loading }}>
			{children}
		</WhatsAppsContext.Provider>
	);
};

export { WhatsAppsContext, WhatsAppsProvider };
