// função para retornar o tipo de extensão com base no mimeType informado
const GetExtensionFromBase64String = (str) => {
    if (!str || str.trim() == '') return {status: false, message: 'String base64 inválida'};
    str = str.trim();

    // Verifique se a string base64 tem um prefixo válido
    const matches = str.match(/^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*$/);
    if (!matches) return {status: false, response: 'String base64 inválida'};

    const mimeToExt = {
        "text/plain": "txt",
        "image/jpeg": "jpg",
        "image/png": "png",
        "audio/mp3": "mp3",
        "audio/mpeg": "mp3",
        "audio/ogg": "oga",
        "audio/opus": "opus",
        "video/x-msvideo": "avi",
        "video/mp4": "mp4",
        "video/mpeg": "mpeg",
        "text/css": "css",
        "text/csv": "csv",
        "application/msword": "doc",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "docx",
        "application/wps-office.docx": "docx",
        "application/epub+zip": "epub",
        "application/gzip": "gz",
        "text/html": "html",
        "text/calendar": "ics",
        "application/java-archive": "jar",
        "text/javascript": "js",
        "application/json": "json",
        "application/vnd.oasis.opendocument.presentation": "odp",
        "application/vnd.oasis.opendocument.spreadsheet": "ods",
        "application/vnd.oasis.opendocument.text": "odt",
        "font/otf": "otf",
        "application/pdf": "pdf",
        "application/x-httpd-php": "php",
        "application/vnd.ms-powerpoint": ".ppt",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation": "pptx",
        "application/wps-office.pptx": "pptx",
        "application/vnd.rar": "rar",
        "application/x-sh": "sh",
        "application/x-tar": "tar",
        "font/ttf": "ttf",
        "application/vnd.visio": "vsd",
        "font/woff": "woff",
        "font/woff2": "woff2",
        "application/xhtml+xml": "xhtml",
        "application/vnd.ms-excel": "xls",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
        "application/wps-office.xlsx": "xlsx",
        "application/xml": "xml",
        "text/xml": "xml",
        "application/atom+xm": "xml",
        "application/zip": "zip",
        "application/x-7z-compressed": "7z",
        "image/svg+xml": "svg",
        "image/webp": "webp",
        "image/avif": "avif",
        "image/bmp": "bmp",
        "image/gif": "gif",
        "image/vnd.microsoft.icon": "ico",
        "image/tiff": "tiff",
        "audio/aac": "aac",
        "audio/midi": "mid",
        "audio/x-midi": "mid",
        "audio/wav": "wav",
        "audio/webm": "weba",
        "audio/3gpp": "3gp",
        "audio/3gpp2": "3g2",
        "video/ogg": "ogv",
        "video/mp2t": "ts",
        "video/webm": "webm",
        "video/3gpp": "3gp",
        "video/3gpp2": "3g2",
    };

    // Extraia o tipo MIME
    const mimeType = matches[1];

    // Mapeie o tipo MIME para uma extensão de arquivo
    const extension = mimeToExt[mimeType];
    if (!extension) {
        throw new Error(`Tipo MIME não suportado: ${mimeType}`);
    }

    return {status: true, response: extension};
}

export default GetExtensionFromBase64String;