
import React, {
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";

import ReactFlow, {
    Background,
    Controls,
    MiniMap,
    Panel,
    ReactFlowProvider,
    addEdge,
    applyEdgeChanges,
    applyNodeChanges,
    getBezierPath,
    useReactFlow,
    useStoreApi
} from "reactflow";

import LoadingButton from '@mui/lab/LoadingButton';

import { Fab } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { toast } from 'react-toastify';
import "reactflow/dist/style.css";
import ChatBotTestModal from './ChatBotTestModal';
import ElementMenu from "./ElementMenu";
import ChatbotCampaignModal from "./CampaignModal";

//FIles to save data initial 

import initialEdgesFile from './GeneralValues/initialEdges.json';
import initialNodesFile from './GeneralValues/initialNodes.json';

import LateralMenu from './LateralMenu';

import ValidateChatBotFlow from './ValidateChatBotFlow';

import ConditionalElement from "./Elements/ConditionalElement";
import EndElement from "./Elements/EndElement";
import StartElement from "./Elements/StartElement";
import generealStyles from "./GeneralValues/styles.json";
import toastError from "../../../errors/toastError";
import JsonParse from "../../../helpers/JsonParse";


const foreignObjectSize = 40;
let nodeId = 1;

const nodeColor = (node) => {
    switch (node.type) {
        case 'input':
            return '#42bf40';
        case 'output':
            return '#ff0072';
        default:
            return '#6865A5';
    }
};

function verifyType(val) {
    if (Array.isArray(val)) {
        return 'array';
    } else if (typeof val === 'string') {
        return 'string'
    } else {
        console.warn("O valor não é um array nem uma string.");
        return 'others'
    }
}

function compareObjects(oldObject, newObject) {

    let message = null;

    // Comparar nodes
    if (JSON.stringify(oldObject.nodes) !== JSON.stringify(newObject.nodes)) {
        console.info('Diferença nos nodes!');
    }

    // Comparar edges
    if (JSON.stringify(oldObject.edges) !== JSON.stringify(newObject.edges)) {
        console.info('Diferença nos edges!');
    }

    // Caso você queira uma verificação mais profunda, pode usar uma função recursiva
    // para comparar cada propriedade dos objetos internos.

    function deepCompare(obj1, obj2, epsilon = 1e-10) {
        for (const key in obj1) {
            if (key === "animated") return;

            if (obj1 === undefined || obj2 === undefined) {
                message = "valores indefinidos em sequência";
            } else if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
                deepCompare(obj1[key], obj2[key], epsilon);
            } else {
                // Comparar números de ponto flutuante com margem de erro
                if (typeof obj1[key] === 'number' && typeof obj2[key] === 'number') {
                    if (Math.abs(obj1[key] - obj2[key]) > epsilon) {
                        message = `Diferença na propriedade ${key}: ${obj1[key]} !== ${obj2[key]}`;
                    }
                } else if (obj1[key] !== obj2[key]) {
                    message = `Diferença na propriedade ${key}: ${obj1[key]} !== ${obj2[key]}`;
                }
            }
        }
    }

    // Chamar a comparação profunda para nodes e edges
    oldObject.nodes.forEach((node, index) => deepCompare(node, newObject.nodes[index]));
    oldObject.edges.forEach((edge, index) => deepCompare(edge, newObject.edges[index]));

    return message;
}

function ChatBotFlow({ onClose, onConfirm, getNodes, getEdges, getQueues, isReceptive, campaignProps, variables, setVariables }) {

    // =============== Valores iniciais =============== //

    const initialNodes = getNodes ? JsonParse(getNodes) : initialNodesFile;
    const initialEdges = getEdges ? JsonParse(getEdges) : initialEdgesFile;

    const [modalOpen, setModalOpen] = useState(false);
    const [campaign, setCampaign] = useState(null);

    useEffect(() => {
        if (campaignProps) {
            // montar objeto de campanha
            let tmp = {};
            if (campaignProps?.id) tmp.id = campaignProps.id;
            if (campaignProps?.name) tmp.name = campaignProps.name;
            if (typeof campaignProps?.holiday == "boolean") tmp.holiday = campaignProps.holiday;
            if (campaignProps?.message) tmp.message = campaignProps.message;
            if (campaignProps?.moveTo) tmp.moveTo = campaignProps.moveTo;
            if (typeof campaignProps?.sendBlackListMessage == "boolean") tmp.sendBlackListMessage = campaignProps.sendBlackListMessage;
            if (campaignProps?.blackListMessage) tmp.blackListMessage = campaignProps.blackListMessage;
            if (campaignProps?.days) tmp.weekDays = JsonParse(campaignProps.days);
            if (campaignProps?.status) tmp.status = campaignProps.status;
            if (campaignProps?.campaignStatus) tmp.campaignStatus = campaignProps.campaignStatus;

            if (campaignProps?.begin) {
                let dt = campaignProps.begin.split("T")[0];
                let hr = campaignProps.begin.split("T")[1];
                tmp.start_date = dt;
                tmp.start_hours = hr.split(":")[0];
                tmp.start_minutes = hr.split(":")[1];
            }

            if (campaignProps?.end) {
                let dt = campaignProps.end.split("T")[0];
                let hr = campaignProps.end.split("T")[1];
                tmp.end_date = dt;
                tmp.end_hours = hr.split(":")[0];
                tmp.end_minutes = hr.split(":")[1];
            }

            // pegar contatos
            if (campaignProps?.contacts && campaignProps.contacts.length > 0) tmp.contacts = campaignProps.contacts;

            // pegar etiquetas
            if (campaignProps?.tagsId) tmp.selectedTagsIds = campaignProps?.tagsId;

            // pegar arquivo
            if (campaignProps?.media64archive) tmp.media64archive = campaignProps.media64archive;
            if (campaignProps?.mediaName) tmp.mediaName = campaignProps.mediaName;

            setCampaign(tmp);
        }
    }, [])

    const handleOpenModal = () => {
        // if (campaignProps?.campaignStatus && campaignProps?.status && campaignProps.campaignStatus == 'finished' && campaignProps.status == 'finished') toastError({ response: { data: { error: "A campanha já foi finalizada, não é possível alterar suas informações." } } });
        // else setModalOpen(true);
        setModalOpen(true);
    }
    const handleCloseModal = (props) => {
        if (props) {
            const { messageVariable } = props;
            if (messageVariable != undefined) {
                // limpar variaveis de campanha e cadastrar as novas
                if (variables && variables.length > 0) setVariables(variables.filter(v => v.origin != 'campaign'));
                let tmp = [];
                messageVariable.map(mv => {
                    tmp.push({key: mv, origin: 'campaign'})
                });
                setVariables(tmp);
            }
        }
        setModalOpen(false);
    }

    const handleSaveModal = (response) => {
        // salvar informações da campanha para futura edição e salvamento na base
        setCampaign({ ...response });
    }

    // =============== Modal de edições ============== //

    const EditNodeElement = (event, id) => {
        // verificar se foi clicado no botão de excluir
        let parent = event.target;
        let limit = 5;
        let deleteButton = false;
        for (let x = 1; x <= limit; x++) {
            if (parent && parent.getAttribute('data-delete') == '1') {
                deleteButton = true;
                break;
            }
            parent = parent.parentElement;
        }
        if (deleteButton) return;

        setElementOnEdit(null);
        setConfirmModalOpen(false);
        setElementMenuOpen(false);
        setNodeIdSelected(null);

        setElementOnEdit(id);
        setConfirmModalOpen(true);
        setElementMenuOpen(false);
        setNodeIdSelected(id);

        // let button = document.getElementById('ClickZoon')
        // setTimeout(() => {
        //     button.click();
        // }, 200);

    }

    // ============== Funções de deletar ============== //

    const DeleteTargetEdgeLile = (evt, id) => {
        if (evt) evt.stopPropagation();
        deleteEdgeLine(id);

    };

    const deleteNodeCard = (id) => {
        // excluir edges com relação ao card
        setEdges(eds => eds.filter(edge => edge.target !== id && edge.source !== id));
        // excluir card
        setNodes(nds => nds.filter(node => node.id !== id));
    }

    const deleteEdgeLine = (id) => setEdges(eds => eds.filter(edge => edge.id !== id));

    // ================================================ //

    // ================================================ //


    const RenderObject = (obj) => {

        let { id, title, position, validate, message, timer, afterMessage, endFlowOption, type, subtype, validate_keywords, api, schedules } = obj

        // identificar id
        const name_id = id;
        id = id.replace(/\D/g, "");
        if (!isNaN(id) && id >= nodeId) nodeId = id++;

        let label;
        switch (type) {
            case 'start':
                label = StartElement({ id, nameId: name_id, onEdit: isReceptive == false ? handleOpenModal : EditNodeElement, props: { title, message, isReceptive } });
                break;
            case 'end':
                label = EndElement({ id, nameId: name_id, subtype, onEdit: EditNodeElement, onDelete: deleteNodeCard, props: { title, message, endOptionProps: endFlowOption } });
                break;
            default:
                label = ConditionalElement({ id, nameId: name_id, subtype, onEdit: EditNodeElement, onDelete: deleteNodeCard, props: { title, message, timer, validate, validate_keywords, afterMessage, endOptionProps: endFlowOption, api, schedules } });
                break
        }

        const newNode = {
            id: `${name_id}`,
            data: { label },
            position,
            type: type == 'start' ? 'input' : (type === 'end' ? 'output' : 'conditional'),
            style: generealStyles.body
        };

        return newNode;
    }

    function renderNodes(_nodes) {
        let array = [];
        _nodes.forEach(obj => array.push(RenderObject(obj)));
        return array;
    }

    // ================================================ //


    // ================= REACT props ================== //

    const reactFlowWrapper = useRef(null);
    const [reactFlowInstance, setReactFlowInstance] = useState(useReactFlow());
    const [modalChatbotOpen, setModalChatbotOpen] = useState(false);
    const [nodes, setNodes] = useState(renderNodes(initialNodes));
    const [edges, setEdges] = useState(initialEdges);
    const [elementOnEdit, setElementOnEdit] = useState('');
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [elementMenuOpen, setElementMenuOpen] = useState(false);
    const [chatBotFlow, setChatBotFlow] = useState({});
    const [nodeIdSelected, setNodeIdSelected] = useState('');
    const onNodesChange = useCallback((changes) => setNodes((nds) => applyNodeChanges(changes, nds)), []);
    const onEdgesChange = useCallback((changes) => setEdges((eds) => applyEdgeChanges(changes, eds)), []);
    const onConnect = useCallback((params) => setEdges((eds) => addEdge(renderNewConnectStyle(params), eds)), []);
    const [loading, setLoading] = useState(false);
    const [isEdited, setIsEdited] = useState(false);
    const [isScheduled, setIsScheduled] = useState(false);

    const store = useStoreApi();

    const { setCenter } = useReactFlow();

    const handleCloseElement = () => {
        if (isEdited) {
            if (window.confirm('Para sair sem salvar as alterações, selecione "OK".')) onClose(true);
        } else onClose(true);
    };

    const focusNode = () => {
        const { nodeInternals } = store.getState();
        const nodes = Array.from(nodeInternals).map(([, node]) => node);
        let selectedNode = nodes.filter(node => node.id === nodeIdSelected);
        if (nodeIdSelected) {
            const node = selectedNode[0];
            const x = node.position.x + node.width / 2;
            const y = node.position.y + node.height / 2;
            const zoom = 2;
            setCenter(x, y, { zoom: zoom, duration: 500 });
        }
    };
    // ================================================ //


    // ============ BOTÃO DE EXCLUIR ALVO ============= //

    function EdgeButton({ id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, style = {}, markerEnd }) {
        const [edgePath, labelX, labelY] = getBezierPath({ sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition });
        return (
            <React.Fragment>
                <path id={id} style={{ stroke: '#1565c0', strokeWidth: 3 }} className="react-flow__edge-path" d={edgePath} markerEnd={markerEnd} />
                <foreignObject width={foreignObjectSize} height={foreignObjectSize} x={labelX - foreignObjectSize / 2} y={labelY - foreignObjectSize / 2} className="edgebutton-foreignobject" requiredExtensions="http://www.w3.org/1999/xhtml">
                    <Box component='div'>
                        <button style={{
                            width: '30px',
                            height: '30px',
                            borderRadius: '25px',
                            background: '#fff',
                            borderColor: '#1565c0',
                        }}
                            className="edgebutton" onClick={(event) => DeleteTargetEdgeLile(event, id)}><CloseIcon style={{ width: '14px' }} /></button>
                    </Box>
                </foreignObject>
            </React.Fragment>
        );
    }

    // ================ FILTRO DE DADOS =============== //

    const FilterNodeData = (id) => {
        let title = '';
        let message = '';
        let endFlowOption = '';
        let afterMessage = '';
        let timer = '';
        let validate = '';
        let validate_keywords = '';
        let api = '';
        let schedules = '';

        const position_object = nodes.map(i => i.id).indexOf(id);
        if (position_object === -1) return;
        nodes[position_object].data.label.props.children.forEach((obj) => {

            if (obj.props?.className === 'headerObject') {
                if (Array.isArray(obj.props.children)) {
                    for (let cont = 0; cont < obj.props.children.length; cont++) {
                        if (typeof obj.props.children[cont] == 'string') {
                            title = obj.props.children[cont];
                            break;
                        }
                    }
                } else {
                    title = obj.props.children;
                }
            }

            if (obj?.props?.children) {
                let propLength = (obj.props.children).length;
                if (propLength >= 2 && propLength <= 5) {

                    let objetoArray = Array.isArray(obj.props.children) ? obj.props.children.filter(item => typeof item !== 'string' || item.trim() !== '') : obj.props.children;

                    const type = verifyType(objetoArray)
                    if (type === 'string') {
                        if (obj?.props?.className === 'bodyObject') { message = obj.props.children }
                        if (obj?.props?.className === 'endOption') { endFlowOption = obj.props.children }
                        if (obj?.props?.className === 'afterMessage') { afterMessage = obj.props.children }
                        if (obj?.props?.className === 'timerObject') { timer = obj.props.children }
                        if (obj?.props?.className === 'validateObject') { validate = obj.props.children }
                        if (obj?.props?.className === 'validateKeywordsObject') { validate_keywords = obj.props.children }
                        if (obj?.props?.className === 'schedulesObject') { schedules = obj.props.children }
                        if (obj?.props?.className === 'api') { api = obj.props.children }
                    } else if (type === 'array') {
                        for (let index = 0; index < objetoArray.length; index++) {
                            const objct = objetoArray[index];
                            if (objct?.props?.className === 'bodyObject') { message = objct.props.children }
                            if (objct?.props?.className === 'endOption') { endFlowOption = objct.props.children }
                            if (objct?.props?.className === 'afterMessage') { afterMessage = objct.props.children }
                            if (objct?.props?.className === 'timerObject') { timer = objct.props.children }
                            if (objct?.props?.className === 'validateObject') { validate = objct.props.children }
                            if (objct?.props?.className === 'validateKeywordsObject') { validate_keywords = objct.props.children }
                            if (objct?.props?.className === 'schedulesObject') { schedules = objct.props.children }
                            if (objct?.props?.className === 'api') { api = objct.props.children }
                        }
                    } else {
                        console.error('Ocorreu um erro ao tentar mapear o objeto')
                    }

                }
            }


        });

        const getType = () => {
            return nodes[position_object].data.label.props['data-type'] ? nodes[position_object].data.label.props['data-type'] : '';
        }

        const getSubtype = () => {
            return nodes[position_object].data.label.props['data-subtype'] ? nodes[position_object].data.label.props['data-subtype'] : null;
        }

        return { id, title, message, endFlowOption, afterMessage, position: nodes[position_object].position, style: nodes[position_object].style, type: getType(id), subtype: getSubtype(), timer, validate, validate_keywords, api, schedules }
    }

    const FilterEdgeData = (edge) => {
        return {
            "animated": false,
            "id": edge.id,
            "source": edge.source,
            "sourceHandle": edge.sourceHandle,
            "target": edge.target,
            "targetHandle": edge.targetHandle,
            "type": edge.type,
        }
    }


    const startCheckFlow = () => {

        let edgesObjects = [];
        let nodesObjects = [];

        edges.forEach(edge => edgesObjects.push(FilterEdgeData(edge)));
        nodes.forEach(node => nodesObjects.push(FilterNodeData(node.id)));

        let fluxo = ({ 'nodes': nodesObjects, 'edges': edgesObjects });

        let checking = ValidateChatBotFlow(fluxo);

        if (checking.type === 'success') {
            return true;
        } else {
            toast.error(checking.message, {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });

            return false;
        }
    }
    // ================================================ //

    // visualizar se teve alguma alteração em nodes ou edges 

    const compareOldToNewFlow = () => {
        // Verificar se passou pelo menos 1 segundo desde a última execução
        if (!isScheduled) {
            setIsScheduled(true);

            const oldObject = localStorage.getItem('ChatBot');
            if (!oldObject) {
                localStorage.setItem('ChatBot', JSON.stringify(saveData()));
                setIsEdited(true);
            } else {
                const newObject = saveData();

                const message = compareObjects(JsonParse(oldObject), newObject);

                if (message) {
                    setIsEdited(true);
                };

                localStorage.setItem('ChatBot', JSON.stringify(saveData()));
            }

            // Agendar a próxima execução após 1 segundo usando setTimeout
            setTimeout(() => {
                setIsScheduled(false);
            }, 1000);
        }
    };


    // ==== Tratar cards com propriedades 1 para 1 ==== //
    useEffect(() => {
        const groupedEdges = edges.reduce((acc, edge) => {
            const existingEdge = acc.find(item => item.source === edge.source);

            if (existingEdge) {
                existingEdge.target.push(edge.target);
            } else {
                acc.push({ source: edge.source, target: [edge.target] });
            }

            return acc;
        }, []);

        groupedEdges.forEach(edgeGroup => {
            if (edgeGroup.target.length > 1) {

                edgeGroup.target.slice(2).forEach(target => {
                    const foundEdge = edges.find(edge => edge.source === edgeGroup.source && edge.target === target);
                    const foundNode = nodes.find(node => node.id === edgeGroup.source);
                    const getNode = getNodeProps(foundNode.id);
                    if (foundEdge && (getNode.subtype == "validate")) {
                        handleDuplicateValidate(foundEdge.id);
                    };
                });

                edgeGroup.target.slice(1).forEach(target => {
                    const foundEdge = edges.find(edge => edge.source === edgeGroup.source && edge.target === target);
                    const foundNode = nodes.find(node => node.id === edgeGroup.source);
                    const getNode = getNodeProps(foundNode.id);
                    if (foundEdge && (getNode.subtype == "msg" || getNode.subtype == "timer")) {
                        handleDuplicateEdge(foundEdge.id);
                    };

                });
            }
        });

        compareOldToNewFlow();

    }, [nodes, edges]);


    const handleDuplicateValidate = (duplicateEdgeId) => {
        toast.error("Este componente só deve ter uma seleção por ligação", {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });

        // Chame a função de exclusão com o ID da aresta duplicada
        DeleteTargetEdgeLile(null, duplicateEdgeId);
    };

    const handleDuplicateEdge = (duplicateEdgeId) => {
        toast.error("Este componente só pode ser ligado a um único componente em sequência", {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });

        // Chame a função de exclusão com o ID da aresta duplicada
        DeleteTargetEdgeLile(null, duplicateEdgeId);
    };
    // ================================================ //

    // ========== Pegar propriedades do NODE ========== //

    const getNodeProps = (id) => {

        let lastTitle = '';
        let lastMessage = '';
        let endFlowOption = '';
        let afterMessage = '';
        let timer = '';
        let validate = '';
        let validate_keywords = '';
        let schedules = ''
        let api = '';

        const position = nodes.map(i => i.id).indexOf(id);
        if (position === -1) return;

        nodes[position].data.label.props.children.forEach((obj) => {

            if (obj.props?.className === 'headerObject') {
                if (Array.isArray(obj.props.children)) {
                    for (let cont = 0; cont < obj.props.children.length; cont++) {
                        if (typeof obj.props.children[cont] == 'string') {
                            lastTitle = obj.props.children[cont];
                            break;
                        }
                    }
                } else {
                    lastTitle = obj.props.children;
                }
            };

            if (obj?.props?.children) {
                let propLength = (obj.props.children).length;

                if (propLength >= 2 && propLength <= 5) {

                    let objetoArray = Array.isArray(obj.props.children) ? obj.props.children.filter(item => typeof item !== 'string' || item.trim() !== '') : obj.props.children;

                    const type = verifyType(objetoArray)
                    if (type === 'string') {
                        if (obj?.props?.className === 'bodyObject') { lastMessage = obj.props.children }
                        if (obj?.props?.className === 'endOption') { endFlowOption = obj.props.children }
                        if (obj?.props?.className === 'afterMessage') { afterMessage = obj.props.children }
                        if (obj?.props?.className === 'timerObject') { timer = obj.props.children }
                        if (obj?.props?.className === 'validateObject') { validate = obj.props.children }
                        if (obj?.props?.className === 'validateKeywordsObject') { validate_keywords = obj.props.children };
                        if (obj?.props?.className === 'schedulesObject') { schedules = obj.props.children }
                        if (obj?.props?.className === 'api') { api = obj.props.children };
                    } else if (type === 'array') {
                        for (let index = 0; index < objetoArray.length; index++) {
                            const objct = objetoArray[index];
                            if (objct?.props?.className === 'bodyObject') { lastMessage = objct.props.children }
                            if (objct?.props?.className === 'endOption') { endFlowOption = objct.props.children }
                            if (objct?.props?.className === 'afterMessage') { afterMessage = objct.props.children }
                            if (objct?.props?.className === 'timerObject') { timer = objct.props.children }
                            if (objct?.props?.className === 'validateObject') { validate = objct.props.children }
                            if (objct?.props?.className === 'validateKeywordsObject') { validate_keywords = objct.props.children }
                            if (objct?.props?.className === 'schedulesObject') { schedules = objct.props.children }
                            if (objct?.props?.className === 'api') { api = objct.props.children }
                        }
                    } else {
                        console.error('Ocorreu um erro ao tentar mapear o objeto')
                    }

                }
            }

        });

        const getType = () => {
            return nodes[position].data.label.props['data-type'] ? nodes[position].data.label.props['data-type'] : '';
        }

        const getSubtype = () => {
            return nodes[position].data.label.props['data-subtype'] ? nodes[position].data.label.props['data-subtype'] : null;
        }

        return { id, lastTitle, lastMessage, endFlowOption, afterMessage, type: getType(), subtype: getSubtype(), positionObject: nodes[position].position, timer, validate, validate_keywords, api, schedules }
    }

    // ================================================ //

    // ================= Editar Node ================== //

    const EditNodeObjectProps = (id, title, message, afterMessage, endOption, type, subtype, timer, validate, validate_keywords, api, schedules) => {

        let oldProps = getNodeProps(id);
        let endOptionProps = endOption || endOption === 0 ? endOption : oldProps.endFlowOption;

        setNodes((nds) =>
            nds.map((node) => {
                if (node.id === id) {
                    // identificar id
                    const name_id = id;
                    id = id.replace(/\D/g, "");
                    let label;

                    switch (type) {
                        case 'start':
                            label = StartElement({ id, nameId: name_id, onEdit: isReceptive == false ? handleOpenModal : EditNodeElement, props: { title, message, isReceptive } });
                            break;
                        case 'end':
                            label = EndElement({ id, nameId: name_id, subtype, onEdit: EditNodeElement, onDelete: deleteNodeCard, props: { title, message, endOptionProps } });
                            break;
                        default:
                            label = ConditionalElement({ id, nameId: name_id, subtype, onEdit: EditNodeElement, onDelete: deleteNodeCard, props: { title, message, timer, validate, afterMessage, endOptionProps, validate_keywords, api, schedules } });
                            break
                    }
                    node.data = {
                        ...node.data,
                        label
                    };

                    node.type = type == 'start' ? 'input' : (type == 'end' ? 'output' : 'conditional')
                }

                return node;
            })
        );
    }

    // ================================================ //

    // = DESCONSTRUINDO OBJETO PARA NOVOS PARAMETROS = //

    const renderNewConnectStyle = (props) => {
        let object = {
            "source": props.source,
            "sourceHandle": props.sourceHandle,
            "target": props.target,
            "animated": false,
            "targetHandle": props.targetHandle,
            "type": 'buttonedge', // trocar para "smoothstep" para linhas retas
        }
        return object;
    }

    // ================================================ //


    // Drag events 

    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);

    const onDrop = useCallback(
        (event) => {

            event.preventDefault();

            let props = event.dataTransfer.getData('application/reactflow');
            props = JsonParse(props);

            const position = reactFlowInstance.screenToFlowPosition({
                x: event.clientX,
                y: event.clientY,
            });

            createNewNode(props.type, props.subtype, position);
        },
        [reactFlowInstance],
    );

    // ============= Criar novo elemento ============== //

    const createNewNode = useCallback((type, subtype, pos = null) => {
        if (!type || typeof type !== 'string') return;

        const id = `${++nodeId}`;
        const namePrefix = type === 'start' ? 'start_' : (type === 'end' ? 'end_' : 'conditional_');
        const name_id = `${namePrefix}${id}`;
        const position = pos || { x: 10 * nodeId, y: 10 * nodeId };

        let label;
        switch (type) {
            case 'start':
                label = StartElement({ id, nameId: name_id, onEdit: isReceptive == false ? handleOpenModal : EditNodeElement, props: { isReceptive } });
                break;
            case 'end':
                label = EndElement({ id, nameId: name_id, subtype, onEdit: EditNodeElement, onDelete: deleteNodeCard });
                break;
            default:
                label = ConditionalElement({ id, nameId: name_id, subtype, onEdit: EditNodeElement, onDelete: deleteNodeCard });
                break;
        }

        const newNode = {
            id: name_id,
            data: { label },
            position,
            type: type == 'start' ? 'input' : (type === 'end' ? 'output' : 'conditional'),
            style: generealStyles.body
        };

        reactFlowInstance.addNodes(newNode);

    }, []);

    // ================================================ //

    const saveData = () => {
        let edgesObjects = [];
        edges.forEach(edge => edgesObjects.push(FilterEdgeData(edge)));

        let nodesObjects = [];
        nodes.forEach(node => nodesObjects.push(FilterNodeData(node.id)));
        return { 'nodes': nodesObjects, 'edges': edgesObjects }
    }

    const saveAllFlow = (isEnd) => {

        setLoading(true);
        try {
            const checkFlow = startCheckFlow();

            if (!checkFlow) return;

            let edgesObjects = [];
            edges.forEach(edge => edgesObjects.push(FilterEdgeData(edge)));
            let edgesFormated = (edgesObjects);

            let nodesObjects = [];
            nodes.forEach(node => nodesObjects.push(FilterNodeData(node.id)));
            let nodesFormated = (nodesObjects);

            let flowParameters = { 'nodes': nodesFormated, 'edges': edgesFormated, campaign, variables };

            onConfirm(flowParameters);

            if (isEnd) { onClose(false) };
            setIsEdited(false);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }

    }

    return (
        <Box component='div' id='Teste' style={{
            display: 'flex',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <Box component='div' id="reactFlowDiv" ref={reactFlowWrapper} style={{ height: "100%", width: "100%" }}>
                <React.Fragment>
                    <ReactFlow
                        nodes={nodes}
                        onNodesChange={onNodesChange}
                        edges={edges}
                        onEdgesChange={onEdgesChange}
                        onInit={setReactFlowInstance}
                        onConnect={onConnect}
                        edgeTypes={{ buttonedge: EdgeButton }}
                        style={{ backgroundColor: '#ffffff' }}
                        onDrop={onDrop}
                        onDragOver={onDragOver}
                        fitView
                    >
                        <Panel position="top-left">
                            <Tooltip
                                arrow
                                title={
                                    <Typography gutterBottom color="inherit">
                                        adicionar elemento
                                    </Typography>
                                }
                            >
                                <Fab
                                    color="primary"
                                    aria-label="add"
                                    style={{ width: '40px', height: '40px', background: '#1565c0' }}
                                    onClick={() => { setElementMenuOpen(!elementMenuOpen); setConfirmModalOpen(false); }}
                                >
                                    <AddIcon style={{ width: '35px', height: '35px' }} />
                                </Fab>
                            </Tooltip>
                        </Panel>
                        <Panel position="bottom-center">
                            <Box component='div' style={{ display: 'flex', width: '245px', justifyContent: 'space-around' }}>
                                <Button onClick={() => saveData()} style={{ margin: 5, display: 'none' }} variant="contained">Salvar em LocalStorage</Button>
                                <Button id='ClickZoon' onClick={e => { focusNode(); }} style={{ display: 'none' }} ></Button>
                                <LoadingButton
                                    variant="contained"
                                    loading={loading}
                                    color="error"
                                    startIcon={<CloseIcon />}
                                    onClick={e => { handleCloseElement(); }}
                                >
                                    Sair
                                </LoadingButton>
                                <LoadingButton
                                    disabled={!isEdited}
                                    variant="contained"
                                    loading={loading}
                                    color="success"
                                    startIcon={<BookmarkAddedIcon />}
                                    onClick={e => { saveAllFlow(false); }}
                                > {isEdited ? "Salvar" : "Salvo"}
                                </LoadingButton>
                            </Box>
                        </Panel>
                        <Panel position="bottom-right">
                            {/* <Button variant="contained" style={{ marginBottom: '120%' }} onClick={e => { openChatbotModal(e); }}>
                                <ModeCommentIcon /> &nbsp; Testar fluxo
                            </Button> */}
                            <ChatBotTestModal
                                open={modalChatbotOpen}
                                onClose={setModalChatbotOpen}
                                chatBotFlow={chatBotFlow}
                            />
                        </Panel>
                        <Background variant={'lines'} />
                        <Controls />
                        <MiniMap nodeColor={nodeColor} nodeStrokeWidth={3} zoomable pannable />
                    </ReactFlow>
                </React.Fragment>
            </Box>
            <LateralMenu
                onSyncCard={onConnect}
                object={saveData}
                propsObject={getNodeProps(elementOnEdit)}
                open={confirmModalOpen}
                onClose={setConfirmModalOpen}
                queues={getQueues}
                onConfirm={(title, message, afterMessage, endOption, type, subtype, timer, validate, validate_keywords, api, schedules) => EditNodeObjectProps(elementOnEdit, title, message, afterMessage, endOption, type, subtype, timer, validate, validate_keywords, api, schedules)}
                variables={variables}
            />
            <ElementMenu
                open={elementMenuOpen}
                onClose={setElementMenuOpen}
                onConfirm={() => { }}
                createNewNode={createNewNode}
            />
            <ChatbotCampaignModal
                open={modalOpen}
                onClose={handleCloseModal}
                campaignProps={campaign}
                onSave={handleSaveModal}
            />
        </Box >
    );
};


export default function ({ onClose, onConfirm, getEdges, getNodes, getQueues, isReceptive, campaignProps, variables, setVariables }) {
    return (
        <ReactFlowProvider>
            <ChatBotFlow
                onClose={onClose}
                onConfirm={onConfirm}
                getEdges={getEdges}
                getNodes={getNodes}
                getQueues={getQueues}
                isReceptive={isReceptive}
                campaignProps={campaignProps}
                variables={variables}
                setVariables={setVariables}
            />
        </ReactFlowProvider>
    );
};