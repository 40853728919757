import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@mui/material';
import classNames from 'classnames';
import { format, differenceInSeconds, differenceInMinutes, differenceInHours, differenceInDays, differenceInMonths, differenceInYears } from "date-fns";
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { AutoSizer, Column, SortDirection, Table } from 'react-virtualized';
import RenderChatRelatory from '../../../components/RenderChatRelatory';
import { i18n } from '../../../translate/i18n';

const styles = theme => ({
  table: {
    fontFamily: theme.typography.fontFamily,
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  tableRow: {
    cursor: 'pointer',
  },
  tableRowHover: {
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  tableCell: {
    flex: 1,
  },
  noClick: {
    cursor: 'initial',
  },
});

class MuiVirtualizedTable extends React.PureComponent {
  getRowClassName = ({ index }) => {
    const { classes, rowClassName, onRowClick } = this.props;

    return classNames(classes.tableRow, classes.flexContainer, rowClassName, {
      [classes.tableRowHover]: index !== -1 && onRowClick != null,
    });
  };

  cellRenderer = ({ cellData, columnIndex = null }) => {
    const { columns, classes, rowHeight, onRowClick } = this.props;
    return (
      <TableCell
        component="div"
        className={classNames(classes.tableCell, classes.flexContainer, {
          [classes.noClick]: onRowClick == null,
        })}
        variant="body"
        style={{ height: rowHeight }}
        align={(columnIndex != null && columns[columnIndex].numeric) || false ? 'right' : 'left'}
      >
        {cellData}
      </TableCell>
    );
  };

  headerRenderer = ({ label, columnIndex, dataKey, sortBy, sortDirection }) => {
    const { headerHeight, columns, classes, sort } = this.props;
    const direction = {
      [SortDirection.ASC]: 'asc',
      [SortDirection.DESC]: 'desc',
    };

    const inner =
      !columns[columnIndex].disableSort && sort != null ? (
        <TableSortLabel active={dataKey === sortBy} direction={direction[sortDirection]}>
          {label}
        </TableSortLabel>
      ) : (
        label
      );

    return (
      <TableCell
        component="div"
        className={classNames(classes.tableCell, classes.flexContainer, classes.noClick)}
        variant="head"
        style={{ height: headerHeight }}
        align={columns[columnIndex].numeric || false ? 'right' : 'left'}
      >
        {inner}
      </TableCell>
    );
  };

  render() {
    const { classes, columns, ...tableProps } = this.props;
    return (
      <AutoSizer>
        {({ height, width }) => (
          <Table
            className={classes.table}
            height={height}
            width={width}
            {...tableProps}
            rowClassName={this.getRowClassName}
          >
            {columns.map(({ cellContentRenderer = null, className, dataKey, ...other }, index) => {
              let renderer;
              if (cellContentRenderer != null) {
                renderer = cellRendererProps =>
                  this.cellRenderer({
                    cellData: cellContentRenderer(cellRendererProps),
                    columnIndex: index,
                  });
              } else {
                renderer = this.cellRenderer;
              }

              return (
                <Column
                  key={dataKey}
                  headerRenderer={headerProps =>
                    this.headerRenderer({
                      ...headerProps,
                      columnIndex: index,
                    })
                  }
                  className={classNames(classes.flexContainer, className)}
                  cellRenderer={renderer}
                  dataKey={dataKey}
                  {...other}
                />
              );
            })}
          </Table>
        )}
      </AutoSizer>
    );
  }
}

MuiVirtualizedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      cellContentRenderer: PropTypes.func,
      dataKey: PropTypes.string.isRequired,
      width: PropTypes.number.isRequired,
    }),
  ).isRequired,
  headerHeight: PropTypes.number,
  onRowClick: PropTypes.func,
  rowClassName: PropTypes.string,
  rowHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
  sort: PropTypes.func,
};

MuiVirtualizedTable.defaultProps = {
  headerHeight: 56,
  rowHeight: 56,
};

const WrappedVirtualizedTable = withStyles(styles)(MuiVirtualizedTable);

// Função para calcular o tempo de atendimento
export const calcularTempoAtendimento = (startDateString, endDateString) => {
  // Converter strings de data para objetos Date
  const startDate = new Date(startDateString);
  const endDate = endDateString ? new Date(endDateString) : new Date();

  // Calcular a diferença em segundos
  const diffInSeconds = differenceInSeconds(endDate, startDate);

  // Converter a diferença para horas, minutos e segundos
  const hours = Math.floor(diffInSeconds / 3600);
  const minutes = Math.floor((diffInSeconds % 3600) / 60);
  //const seconds = diffInSeconds % 60;

  return `${hours}H ${minutes}m`;
};

// Função para calcular o tempo de atendimento em dias, meses ou anos
export const calcularTempoEmDias = (startDateString, endDateString) => {
  // Converter strings de data para objetos Date
  const startDate = new Date(startDateString);
  const endDate = endDateString ? new Date(endDateString) : new Date();

  // Calcular a diferença em anos, meses ou dias
  const diffInYears = differenceInYears(endDate, startDate);
  const diffInMonths = differenceInMonths(endDate, startDate);
  const diffInDays = differenceInDays(endDate, startDate);

  if (diffInYears > 0) return `${diffInYears} ano(s)`;
  if (diffInMonths > 0) return `${diffInMonths} mês(es)`;
  return `${diffInDays} dia(s)`;
};


const data = [
  ['Contato Exemplo 1', 'Atentende 1', 'Suporte', '22/09/2022', 'Aberto', 'Mensagem 1'],
  ['Contato Exemplo 2', 'Atentende 1', 'Financeiro', '12/09/2022', 'Pendente', 'Mensagem 1'],
  ['Contato Exemplo 3', 'Atentende 1', 'Vendas', '06/09/2022', 'Finalizado', 'Mensagem 1'],
  ['Contato Exemplo 4', 'Atentende 1', 'Financeiro', '10/07/2022', 'Aberto', 'Mensagem 1'],
  ['Contato Exemplo 5', 'Atentende 1', 'Sem fila', '10/03/2022', 'Pendente', 'Mensagem 1'],
];

let id = 0;
function createData(contato, atendente, fila, data, status, mensagem) {
  id += 1;
  return { id, contato, atendente, fila, data, status, mensagem };
}

function random() {
  return Math.floor(Math.random() * 256);
}

function dataAleatoria() {
  var dataIni = new Date(2022, 2, 2);
  var dataAtual = new Date();
  return new Date(dataIni.getTime() + Math.random() * (dataAtual.getTime() - dataIni.getTime()));
}

function filas() {
  const filas = ['Suporte', 'Financeiro', 'Comercial', 'Ajuda'];
  const fila = Math.floor(Math.random() * filas.length);
  return filas[fila]
}

function status() {
  const status = ['Aberto', 'Fechado', 'Pendente', 'Reaberto'];
  const set = Math.floor(Math.random() * status.length);
  return status[set]
}

const relatory = [];

for (let i = 0; i < 100; i += 1) {
  relatory.push([`Contato Exemplo ${random()}`, `Atentende ${random()}`, `${filas()}`, `${dataAleatoria()}`, `${status()}`, `Mensagem ${random()}`]);
}

const rows = [];
for (let i = 0; i < relatory.length; i += 1) {
  const randomSelection = data[Math.floor(Math.random() * data.length)];
  rows.push(createData(...randomSelection));
}

function ReactVirtualizedTable({ messagesRelatory }) {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [contactMessages, setContactMessages] = useState({});

  function closeModal() {
    setConfirmModalOpen(false)
  }

  // var mount_msg = messagesRelatory;
  var mount_msg = [];
  if (messagesRelatory && messagesRelatory.length >= 1) {
    for (let i = 0; i < messagesRelatory.length; i++) {
      mount_msg.push({
        'ticketId': messagesRelatory[i]['ticketId'],
        'Contact.name': messagesRelatory[i]['Contact.isGroup'] == 1 ? i18n.t('groupPrefix') + messagesRelatory[i]['Contact.name'] : messagesRelatory[i]['Contact.name'],
        'Contact.number': messagesRelatory[i]['Contact.number'],
        'User.name': messagesRelatory[i]['User.name'] || (messagesRelatory[i]['isChatbot'] ? "Chatbot" : ""),
        'Queue.name': messagesRelatory[i]['Queue.name'],
        'createdAt': format((new Date((messagesRelatory[i]['createdAt']))), 'dd/MM/yyyy HH:mm'),
        'updatedAt': messagesRelatory[i]['status'] === 'closed' ? format((new Date((messagesRelatory[i]['updatedAt']))), 'dd/MM/yyyy HH:mm') : '',
        'status': i18n.t(`relatoryTicket.status.${messagesRelatory[i]['status']}`),
        'ConclusionMessage': messagesRelatory[i]['ConclusionMessage.shortcut'],
        'lastReason': messagesRelatory[i]['lastReason'],
        'finishedBy': messagesRelatory[i]['finishedBy'] || ((messagesRelatory[i]['isChatbot'] && messagesRelatory[i]['status'] == 'closed') ? "Chatbot" : ""),
        'Whatsapp.name': messagesRelatory[i]['whatsappName']
      });
    }
  }

  return (
    <>
      <RenderChatRelatory
        title={`${contactMessages['Contact.name']} - ${contactMessages['Contact.number']}`}
        open={confirmModalOpen}
        onClose={e => { closeModal() }}
        ticketId={contactMessages['ticketId']}
      />
      <Paper style={{ height: 400, width: '100%' }}>
        <WrappedVirtualizedTable
          rowCount={mount_msg.length}
          rowGetter={({ index }) => {
            return mount_msg[index]
          }}
          onRowClick={event => {
            setConfirmModalOpen(true);
            setContactMessages(event.rowData)
          }}
          columns={[
            {
              width: 400,
              label: 'Ticket',
              dataKey: 'ticketId',
            },
            {
              width: 400,
              label: 'Contato',
              dataKey: 'Contact.name',
            },
            {
              width: 400,
              label: 'Número',
              dataKey: 'Contact.number',
            },
            {
              width: 400,
              label: 'Conexão',
              dataKey: 'Whatsapp.name',
              cellRenderer: ({ cellData, dataKey }) => (
                <Tooltip title={ cellData != null && cellData.indexOf(' - ') ? cellData.split(' - ')[cellData.split(' - ').length - 1] : cellData } arrow>
                  <div style={{ height: '56px', borderBottom: '1px solid rgba(224, 224, 224, 1)', alignItems: 'center', padding: '16px', width: '100%', color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem' }}>
                    { cellData != null && cellData.indexOf(' - ') ? cellData.split(' - ').slice(0, -1).join(' - ') : '' }
                  </div>
                </Tooltip>
              ),
            },
            {
              width: 400,
              label: 'Usuário Atribuído',
              dataKey: 'User.name',
            },
            {
              width: 400,
              label: 'Fila',
              dataKey: 'Queue.name',
            },
            {
              width: 400,
              label: 'Data do inicio',
              dataKey: 'createdAt',
            },
            {
              width: 400,
              label: 'Data do fim ',
              dataKey: 'updatedAt',
            },
            {
              width: 400,
              label: 'Status atual',
              dataKey: 'status',
            },
            {
              width: 400,
              label: 'Finalização',
              dataKey: 'ConclusionMessage',
            },
            {
              width: 400,
              label: 'Motivo da finalização',
              dataKey: 'lastReason',
            },
            {
              width: 400,
              label: 'Finalizado por:',
              dataKey: 'finishedBy',
            },
          ]}
        />
      </Paper >
    </>


  );
}

export default ReactVirtualizedTable;
