import { Button, Checkbox, CircularProgress, Menu, MenuItem } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { FormControlLabel, Skeleton } from "@mui/material";
import 'date-fns';
import { useEffect, useState } from 'react';
import { toast } from "react-toastify";
import CampaignChart from '../../components/RelatoryGeneralCampaigns/campaignChart';
import MessagesChart from '../../components/RelatoryGeneralCampaigns/messagesChart';
import TableRelatory from '../../components/RelatoryGeneralCampaigns/table';
import toastError from "../../errors/toastError";
import GetDate from "../../helpers/GetDate";
import api from '../../services/api';
import { i18n } from "../../translate/i18n";

const isMobile = +window.innerWidth < 600;

const useStyles = makeStyles(theme => ({
	container: {
		backgroundColor: "#ffffff",
		boxShadow: "0px 0px 20px 0px rgba(76, 87, 125, 0.02)",
		marginBottom: "2rem",
		backgroundClip: "border-box",
		borderRadius: "0.625rem",
		userSelect: "none"
	},
	mainContainer: {
		marginTop: "60px",
		padding: "25px 40px",
		maxWidth: "100vw"
	},
	title: {
		fontSize: "1.3rem",
		fontWeight: "800",
		color: "#616161"
	},
	subtitle: {
		fontSize: "1.1rem",
		marginTop: "5px",
		marginBottom: "20px",
		display: "block",
		color: "#9e9e9e",
		fontWeight: "600"
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		maxWidth: 300,
	},
	filterButtons: {
		width: !isMobile ? "max-content" : "100%",
		minWidth: "120px",
		height: "35px",
		color: "rgba(0, 0, 0, .8)",
		paddingRight: "30px",
		fontStyle: "italic",
		display: "inline-block",
		marginBottom: !isMobile ? "0" : "10px",
	},
	filterButtonsIcon: {
		position: "absolute",
		right: "2px",
		color: "rgba(0,0,0,.55)"
	},
	filterContainer: {
		float: !isMobile ? "right" : "none",
		marginRight: !isMobile ? "10px" : "0",
		display: !isMobile ? "inline-flex" : "block",
		marginTop: !isMobile ? "0" : "30px",
		"& .MuiBox-root": {
			margin: "0!important",
			display: "flex"
		}
	},
	filterDateButtons: {
		border: "1px solid rgba(0, 0, 0, 0.23)",
		padding: "5px 15px",
		minHeight: "35px",
		borderRadius: "4px",
		width: isMobile ? "100%" : "auto"
	},
	filterButtonsContainer: {
		display: "inline-block!important",
		paddingRight: isMobile ? "0" : "10px!important",
		width: isMobile ? "100%" : "auto"
	},
}));

const RelatoryGeneralCampaigns = () => {
	const getCurrentDate = (daysAgo = 0) => {
		if (isNaN(daysAgo) || daysAgo < 0) daysAgo = 0;
		const currentDate = daysAgo == 0 ? new Date() : new Date(Date.now() - daysAgo * 24 * 60 * 60 * 1000);
		const day = (currentDate.getDate().toString().padStart(2, '0'));
		const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
		const year = (currentDate.getFullYear());
		return `${year}-${month}-${day}`
	}

	const classes = useStyles();
	const [selectedConnectionsIds, setSelectedConnectionsIds] = useState([]);
	const [connections, setConnections] = useState([]);
	const [queues, setQueues] = useState([]); // filas sem filtro buscadas na base
	const [selectedQueuesIds, setSelectedQueuesIds] = useState([]);
	const [filteredQueues, setFilteredQueues] = useState([]); // filas filtradas para mostrar no select
	const [connectionAnchorEl, setConnectionAnchorEl] = useState(null);
	const [queueAnchorEl, setQueueAnchorEl] = useState(null);
	const [startDate, setStartDate] = useState(getCurrentDate(7));
	const [endDate, setEndDate] = useState(getCurrentDate());
	const [searchingData, setSearchingData] = useState(false);
	const [updatedAt, setUpdatedAt] = useState(null);
	const [campaigns, setCampaigns] = useState([]);
	const [campaignsTotal, setCampaignsTotal] = useState(0);
	const [messages, setMessages] = useState([]);
	const [history, setHistory] = useState([]);

	useEffect(() => {
		(async () => {
			await getConnections();
			await getQueues();
			setStartDate(formatDate1Week());
			setEndDate(new Date().toISOString().split("T")[0])
			await searchData();
		})();
	}, []);

	const handleConnectionClick = (event) => {
		setConnectionAnchorEl(event.currentTarget);
	};

	const handleConnectionClose = () => {
		setConnectionAnchorEl(null);
	};

	const handleConnectionSelection = (connId) => {
		if (selectedConnectionsIds.includes(connId)) {
			setSelectedConnectionsIds(selectedConnectionsIds.filter(id => id !== connId));
		} else {
			setSelectedConnectionsIds([...selectedConnectionsIds, connId]);
		}
	};

	const handleQueueClick = (event) => {
		setQueueAnchorEl(event.currentTarget);
	};

	const handleQueueClose = () => {
		setQueueAnchorEl(null);
	};

	const handleQueuesSelection = (queueId) => {
		if (selectedQueuesIds.includes(queueId)) {
			setSelectedQueuesIds(selectedQueuesIds.filter(id => id !== queueId));
		} else {
			setSelectedQueuesIds([...selectedQueuesIds, queueId]);
		}
	};

	const formatDate1Week = () => {
		var last_week = new Date();
		last_week.setDate(last_week.getDate() - 8);
		return new Date(last_week).toISOString().split("T")[0]
	}

	async function getConnections() {
		try {
			const { data } = await api.get("/whatsappListAll", { params: { disabled: '1' } });
			setConnections(data);
		} catch (err) {
			toastError(err);
		}
	}

	async function getQueues() {
		try {
			const resp = await api.get(`/queueListWithConnections`);
			if (!resp.data) return toast.error('Erro ao resgatar filas, contate o administrador.');
			setQueues(resp.data);
		} catch (err) {
			toastError(err);
		}
	}

	function openDatePicker(input) {
		var inputDate = document.getElementById(input)
		if (!inputDate.disabled) {
			inputDate.showPicker()
		}
	}

	function calcMonth(startDate, endDate) {
		if (!startDate || startDate == '' || !endDate || endDate == '') return 0;

		// Converter as strings de datas para objetos Date
		const start = new Date(startDate);
		const end = new Date(endDate);

		// Calcular a diferença em milissegundos entre as duas datas
		const diff = end - start;

		// Calcular o número de meses
		const months = diff / (1000 * 60 * 60 * 24 * 31);

		if (months < 0) return 0;

		// Arredondar para o número inteiro mais próximo
		return Math.ceil(months);
	}

	async function searchData() {
		setSearchingData(true);
		const params = {
			startDate,
			endDate,
			connectionsId: selectedConnectionsIds,
			queuesId: selectedQueuesIds
		};

		try {
			const months = calcMonth(startDate, endDate);
			if (months > 1) {
				toastError({ response: { data: { error: i18n.t("relatoryGeneralCampaigns.errors.invalid_interval_date") } } });
				return;
			}

			const { data } = await api.post("/relatoryGeneralCampaigns", params);
			let now = GetDate();
			now = now.split(':');
			now.pop();
			now = now.join(':');
			setUpdatedAt(now);
			setCampaigns(data.campaigns || []);
			setMessages(data.messages || []);
			setCampaignsTotal(data.campaignsTotal || 0);
			setHistory(data.history || []);
		} catch (err) {
			toastError(err);
		}

		setSearchingData(false);
	}

	useEffect(() => {
		setFilteredQueues([]);
		setSelectedQueuesIds([]);
		let addedQueues = [];
		selectedConnectionsIds.map((whatsappId) => {
			whatsappId = String(whatsappId);
			queues.map((item) => {
				// se a conexão bater com o parametro local, adicionar ao array
				if (item.whatsappConnections) {
					let whatsappIds = item.whatsappConnections.split(';');
					if (whatsappIds.indexOf(whatsappId) >= 0 && addedQueues.includes(item.id) == false) {
						setFilteredQueues(oldFilteredQueues => [
							...oldFilteredQueues,
							{
								id: item.id,
								name: item.name
							}
						]);
						addedQueues.push(item.id);
					}
				}
			});
		});
	}, [selectedConnectionsIds]);

	useEffect(() => {
		if (!campaigns || campaigns.length == 0) return setCampaignsTotal(0);
		let total = 0;

		campaigns.map(c => {
			if (c.quantity && !isNaN(c.quantity)) total += c.quantity;
		});

		setCampaignsTotal(total);
	}, [campaigns]);

	return (
		<Box className={classes.mainContainer}>
			<Grid container spacing={3}>
				{/* Titulos */}
				<Grid item xs={12} sm={12}>
					<Typography variant='h4' className={classes.title} style={{ fontSize: "1.5rem" }}>{i18n.t("relatoryGeneralCampaigns.title")}</Typography>
					{updatedAt && <Typography variant='subtitle' className={classes.subtitle}>{i18n.t("relatoryGeneralCampaigns.updatedAt")} {updatedAt}</Typography>}
				</Grid>
				{/* Filtros */}
				<Grid item xs={12} sm={12} pt={2} className={classes.container}>
					<Typography variant='h5' className={classes.title} style={{ float: "left" }}>{i18n.t("relatoryGeneralCampaigns.filters.title")}</Typography>
					<Box className={classes.filterContainer}>
						{/* Data de inicio */}
						<Box
							className={classes.filterButtonsContainer}
							onClick={e => { openDatePicker('start_date') }}
						>
							<Typography>{i18n.t("relatoryGeneralCampaigns.filters.startDate")}</Typography>
							<input
								max={new Date().toISOString().split("T")[0]}
								onChange={e => { setStartDate(e.target.value) }}
								defaultValue={startDate}
								id="start_date"
								type="date"
								aria-describedby="emailHelp"
								className={classes.filterDateButtons}
								disabled={searchingData}
							/>
						</Box>
						{/* Data de fim */}
						<Box className={classes.filterButtonsContainer} onClick={e => { openDatePicker('end_date') }}>
							<Typography>{i18n.t("relatoryGeneralCampaigns.filters.endDate")}</Typography>
							<input
								max={new Date().toISOString().split("T")[0]}
								onChange={e => { setEndDate(e.target.value) }}
								defaultValue={endDate}
								id="end_date"
								type="date"
								aria-describedby="emailHelp"
								className={classes.filterDateButtons}
								disabled={searchingData}
							/>
						</Box>
						{/* Conexões */}
						<Box className={classes.filterButtonsContainer}>
							<Typography>{i18n.t("relatoryGeneralCampaigns.filters.connections")}</Typography>
							<Button variant="outlined" className={classes.filterButtons} onClick={handleConnectionClick} disabled={searchingData}>
								{i18n.t("relatoryGeneralCampaigns.filters.selectConnections")}
								<ArrowDropDownIcon className={classes.filterButtonsIcon} />
							</Button>
						</Box>
						<Menu
							anchorEl={connectionAnchorEl}
							open={Boolean(connectionAnchorEl)}
							onClose={handleConnectionClose}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "left"
							}}
							transformOrigin={{
								vertical: "top",
								horizontal: "left"
							}}
						>
							{connections?.length > 0 &&
								connections.map(conn => (
									<MenuItem dense key={conn.id}>
										<FormControlLabel control={<Checkbox
											size="small"
											color="primary"
											checked={selectedConnectionsIds.includes(conn.id)}
											onChange={() => handleConnectionSelection(conn.id)}
										/>} label={conn.name} />
									</MenuItem>
								))}
						</Menu>
						{/* Filas */}
						<Box className={classes.filterButtonsContainer}>
							<Typography>{i18n.t("relatoryGeneralCampaigns.filters.queues")}</Typography>
							<Button variant="outlined" className={classes.filterButtons} onClick={handleQueueClick} disabled={filteredQueues == null || filteredQueues.length == 0 || searchingData ? true : false}>
								{i18n.t("relatoryGeneralCampaigns.filters.selectQueues")}
								<ArrowDropDownIcon className={classes.filterButtonsIcon} />
							</Button>
						</Box>
						<Menu
							anchorEl={queueAnchorEl}
							open={Boolean(queueAnchorEl)}
							onClose={handleQueueClose}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "left"
							}}
							transformOrigin={{
								vertical: "top",
								horizontal: "left"
							}}
						>
							{filteredQueues?.length > 0 &&
								filteredQueues.map(queue => (
									<MenuItem dense key={queue.id}>
										<FormControlLabel control={<Checkbox
											size="small"
											color="primary"
											checked={selectedQueuesIds.includes(queue.id)}
											onChange={() => handleQueuesSelection(queue.id)}
										/>} label={queue.name} />
									</MenuItem>
								))}
						</Menu>
						{/* Aplicar filtro */}
						<Button
							aria-controls="customized-menu"
							aria-haspopup="true"
							variant="contained"
							color="primary"
							style={{ width: "100%", top: isMobile ? "0" : "19px", height: "35px" }}
							onClick={() => { searchData() }}
						>
							{searchingData ? <CircularProgress style={{ color: "#ffffff", padding: "10px" }} /> : i18n.t("relatoryGeneralCampaigns.buttons.apply")}
						</Button>
					</Box>
				</Grid>
			</Grid>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={9} pt={2} className={classes.container} style={{ maxHeight: "780px", heigh: "100%", position: "relative", overflowY: "auto" }}>
					<Typography variant='h5' className={classes.title} style={{ marginBottom: "10px" }}>{i18n.t("relatoryGeneralCampaigns.messages.title")}</Typography>
					<Typography variant='subtitle' className={classes.subtitle}>{i18n.t("relatoryGeneralCampaigns.messages.subtitle")}</Typography>
					{searchingData
						?
						<>
							<Skeleton variant="rect" style={{ width: "100%", height: "300px", marginBottom: "10px", borderRadius: "10px" }} />
						</>
						:
						(messages && messages.length > 0) &&
						<MessagesChart data={messages} />
					}
				</Grid>
				<Grid container xs={12} sm={3} pt={2} className={classes.container} style={{ maxHeight: "780px", heigh: "100%", position: "relative", overflowY: "auto", paddingLeft: "15px", backgroundColor: "transparent" }}>
					<Box className={classes.container} style={{ padding: "12px", marginBottom: "0", width: "100%" }}>


						<Typography variant='h5' className={classes.title}>{i18n.t("relatoryGeneralCampaigns.campaigns.title")}</Typography>
						<Typography variant='subtitle' className={classes.subtitle}>{i18n.t("relatoryGeneralCampaigns.campaigns.total")} {campaignsTotal}</Typography>
						{searchingData
							?
							<>
								<Skeleton variant="rect" style={{ width: "100%", height: "300px", marginBottom: "10px", borderRadius: "10px" }} />
							</>
							:
							(campaigns && campaigns.length > 0) &&
							<CampaignChart data={campaigns} />
						}
					</Box>
				</Grid>
			</Grid>

			<Grid container spacing={3}>
				<Grid item xs={12} sm={12} pt={2} className={classes.container} style={{ maxHeight: "780px", heigh: "100%", position: "relative", overflowY: "auto" }}>
					<Typography variant='h5' className={classes.title} style={{ marginBottom: "10px" }}>{i18n.t("relatoryGeneralCampaigns.history.title")}</Typography>
					{searchingData
						?
						<>
							<Skeleton variant="rect" style={{ width: "100%", height: "300px", marginBottom: "10px", borderRadius: "10px" }} />
						</>
						:
						(history && history.length > 0) && <TableRelatory data={history} />
					}
				</Grid>
			</Grid>
		</Box >
	);

};

export default RelatoryGeneralCampaigns;
