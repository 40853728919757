import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import { KTSVG } from '../../../_metronic/helpers';

const useStyles = makeStyles((theme) => ({
    instructionText: {
        margin: theme.spacing(0.5, 0, -1, 0),
        color: theme.palette.text.primary,
        '& .text-danger': {
            color: theme.palette.error.main,
            marginLeft: theme.spacing(0.5),
        },
    },
    hiddenInput: {
        display: 'none',
    },
    button: {
        // Estilo padrão do botão
        padding: theme.spacing(2),
        // Adicione outros estilos necessários aqui
    },
    errorButton: {
        padding: theme.spacing(1, 3),
        backgroundColor: '#db3a3a',
    },
    progressBar: {
        marginTop: theme.spacing(-0.5),
    },
    messageBox: {
        display: "block",
        padding: "10px 0",
        width: "100%"
    },
    buttonBox: {
        float: "left"
    },
    progressBox: {
        float: "right",
        padding: "10px"
    }
}));


const FileUploader = ({ disabledInput, disabled, csvUpload, contactsError, getErrosExcel, buttonLabel, formGroup = true }) => {
    const classes = useStyles();

    return (
        <>
            <Box className={formGroup == true ? "form-group col-md-12" : classes.messageBox}>
                <Typography variant="body1" className={classes.instructionText}>
                    Enviar arquivo .CSV, .XLSX com os contatos da campanha.
                    <span className="text-danger">*</span>
                </Typography>
            </Box>

            <Box className={formGroup == true ? "form-group col-6 col-md-4" : classes.buttonBox}>
                <input
                    className={classes.hiddenInput}
                    disabled={disabledInput}
                    accept=".csv, .xlsx"
                    id="uploadContato"
                    multiple
                    type="file"
                    onChange={csvUpload}
                />

                <label htmlFor="uploadContato">
                    <Button
                        variant="contained"
                        component="span"
                        className={classes.button}
                        color="primary"
                        endIcon={<RecentActorsIcon />}
                        disabled={disabled}
                    >
                        {buttonLabel || "ENVIAR CONTATOS"}
                    </Button>
                </label>
                {contactsError.length > 0 && (
                    <Box>
                        <Button variant="contained" className={classes.errorButton} endIcon={<ErrorIcon />} onClick={getErrosExcel}>
                            {`Corrigir erros (${contactsError.length})`}
                        </Button>
                    </Box>
                )}
            </Box>
            <Box style={{
                border: '1px solid #b3b3b3',
                borderRadius: '5px',
                width: '65%',
                height: 'min-content',
                minHeight: '50px',
                paddingBottom: '10px'
            }} className={formGroup == true ? "form-group col-6 col-md-8" : classes.progressBox}>
                <Box style={{ display: 'none', marginTop: '0.35em', justifyContent: 'space-between', minHeight: '30px' }} id="resultRelatory">
                    <Box title={'Total de contatos enviados'} style={{ display: 'flex' }}><KTSVG path='/media/icons/myicons/ContactsIcon.svg' className='svg-icon-3' /><p style={{ padding: '2px' }} id="contactsResult"></p></Box>
                    <Box title={'Tempo necessário para enviar todos os contatos da campanha.'} style={{ display: 'none' }}><KTSVG path='/media/icons/myicons/DateIcon.svg' className='svg-icon-3' /><p style={{ padding: '2px' }} id="contactsEnd"></p></Box>
                    <Box title={'Contatos validos a serem enviados'} style={{ display: 'flex' }}><KTSVG path='/media/icons/myicons/SuccessIcon.svg' className='svg-icon-3' /><p style={{ padding: '2px' }} id="successContacts"></p></Box>
                    <Box title={'Contatos com erro enviados'} style={{ display: 'flex' }}><KTSVG path='/media/icons/myicons/ErrorIcon.svg' className='svg-icon-3' /><p style={{ padding: '2px' }} id="errorContacts"></p></Box>
                </Box>
                <Box className="progress" id="progressRelatory" style={{ marginTop: '-0.5rem', display: 'none' }}>
                    <Box className="progress-bar" id="progressBar" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></Box>
                </Box>
            </Box>
        </>
    );
}

export default FileUploader;
