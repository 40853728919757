import { Skeleton, Box } from "@mui/material";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    item: {
      marginBottom: "15px"
    },
    container: {
        position: "absolute",
        width: "100%",
        height: "100%",
        zIndex: "10",
        background: "#efeae2"
    }
  }));

const SkeletonMessages = () => {
    const classes = useStyles();

    return (
        <Box className={classes.container}>
          <Skeleton className={classes.item} height={40} />
          <Skeleton className={classes.item} height={40} />
          <Skeleton className={classes.item} height={40} />
          <Skeleton className={classes.item} height={40} />
          <Skeleton className={classes.item} height={40} />
        </Box>
    );
}

export default SkeletonMessages;