import { useEffect, useReducer, useState } from "react";

import { Switch } from "@material-ui/core";
import {
  FormControl,
  MenuItem,
  Select
} from '@material-ui/core/';
import { makeStyles } from "@material-ui/core/styles";
import StorageIcon from '@material-ui/icons/Storage';
import { format, parseISO } from "date-fns";
import { toast } from "react-toastify";
import openSocket from "socket.io-client";
import { KTSVG } from '../../../_metronic/helpers';
import CompanyModal from "../../components/CompanyModal";
import CompanyDatabaseModal from "../../components/CompanyModal/DatabaseModal";
import CompanyPaymentModal from "../../components/CompanyPaymentModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import MainContainer from "../../components/MainContainer";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import socket from "../../hooks/useSocket";

const reducer = (state, action) => {
  if (action.type === "LOAD_COMPANIES") {
    const companys = action.payload;
    const newCompanys = [];

    companys.forEach((company) => {
      const companyIndex = state.findIndex((u) => u.id === company.id);
      if (companyIndex !== -1) {
        state[companyIndex] = company;
      } else {
        newCompanys.push(company);
      }
    });

    return [...state, ...newCompanys];
  }

  if (action.type === "UPDATE_COMPANIES") {
    const companys = action.payload;
    const companyIndex = state.findIndex((u) => u.id === companys.id);

    if (companyIndex !== -1) {
      state[companyIndex] = companys;
      return [...state];
    } else {
      return [companys, ...state];
    }
  }

  if (action.type === "DELETE_COMPANIES") {
    const companyId = action.payload;

    const companyIndex = state.findIndex((u) => u.id === companyId);
    if (companyIndex !== -1) {
      state.splice(companyIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};


const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  modalStyle: {
    padding: '90px',
    minWidth: '310px',
    overflowY: 'scroll',
    "@media (max-width: 740px)": {
      padding: '0px !important',
      marginTop: '65px'
    }
  }
}));
const Company = () => {
  const classes = useStyles();

  const [company, setCompany] = useState({ nomeEmpresa: "", cnpj: "", maxWhatsapps: "", maxCampaign: "", MaxUsers: "", status: true, dataDesativacao: "" });
  const [companyModalOpen, setCompanyModalOpen] = useState(false);
  const [companyPaymentModalOpen, setCompanyPaymentModalOpen] = useState(false);
  const [companyDatabaseModalOpen, setCompanyDatabaseModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [deletingCompany, setDeletingCompany] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [companys, dispatch] = useReducer(reducer, []);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchCompany = async () => {
        try {
          const { data } = await api.get("/company/", {
            params: { searchParam, pageNumber },
          });
          dispatch({ type: "LOAD_COMPANIES", payload: data });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchCompany();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  useEffect(() => {

    socket.on("company", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_COMPANIES", payload: data.company });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_COMPANIES", payload: +data.companyId });
      }
    });

    return () => {};
  }, []);

  const filteredCompanies = companys.filter(company => {
    if (selectedStatus === "") {
      return true;
    } else {
      return company.status === (selectedStatus === "true");
    }
  });
  //fazer pesquisa 
  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };
  //Abrir modal para cadastrar empresa
  const handleOpenCompanyModal = () => {
    setSelectedCompany(null);
    setCompanyModalOpen(true);
  };

  //Abrir modal para cadastrar empresa
  const handleOpenCompanyPaymentModal = () => {
    setSelectedCompany(null);
    setCompanyPaymentModalOpen(true);
  };


  //fechar modal empresa 
  const handleCloseCompanyModal = () => {
    setSelectedCompany(null);
    setCompanyModalOpen(false);
  };

  //fechar modal empresa 
  const handleClosePaymentCompanyModal = () => {
    setSelectedCompany(null);
    setCompanyPaymentModalOpen(false);
  };
  const handleCloseDatabaseCompanyModal = () => {
    setSelectedCompany(null);
    setCompanyDatabaseModalOpen(false);
  };
  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };
  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };
  const handleEditCompany = (company) => {
    setSelectedCompany(company);

    setCompanyModalOpen(true);
  };

  const handlePaymentCompany = (company) => {
    setSelectedCompany(company);

    setCompanyPaymentModalOpen(true);
  };

  const handleDatabaseCompany = (company) => {
    setSelectedCompany(company);

    setCompanyDatabaseModalOpen(true);
  };

  const handleChangeCompanyStatus = async (companyId, newStatus) => {
    try {
      let dataDesativacao = null;
      if (!newStatus) {
        // Se o novo status for false, defina a data de desativação como a data atual
        const date = new Date();
        dataDesativacao = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}`;
      }
      await api.put(`/company/${companyId}`, { status: newStatus, dataDesativacao });
      toast.success("Status alterado com sucesso");
    } catch (err) {
      toastError(err);
    }
  };
  const handleDeleteCompany = async (companyId) => {
    try {
      await api.delete(`/company/${companyId}`);
      toast.success("Empresa Excluida com sucesso");
    } catch (err) {
      toastError(err);
    }
    setDeletingCompany(null);
    setSearchParam("");
    setPageNumber(1);
  };
  return (
    <MainContainer>
      <ConfirmationModal
        title={
          deletingCompany &&
          `${'Deseja realmente deletar a empresa'} ${deletingCompany.name
          }?`
        }
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() => handleDeleteCompany(deletingCompany.id)}
      >
        {"deletar empresa"}
      </ConfirmationModal>
      <CompanyModal
        open={companyModalOpen}
        onClose={handleCloseCompanyModal}
        aria-labelledby="form-dialog-title"
        companyId={selectedCompany && selectedCompany.id}
      />
      <CompanyPaymentModal
        open={companyPaymentModalOpen}
        onClose={handleClosePaymentCompanyModal}
        aria-labelledby="form-dialog-title"
        companyId={selectedCompany && selectedCompany.id}
      />
      <CompanyDatabaseModal
        open={companyDatabaseModalOpen}
        onClose={handleCloseDatabaseCompanyModal}
        aria-labelledby="form-dialog-title"
        companyId={selectedCompany && selectedCompany.id}
      />
      <>
        <div class='row gy-5 gx-xl-8'
          className={classes.modalStyle}
          onScroll={handleScroll}>
          <div className='col-xl-15'>
            <div className='card card-xxl-stretch-5 mb-15 mb-xl-8'>
              {/* begin::Header */}
              <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bolder fs-3 mb-1'>Empresas</span>
                  {/*<span className='text-muted mt-1 fw-bold fs-7'>Over 500 members</span>*/}
                </h3>
                <div>
                  <FormControl variant="outlined"
                    style={{
                      display: 'flex-column',
                      padding: '12px 3px 4px',
                      margin: '-1px 10px -2px 0px',

                    }} >
                    <Select
                      value={selectedStatus}
                      displayEmpty
                      style={{
                        padding: '1px 0px 4px',
                        margin: '4px 10px -2px 0px',
                        height: '30px',
                        width: '190px',
                        fontSize: '15px'
                      }}
                      onChange={(e) => setSelectedStatus(e.target.value)}
                    >
                      <MenuItem value="">
                        <em>Todas as empresas</em>
                      </MenuItem>
                      <MenuItem value="false">Empresas inativas</MenuItem>
                      <MenuItem value="true">Empresas ativas</MenuItem>
                    </Select>
                  </FormControl>
                  {filteredCompanies.map((company) => (
                    <div key={company.id}>
                    </div>
                  ))}
                </div>
                <div
                  className='card-toolbar '
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                >

                  <button
                    className='btn btn-sm btn-success'
                    type="button"
                    style={{
                      marginLeft: "1.5px",
                      backgroundColor: "#00a884"
                    }}
                    onClick={handleOpenCompanyModal}
                  >

                    <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                    Adicionar Empresa
                  </button>
                </div>
              </div>
              {/* end::Header */}
              {/* begin::Body */}
              <div className='card-body py-3'>
                {/* begin::Table container */}
                <div className='table-responsive'>
                  {/* begin::Table */}
                  <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className='fw-bolder text-muted' >
                        <th className='min-w-150px'>Razão social</th>
                        <th className='min-w-120px'>Cnpj</th>
                        <th className='min-w-120px'>Email</th>
                        <th className='min-w-100px'>N° Whatsapps</th>
                        <th className='min-w-100px'>N° usuarios</th>
                        <th className='min-w-100px'>Status</th>
                        <th className='min-w-100px'>Cliente parceiro</th>
                        <th className='min-w-100px'>Data da desativação</th>
                        <th className='min-w-100px text-end'>Ações</th>
                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      <>
                        {filteredCompanies.map((company) => (
                          <>

                            <tr >
                              <td>
                                <div>
                                  <div className='d-flex justify-content-start flex-column'>
                                    <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                                      {company.name}
                                    </a>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <a className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                                  {company.cnpj}
                                </a>
                              </td>
                              <td>
                                <a href={`mailto:${company.email}?subject=Ola%20contato!`} className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                                  {company.email}
                                </a>
                              </td>
                              <td>
                                <a className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                                  {company.maxWhatsapps}
                                </a>
                              </td>
                              <td>
                                <a className='text-dark fw-bolder text-hover-primary  fs-6'>
                                  {company.maxUsers}
                                </a>
                              </td>

                              <Switch
                                checked={company.status}
                                color="primary"
                                onChange={(e) => handleChangeCompanyStatus(company.id, e.target.checked)}
                              />
                              <td>
                                <a className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                                  {(company.clienteParceiro) ? ((company.clienteParceiro), "Sim") : "Não"}
                                </a>
                              </td>
                              <td>
                                <a className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                                  {(company.dataDesativacao) ? format(parseISO(company.dataDesativacao), "dd/MM/yy HH:mm") : "Não se aplica."}
                                </a>
                              </td>

                              <td>
                                <div className='d-flex justify-content-end flex-shrink-0'>
                                  <button
                                    title='Pagamentos'
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                    onClick={() => handlePaymentCompany(company)}
                                  >
                                    <KTSVG path='/media/icons/duotune/finance/fin001.svg' className='svg-icon-3' />
                                  </button>

                                  <button
                                    title='Editar'
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                    onClick={() => handleEditCompany(company)}
                                  >
                                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                  </button>

                                  <button
                                    title='Banco de dados'
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                    onClick={() => handleDatabaseCompany(company)}
                                  >
                                    <StorageIcon className='svg-icon-3' />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </>
                        ))}
                        {loading && <TableRowSkeleton columns={5} />}
                      </>
                    </tbody>
                    {/* end::Table body */}
                  </table>
                  {/* end::Table */}
                </div>
                {/* end::Table container */}
              </div>
              {/* begin::Body */}
            </div>
          </div>
        </div>
      </>
    </MainContainer>

  )
}

export default Company;
