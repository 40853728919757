import { useContext, useEffect, useRef, useState } from "react";

import { Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Typography from '@material-ui/core/Typography';
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

import { i18n } from "../../translate/i18n";

import { FormControlLabel, Switch } from "@material-ui/core";
import toastError from "../../errors/toastError";
import api from "../../services/api";

import { AuthContext } from "../../context/Auth/AuthContext";
import { Grid, Select, MenuItem } from "@mui/material";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	colorAdorment: {
		width: 20,
		height: 20,
	},
	dialog: {
		'& .MuiDialog-paper': {
			width: '100%'
		}
	}
}));

const QueueSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Muito Curto(a)!")
		.max(50, "Muito Longo(a)!")
		.required("Obrigatório"),
	color: Yup.string().min(3, "Muito Curto(a)!").max(9, "Muito Longo(a)!").required(),
	greetingMessage: Yup.string(),
	adminId: Yup.string(),
});

const QueueModal = ({ open, onClose, queueId }) => {
	const { user: loggedInUser } = useContext(AuthContext);

	const classes = useStyles();

	const initialState = {
		adminId: loggedInUser.adminId,
		isPublic: true,
		name: "",
		color: "",
		greetingMessage: "",
		distribution: "automatic"
	};

	const [colorPickerModalOpen, setColorPickerModalOpen] = useState(false);
	const [queue, setQueue] = useState(initialState);
	const greetingRef = useRef();

	useEffect(() => {
		(async () => {
			if (!queueId) return;
			try {
				const { data } = await api.get(`/queue/${queueId}`);
				setQueue(prevState => {
					return { ...prevState, ...data };
				});
			} catch (err) {
				toastError(err);
			}
		})();

		return () => {
			setQueue({
				name: "",
				isPublic: true,
				color: "",
				greetingMessage: "",
				adminId: loggedInUser.adminId,
				distribution: "automatic"
			});
		};
	}, [queueId, open]);
	const handleClose = () => {
		onClose();
		setQueue(initialState);
	};

	const handleSaveQueue = async values => {
		try {
			if (queueId) {
				await api.put(`/queue/${queueId}`, { ...values, greetingMessage: String(values.greetingMessage).trim() });
			} else {
				await api.post("/queue", { ...values, greetingMessage: String(values.greetingMessage).trim() });
			}
			toast.success("Fila salva com sucesso!");
			handleClose();
		} catch (err) {
			toastError(err);
		}
	};

	return (
		<div className={classes.root}>
			<Dialog open={open} onClose={handleClose} scroll="paper" className={classes.dialog} >
				<DialogTitle>
					{queueId
						? `${i18n.t("queueModal.title.edit")}`
						: `${i18n.t("queueModal.title.add")}`}
				</DialogTitle>
				<Formik
					initialValues={queue}
					enableReinitialize={true}
					validationSchema={QueueSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveQueue(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting, values }) => (
						<Form style={{ padding: "10px", maxWidth: '600px' }}>
							<DialogContent dividers>
								<Field
									as={TextField}
									type="hidden"
									name="adminId"
									value={loggedInUser.adminId}
								/>
								<Grid container spacing={2}>
									<Grid item xs={9} mb={2}>
										<Typography component="label">
											{i18n.t("queueModal.form.name")}
											<Typography component="span" style={{ marginLeft: '5px' }} color="secondary">*</Typography>
										</Typography>
										<Field
											style={{
												borderColor: '#c4c4c4',
												borderRadius: '3px',
												marginTop: '10px'
											}}
											placeholder={i18n.t("queueModal.form.name")}
											autoFocus
											name="name"
											error={touched.name && Boolean(errors.name)}
											helperText={touched.name && errors.name}
											className='form-control '
											required
										/>
									</Grid>
									<Grid item xs={3} mb={2}>
										<Typography component="label">
											{i18n.t("queueModal.form.color")}
											<Typography component="span" style={{ marginLeft: '5px' }} color="secondary">*</Typography>
										</Typography>
										<Field
											style={{ height: '42px', borderColor: '#c4c4c4', borderRadius: '3px', marginTop: '10px' }}
											as='input'
											autoFocus
											name="color"
											id="color"
											type="color"
											className='form-control'
											required
										/>
									</Grid>
								</Grid>
								<Grid container spacing={2}>
									<Grid item xs={12} mb={2}>
										<Typography component="label">
											{i18n.t("queueModal.form.distribution.title")}
											<Typography component="span" style={{ marginLeft: '5px' }} color="secondary">*</Typography>
										</Typography>
										<Field
											style={{ height: '42px', marginTop: '10px' }}
											variant="outlined"
											as={Select}
											name="distribution"
											className='form-control'
											required
										>
											<MenuItem value="automatic">{i18n.t("queueModal.form.distribution.automatic")}</MenuItem>
											<MenuItem value="shared">{i18n.t("queueModal.form.distribution.shared")}</MenuItem>
										</Field>
									</Grid>
								</Grid>
								<Grid container spacing={2}>
									<Grid item xs={12} mb={2}>
										<Typography component="label">
											{i18n.t("queueModal.form.greetingMessage")}
										</Typography>
										<Field
											as="textarea"
											style={{
												borderColor: '#c4c4c4',
												borderRadius: '3px',
												marginTop: '10px'
											}}
											inputRef={greetingRef}
											rows='4'
											className='form-control '
											type="greetingMessage"
											placeholder={i18n.t("queueModal.form.greetingMessage")}
											name="greetingMessage"
											error={
												touched.greetingMessage && Boolean(errors.greetingMessage)
											}
											helperText={
												touched.greetingMessage && errors.greetingMessage
											}
										/>
									</Grid>
								</Grid>
								<Grid container spacing={2}>
									<Grid item xs={12} mb={2}>
										<FormControlLabel
											control={
												<Field
													as={Switch}
													color="primary"
													name="isPublic"
													checked={values.isPublic}
													// onChange= {queue ?  true : false}
												/>
											}
											label={i18n.t("queueModal.form.public")}
										/>
									</Grid>
								</Grid>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("queueModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
									onClick={(event) => {
										if (!values.color || values.color === '') {
											toastError({ response: { data: { error: `${i18n.t("queueModal.errors.colorNotFound")}` } } });
											event.preventDefault();
										}
									}}
								>
									{queueId
										? `${i18n.t("queueModal.buttons.okEdit")}`
										: `${i18n.t("queueModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default QueueModal;
