import { Typography, Button, Menu, MenuItem, Checkbox, FormControlLabel } from "@mui/material";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
	filterButtons: {
		width: "100%!important",
		minWidth: "120px!important",
		height: "43px!important",
		color: "rgba(0, 0, 0, .8)!important",
		paddingRight: "30px!important",
		display: "inline-block!important",
		marginBottom: "10px!important",
        textAlign: "left!important",
        border: "1px solid rgb(196, 196, 196)!important",
        borderRadius: "3px!important",
        borderBottom: "1px solid #757575!important"
    
	},
    filterButtonsIcon: {
		position: "absolute",
		right: "2px",
		color: "rgba(0,0,0,.55)"
	},
}));

const MultiSelect = ({ title, displayText, options, disabled, onSelected }) => {
    const classes = useStyles();
    const [connectionAnchorEl, setConnectionAnchorEl] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState([]);

    const handleConnectionClose = () => {
		setConnectionAnchorEl(null);
        onSelected(selectedOptions.filter(opt => opt != 'selectAll'));
	};

    const handleConnectionClick = (event) => {
		setConnectionAnchorEl(event.currentTarget);
	};

    const handleSelectedResendOptions = (ev, option) => {

		// verificar se é selecionar todos
		if (option == 'selectAll') {
			// verificar se é para selecionar ou deselecionar
			if (!selectedOptions.includes(option)) {
				// selecionar
				const optionsMapped = options.map(t => t.key);
				setSelectedOptions([...optionsMapped, 'selectAll']);
			} else {
				// deselecionar
				setSelectedOptions([]);
			}
			return;
		}

		if (selectedOptions.includes(option)) {
			setSelectedOptions(selectedOptions.filter(opt => opt !== option));
		} else {
			setSelectedOptions([...selectedOptions, option]);
		}
	}

    return (
        <>
            {
                title 
                &&
                <Typography>{ title }</Typography>
            }

            <Button 
                variant="outlined" 
                onClick={handleConnectionClick} 
                disabled={disabled}
                className={classes.filterButtons}
            >
                {displayText}
                <ArrowDropDownIcon className={classes.filterButtonsIcon} />
            </Button>

            <Menu
                anchorEl={connectionAnchorEl}
                open={Boolean(connectionAnchorEl)}
                onClose={handleConnectionClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{
                    style: {
                        maxHeight: "300px",
                        width: connectionAnchorEl?.offsetWidth || "300px",
                        marginTop: "0"
                    },
                }}
            >
                {Array.isArray(options) && options?.length > 0 &&
                    <>
                        <MenuItem dense key={new Date().getTime()}>
                            <FormControlLabel
                                control={<Checkbox
                                    size="small"
                                    color="primary"
                                    checked={selectedOptions.includes('selectAll')}
                                    onChange={(e) => handleSelectedResendOptions(e, 'selectAll')}
                                />}
                                label={i18n.t("selectAll")}
                                onClick={(e) => handleSelectedResendOptions(e, 'selectAll')}
                            />
                        </MenuItem>
                        {options.map(opt => (
                            <MenuItem dense key={opt.key}>
                                <FormControlLabel
                                    control={<Checkbox
                                        size="small"
                                        color="primary"
                                        checked={selectedOptions.includes(opt.key)}
                                        onChange={(e) => handleSelectedResendOptions(e, opt.key)}
                                    />}
                                    label={opt.value}
                                    onClick={(e) => handleSelectedResendOptions(e, opt.key)}
                                />
                            </MenuItem>
                        ))}
                    </>
                }
            </Menu>
        </>
    );
}

export default MultiSelect;