import io from "socket.io-client";

class SocketWorker {
  constructor() {
    this.socket = null;
    this.eventListeners = {};
    this.rooms = []; // Para armazenar as salas em que o cliente está
    this.pendingEvents = []; // Para armazenar os eventos pendentes

    window.addEventListener("beforeunload", () => {
      this.disconnect();
    });
  }

  configureSocket(adminId) {
    if (!adminId) return; // Se não houver adminId, não conecte o socket

    this.socket = io(process.env.REACT_APP_BACKEND_URL, {
      autoConnect: false, // Desativar conexão automática
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionAttempts: Infinity,
      transports: ['websocket'],
    });

    this.socket.on("connect", () => {
      console.log("Conectado ao servidor Socket.IO");
      // Quando conectado, entramos nas salas que foram previamente definidas
      this.rooms.forEach(room => {
        this.socket.emit('join', room);
      });

      // Lidar com os eventos pendentes após a conexão
      this.pendingEvents.forEach(({ event, callback, room }) => {
        if (room) {
          // Adicionamos um ouvinte para o evento filtrando pela sala especificada
          this.socket.on(event, (data) => {
            if (data.room === room) {
              callback(data);
            }
          });
        } else {
          this.socket.on(event, callback);
        }
      });

      // Limpar eventos pendentes após a conexão
      this.pendingEvents = [];

      // Colocar o usuário na sala company-${adminId}-room
      this.joinRoom(`company-${adminId}-room`);
    });

    this.socket.on("disconnect", () => {
      console.log("Desconectado do servidor Socket.IO");
      this._reconnectAfterDelay(adminId); // Reconnectar após desconexão
    });
  }

  on(event, callback, room = null) {
    if (this.socket) {
      if (room) {
        // Adicionamos um ouvinte para o evento filtrando pela sala especificada
        this.socket.on(event, (data) => {
          if (data.room === room) {
            callback(data);
          }
        });
      } else {
        this.socket.on(event, callback);
      }
    } else {
      // Armazenar eventos pendentes se o socket ainda não estiver conectado
      this.pendingEvents.push({ event, callback, room });
    }

    if (!this.eventListeners[event]) {
      this.eventListeners[event] = [];
    }
    this.eventListeners[event].push(callback);
  }

  joinRoom(room) {
    if (this.socket) {
      this.socket.emit('join', room);
    }
    this.rooms.push(room); // Armazenamos a sala para que possamos entrar quando nos reconectarmos
  }

  leaveRoom(room) {
    if (this.socket) {
      this.socket.emit('leave', room);
    }
    const index = this.rooms.indexOf(room);
    if (index !== -1) {
      this.rooms.splice(index, 1); // Removemos a sala da lista de salas
    }
  }

  emit(event, data) {
    if (this.socket) {
      this.socket.emit(event, data);
    }
  }

  emitToRoom(room, event, data) {
    if (this.socket) {
      this.socket.emit(event, { room, data });
    }
  }

  off(event, callback) {
    if (this.socket) {
      if (this.eventListeners[event]) {
        const listeners = this.eventListeners[event];
        const index = listeners.indexOf(callback);
        if (index !== -1) {
          this.eventListeners[event].splice(index, 1);
          this.socket.off(event, callback);
        }

        if (listeners.length === 0) {
          this.socket.off(event, callback);
          delete this.eventListeners[event];
        }
      }
    }
  }

  connect(adminId) {
    if (!this.socket && adminId) {
      this.configureSocket(adminId); // Configurar o socket apenas quando o método connect é chamado
      this.socket.connect(adminId); // Conectar explicitamente quando o método connect é chamado
    }
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
      console.log("Socket desconectado manualmente");
    }
  }

  _reconnectAfterDelay(adminId) {
    setTimeout(() => {
      if (!this.socket || !this.socket.connected) {
        console.log("Tentando reconectar após desconexão");
        if (adminId) {
          // Se houver adminId, reconectar
          this.connect(adminId);
        }
      }
    }, 1000);
  }
}

const socket = new SocketWorker();

export default socket;
