import Typography from '@material-ui/core/Typography';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import MessageIcon from '@material-ui/icons/Message';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import TimerIcon from '@material-ui/icons/Timer';
import ApiIcon from '@mui/icons-material/Api';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import FlagIcon from '@mui/icons-material/Flag';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import QuizIcon from '@mui/icons-material/Quiz';
import Tooltip from '@mui/material/Tooltip';

const RenderIcon = ({ type }) => {
    if (type === 'start') {
        return <Tooltip
            title={
                <Typography gutterBottom color="inherit">
                    Inicio do fluxo
                </Typography>
            }
        >
            <FlagIcon style={{ margin: "0 5px" }} />
        </Tooltip>;
    } else if (type === 'conditional' || type === 'cond') {
        return <Tooltip
            title={
                <Typography gutterBottom color="inherit">
                    Uma pergunta ou seleção para prosseguir
                </Typography>
            }
        >
            <QuestionMarkIcon style={{ margin: "0 5px" }} />
        </Tooltip>;
    } else if (type === 'end') {
        return <Tooltip
            title={
                <Typography gutterBottom color="inherit">
                    Finaliza o fluxo encerrando o atendimento
                </Typography>
            }
        >
            <DoneAllIcon style={{ margin: "0 5px" }} />
        </Tooltip>;
    } else if (type === 'transfer') {
        return <Tooltip
            title={
                <Typography gutterBottom color="inherit">
                    Transferir atendimento para uma fila
                </Typography>
            }
        >
            <SyncAltIcon style={{ margin: "0 5px" }} />
        </Tooltip>;

    } else if (type === 'msg') {
        return <Tooltip
            title={
                <Typography gutterBottom color="inherit">
                    Mensagem destinada ao usuário
                </Typography>
            }
        >
            <MessageIcon style={{ margin: "0 5px" }} />
        </Tooltip>;

    } else if (type === 'validate_keywords') {
        return <Tooltip
            title={
                <Typography gutterBottom color="inherit">
                    Validação de palavras chaves com diferentes saidas do objeto
                </Typography>
            }
        >
            <CallSplitIcon style={{ margin: "0 5px" }} />
        </Tooltip>;
    } else if (type === 'api') {
        return <Tooltip
            title={
                <Typography gutterBottom color="inherit">
                    API con endpoit e e estrutura de variáveis
                </Typography>
            }
        >
            <ApiIcon style={{ margin: "0 5px" }} />
        </Tooltip>;
    } else if (type === 'tmp') {
        return <Tooltip
            title={
                <Typography gutterBottom color="inherit">
                    Tempo de espera
                </Typography>
            }
        >
            <TimerIcon style={{ margin: "0 5px" }} />
        </Tooltip>;
    } else {
        return <Tooltip
            title={
                <Typography gutterBottom color="inherit">
                    Validação
                </Typography>
            }
        >
            <CheckBoxIcon style={{ margin: "0 5px" }} />
        </Tooltip>;

    }
}

export default RenderIcon;