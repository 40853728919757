import { useContext } from "react";
import { Redirect, Route as RouterRoute } from "react-router-dom";

import BackdropLoading from "../components/BackdropLoading";
import { AuthContext } from "../context/Auth/AuthContext";
import socket from "../hooks/useSocket";

const Route = ({ component: Component, isPrivate = false, ...rest }) => {

  const { isAuth, loading, user } = useContext(AuthContext);

  if (loading || (isAuth && !user.id)) {
    return (<>
      <BackdropLoading />
    </>)
  }

  if (!isAuth && isPrivate) {
    return (
      <>
        {loading && <BackdropLoading />}
        <Redirect to={{ pathname: "/login", state: { from: rest.location } }} />
      </>
    );
  }

  if (isAuth && !isPrivate) {
    return (
      <>
        {loading && <BackdropLoading />}
        <Redirect to={{ pathname: "/", state: { from: rest.location } }} />;
      </>
    );
  }

  return (
    <>
      {loading && <BackdropLoading />}
      <RouterRoute {...rest} component={Component} />
    </>
  );
};

export default Route;