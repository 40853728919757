import { useContext, useEffect, useReducer, useState } from "react";
import openSocket from "socket.io-client";

import { makeStyles } from "@material-ui/core";

import MainContainer from "../../components/MainContainer";

import { toast } from "react-toastify";
import { KTSVG } from '../../../_metronic/helpers';
import ConfirmationModal from "../../components/ConfirmationModal";
import OpeningHourModal from "../../components/OpeningHour/Modal";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

import { Container, Switch, Grid, Typography, Button, Table, TableHead, TableCell, TableBody, TableRow } from "@mui/material";
import socket from "../../hooks/useSocket";

const reducer = (state, action) => {
  if (action.type === "LOAD_OPENINGHOURS") {
    const openingHours = action.payload;
    const newTags = [];

    openingHours.forEach((openingHour) => {
      const openingIndex = state.findIndex((q) => q.id === openingHour.id);
      if (openingIndex !== -1) {
        state[openingIndex] = openingHour;
      } else {
        newTags.push(openingHour);
      }
    });

    return [...state, ...newTags];
  }

  if (action.type === "UPDATE_OPENINGHOURS") {
    const openingHour = action.payload;

    const openingIndex = state.findIndex((q) => q.id === openingHour.id);
    
    if (openingIndex !== -1) {
      state[openingIndex] = openingHour;
      return [...state];
    } else {
      return [openingHour, ...state];
    }
  }

  if (action.type === "DELETE_OPENINGHOURS") {
    const tagId = action.payload;

    const openingIndex = state.findIndex((q) => q.id === tagId);
    if (openingIndex !== -1) {
      state.splice(openingIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  modalStyle: {
    padding: '90px',
    minWidth: '395px',
    overflowY: 'scroll',
    "@media (max-width: 740px)": {
      padding: '0px !important',
      marginTop: '65px'
    }
  }
}));

const OpeningHours = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [openingHours, dispatch] = useReducer(reducer, []);
  const [selectedHours, setSelectedHours] = useState(null);
  const [openingHourModalOpen, setOpeningHourModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const { user } = useContext(AuthContext);

  const adminId = user.adminId;

  const [getCreatedBy, setSelectedUserFilter] = useState("");


  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam, getCreatedBy]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchTags = async () => {
        try {
          const { data } = await api.get("/openingHour/", {
            params: { searchParam, pageNumber, getCreatedBy },
          });
          dispatch({ type: "LOAD_OPENINGHOURS", payload: data.openingHours });
          setHasMore(!!data?.hasMore);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchTags();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber, getCreatedBy]);

  useEffect(() => {
    socket.on(`openingHours-${adminId}`, (data) => {

      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_OPENINGHOURS", payload: data.openingHour });
      }

      if (data.action === "delete") {
        dispatch({
          type: "DELETE_OPENINGHOURS",
          payload: +data.openingHourId,
        });
      }
    });

    return () => { };
  }, []);

  const handleChange = async (event) => {
    if (event.target.value === "") {
      setSelectedUserFilter("");
    } else {
      setSelectedUserFilter(event.target.value);
    }
  };

  const handleOpenOpeningHourModal = () => {
    setSelectedHours(null);
    setOpeningHourModalOpen(true);
  };

  const handleCloseOpeningHourModal = () => {
    setSelectedHours(null);
    setOpeningHourModalOpen(false);
  };

  const handleEditHours = (openingHour) => {
    setSelectedHours(openingHour);
    setOpeningHourModalOpen(true);
  };

  const handleDeleteHourModal = (openingHour) => {
    if (confirmModalOpen == false) {
      setSelectedHours(openingHour);
      setConfirmModalOpen(true);
    } else {
      setSelectedHours(null);
      setConfirmModalOpen(false);
    }
  };

  const handleDisableHours = async (openingData) => {
    try {
      openingData = { ...openingData, status: !openingData.status };
      await api.put(`/openingHour/${openingData.id}`, openingData);
      toast.success(i18n.t("openingHourModal.success"));
    } catch (err) {
      toastError(err);
    }
  };

  const handleDeleteHours = async (openingHourId) => {
    try {
      await api.delete(`/openingHour/${selectedHours.id}`);
      toast.success(i18n.t("openingHourModal.invalidMessages.deleted"));
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <MainContainer>
      <ConfirmationModal
        title={i18n.t("openingHours.confirmationModal.deleteTitle")}
        open={confirmModalOpen}
        onClose={() => handleDeleteHourModal(null)}
        onConfirm={() => handleDeleteHours()}
      >
        {i18n.t("openingHours.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <OpeningHourModal
        open={openingHourModalOpen}
        onClose={handleCloseOpeningHourModal}
        aria-labelledby="form-dialog-title"
        openingHourId={selectedHours && selectedHours.id}
      />

      <Container
        style={{
          marginTop: "100px",
          background: "#fff",
          borderRadius: "10px",
          padding: "20px"
        }}
      >
        <Grid container spacing={5}>
          <Grid item sm={12} xs={12} mb={2}>
            <Typography variant="h6">{i18n.t("openingHours.title")}</Typography>
            <Button
              className='btn btn-sm btn-success'
              style={{
                marginLeft: "1.5px",
                backgroundColor: "#00a884",
                float: "right"
              }}
              onClick={handleOpenOpeningHourModal}
            >
              <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
              {i18n.t("openingHours.buttons.add")}
            </Button>
          </Grid>
        </Grid>

        <Grid container spacing={5}>
          <Grid item sm={12} xs={12} mb={2}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{i18n.t("openingHours.table.name")}</TableCell>
                  <TableCell>{i18n.t("openingHours.table.start")}</TableCell>
                  <TableCell>{i18n.t("openingHours.table.end")}</TableCell>
                  <TableCell>{i18n.t("openingHours.table.status")}</TableCell>
                  <TableCell>{i18n.t("openingHours.table.updatedBy")}</TableCell>
                  <TableCell align="center">{i18n.t("openingHours.table.actions")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {openingHours.map((openingHour) => (
                  <TableRow>
                    <TableCell component="th">{openingHour?.name || ''}</TableCell>
                    <TableCell>{openingHour?.settings?.startTime || ''}</TableCell>
                    <TableCell>{openingHour?.settings?.endTime || ''}</TableCell>
                    <TableCell>
                      <Switch
                        checked={openingHour.status}
                        onChange={() => handleDisableHours(openingHour)}
                        color="primary"
                        name={`status${openingHour.status}`}
                        key={`status${openingHour.status}`}
                        inputProps={{ 'aria-label': 'handle status' }}
                      />
                    </TableCell>
                    <TableCell>{openingHour?.['User.name']}</TableCell>
                    <TableCell align="center">
                      <Button
                        title={i18n.t("buttons.edit")}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        onClick={() => handleEditHours(openingHour)}
                      >
                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                      </Button>
                      <Button
                        title={i18n.t("buttons.delete")}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        onClick={() => handleDeleteHourModal(openingHour)}
                      >
                        <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Container>
    </MainContainer>
  );
};

export default OpeningHours;