import { Box, Typography, makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import StopRoundedIcon from '@material-ui/icons/StopRounded';
import { Bar, BarChart, CartesianGrid, Legend, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
    chartContainer: {
        "& .recharts-responsive-container": {
            height: "50vh!important",
            maxHeight: "800px"
        }
    }
}));

const MessagesChart = ({ data }) => {
    const theme = useTheme();
    const classes = useStyles();
    const status = [{ original: "answered", label: i18n.t("relatoryGeneralCampaigns.messages.statusType.answered"), color: "#00897b" }, { original: "open", label: i18n.t("relatoryGeneralCampaigns.messages.statusType.open"), color: "#9e9e9e" }, { original: "sent", label: i18n.t("relatoryGeneralCampaigns.messages.statusType.sent"), color: "#1e88e5" }, { original: "error", label: i18n.t("relatoryGeneralCampaigns.messages.statusType.error"), color: "#e53935" }, { original: "blacklist", label: i18n.t("relatoryGeneralCampaigns.messages.statusType.blacklist"), color: "#000000" }];

    const RenderLegend = (props) => {
        const { payload } = props;
        let colors = [];
        status.map(s => {
            colors.push(s.color)
        })
        return (
            <Box style={{ display: "flex", width: "fit-content", margin: "0 auto" }}>
                {payload.map((entry, index) => (
                    <>
                        <StopRoundedIcon style={{ color: colors[index], width: "30px", height: "30px" }} />
                        <Typography key={`item-${index}`} style={{ lineHeight: "30px" }}>
                            {renderSpecificQty(entry.value)}
                        </Typography>
                    </>
                ))}
            </Box>
        );
    };

    // buscar o total de atendimentos de acordo com o nome da label traduzida
    const renderSpecificQty = (label) => {
        if (!label) return '';
        // encontrar valor original da label
        const finded = status.find((element) => element.label == label);

        let total = 0;
        data.map(row => {
            for (const [key, value] of Object.entries(row)) {
                if (key == finded.original) total += value;
            }
        });

        return `${label}: ${total}`;
    }

    const makeChartData = () => {
        let arr = [];
        data.forEach(d => {
          let obj = {};
          Object.keys(d).forEach(key => {
            if (key == 'date') {
                let datetmp = d[key].split('-');
                obj[key] = datetmp[2]+'/'+datetmp[1]+'/'+datetmp[0];
            } else {
                obj[i18n.t(`relatoryGeneralCampaigns.messages.statusType.${key}`)] = d[key];
            }
          });
          arr.push(obj);
        });
        return arr;
      }

    return (
        <Box className={classes.chartContainer}>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    data={makeChartData()}
                    barSize={12}
                    width={730}
                    height={230}
                    margin={{
                        top: 16,
                        right: 16,
                        bottom: 0,
                        left: 24,
                    }}
                    borderRadius={10}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" stroke={theme.palette.text.secondary} />
                    <YAxis
                        type="number"
                        allowDecimals={false}
                        stroke={theme.palette.text.secondary}
                    />
                    <Tooltip />
                    <Legend
                        content={<RenderLegend />}
                    />
                    {status.map(s => {
                        return (<Bar dataKey={s['label']} fill={`${s['color']}`} activeBar={<Rectangle fill="pink" stroke="blue" />} />);
                    })}
                </BarChart>
            </ResponsiveContainer>
        </Box>
    );
};

export default MessagesChart;
