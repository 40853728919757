import React from "react";
import { Box, Divider } from "@material-ui/core";
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { Handle, Position } from 'reactflow';
import generalStyles from "../GeneralValues/styles.json";
import RenderIcon from "../RenderIcon";
import ParseJSONorString from "../../../../helpers/ParseJSONorString";
import JsonParse from "../../../../helpers/JsonParse";


const ConditionalElement = ({
    id,
    nameId,
    subtype,
    onEdit,
    onDelete,
    props
}) => {
    const formatNameValidate = (validate_keywords) => {
        if (!validate_keywords) return '';
        var namesArray = [];
        ParseJSONorString(validate_keywords).forEach(function (obj) {
            namesArray.push(obj.name);
        });
        var namesString = namesArray.join(', ');
        return namesString;
    };

    // Remover elemento de ligação padrão para validação
    const renderPropsValidate = (nameId) => {
        const elements = document.querySelectorAll(`[data-nodeid="${nameId}"]`);
        if (elements.length > 0) {
            elements.forEach(element => {
                if (!element.dataset.handleid) {
                    if (element.classList.contains('source')) {
                        element.remove();
                    }
                }
            });
        } else {
            const timeoutId = setTimeout(() => renderPropsValidate(nameId), 10);
            return () => clearTimeout(timeoutId);
        }
    };

    if (subtype === "validate" || subtype === "validate_keywords" || subtype === "api") {
        renderPropsValidate(nameId);
    }

    let handlesKeywords = props?.validate_keywords ? props.validate_keywords : [];

    if (subtype === "validate_keywords") {
        let keywordsArray;
        try { keywordsArray = JsonParse(handlesKeywords) } catch (err) { keywordsArray = handlesKeywords };
        const objetoInvalido = keywordsArray.find(objeto => objeto.id === 'invalid_response');
        if (!objetoInvalido) { keywordsArray.push({ "id": "invalid_response", "name": "Resposta inválida", "color": '#d32f2f', "keywords": null }) };
        handlesKeywords = JSON.stringify(keywordsArray);
    }

    const handles = subtype === "validate_keywords" ?
        ParseJSONorString(handlesKeywords)
        :
        [
            { id: 'true', color: '#00ff0d', name: 'Saída verdadeira' },
            { id: 'false', color: '#d32f2f', name: 'Saída falsa' },
        ];

    const handleSpacing = 100 / (handles.length + 1);

    return (
        <Box
            onClick={(event) => { onEdit(event, nameId); }}
            component='div'
            className="showOptions"
            id={nameId}
            data-type="cond"
            data-subtype={subtype}
        >
            <Tooltip title={<Typography gutterBottom color="inherit">Deletar</Typography>} placement="top">
                <IconButton
                    className="configButton"
                    style={generalStyles.deleteButton}
                    onClick={() => onDelete(nameId)}
                    data-delete="1"
                >
                    <DeleteIcon />
                </IconButton>
            </Tooltip>

            <Box component='p' className="iconHeaderObject">
                <RenderIcon type={subtype} />
            </Box>
            <Box component="p" className="headerObject" style={generalStyles.title}>
                {props?.title || `Titulo ${id}`}
            </Box>

            {subtype === 'validate_keywords' &&
                <Box component='p' className="validateKeywordsNameObject">
                    {formatNameValidate(ParseJSONorString(props?.validate_keywords)) || ''}
                </Box>
            }
            {subtype === 'api' &&
                <Box component='p' className="validateKeywordsNameObject">
                    "api"
                </Box>
            }
            <Divider className="headerDivider" />

            <Box
                component='div'
                style={generalStyles.message}
            >

                {
                    subtype === 'tmp'
                        ?
                        <Box component='p' className="timerObject">
                            {props?.timer || '00'}
                        </Box>
                        :
                        subtype === 'validate'
                            ?
                            <Box component='p' className="validateObject">
                                {props?.validate || 'cpf'}
                            </Box>
                            : subtype === 'validate_keywords' ?
                                <Box component='p' style={{ display: 'none' }} className="validateKeywordsObject">
                                    {JSON.stringify(ParseJSONorString(props?.validate_keywords)) || '[]'}
                                </Box> :
                                subtype === 'api' ?
                                    <Box component='p' style={{ display: 'none' }} className="api">
                                        {JSON.stringify(ParseJSONorString(props?.api)) || '{}'}
                                    </Box>
                                    :
                                    <Box component='p' className="bodyObject" style={{ margin: '5px' }}>
                                        {props?.message || 'Mensagem'}
                                    </Box>
                }
                <Box component='p' className="schedulesObject" style={{opacity: props?.validate == 'horarios' ? 0 : 1}}>
                    {props?.schedules || null}
                </Box>
                <Box component="p" className="endOption" style={{ display: 'none' }} >
                    {props?.endOptionProps || 0}
                </Box>
                {
                    props?.afterMessage
                    &&
                    <Box
                        component='p'
                        style={generalStyles.afterMessage}
                        className="afterMessage"
                    >
                        {props.afterMessage}
                    </Box>
                }
            </Box>
            {(subtype === "validate" || subtype === "validate_keywords") &&
                <React.Fragment>
                    {handles && handles.map((handle, index) => (
                        <Tooltip key={handle.id} title={<Typography>{handle.name}</Typography>} placement="top">
                            <Handle
                                type="source"
                                id={handle.id}
                                position={Position.Bottom}
                                style={{ left: `${(index + 1) * handleSpacing}%`, background: handle?.color || '#e8ff04', borderColor: handle?.color || '#e8ff04' }}
                                onConnect={(params) => console.log('handle onConnect', params)}
                                isConnectable={true}
                            />
                        </Tooltip>
                    ))}
                </React.Fragment>}
            {subtype === "api" && (
                <React.Fragment>
                    <Tooltip key="true_api" title={<Typography>{"Saida de resposta de API"}</Typography>} placement="top">
                        <Handle
                            type="source"
                            id="true_api"
                            position={Position.Bottom}
                            style={{ left: `${(0 + 1) * handleSpacing}%`, background: '#2e7d32', borderColor: '#2e7d32' }}
                            onConnect={(params) => console.log('handle onConnect', params)}
                            isConnectable={true}
                        />
                    </Tooltip>
                    <Tooltip key="false_api" title={<Typography>{"Saida de erro de API"}</Typography>} placement="top">
                        <Handle
                            type="source"
                            id="false_api"
                            position={Position.Bottom}
                            style={{ left: `${(1 + 1) * handleSpacing}%`, background: '#ff0000', borderColor: '#ff0000' }}
                            onConnect={(params) => console.log('handle onConnect', params)}
                            isConnectable={true}
                        />
                    </Tooltip>

                </React.Fragment>
            )}
        </Box>
    );
}

export default ConditionalElement;