import { Box, Chip, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    container: {
        background: "transparent",
        margin: "0",
        padding: "0"
    },
    chip: {
        backgroundColor: "#1565c0!important",
        color: "#ffffff!important",
        cursor: "pointer!important",
        margin: "5px 5px 5px auto!important"
    }
});

const VariableChips = ({
    variables,
    onClick
}) => {
    const classes = useStyles();

    const renderChips = () => {
        if (!variables) return;
        const chips = [];
        variables.map(v => chips.push(
            <Tooltip
                arrow
                title={
                    <Typography gutterBottom color="inherit">
                        variavel: {v.key}
                    </Typography>
                }
                onClick={() => onClick('{{' + v.key + '}}')}
            >
                <Chip className={classes.chip} label={v.key} />
            </Tooltip>
        ));
        return chips;
    }
    

    return (
        <Box
            className={classes.container}
            style={{
                display: !variables || variables?.length == 0 ? "none" : ""
            }}
        >
            {renderChips()}
        </Box>
    );
}

export default VariableChips;