import React, { useContext, useEffect, useState } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";

import CircularProgress from "@material-ui/core/CircularProgress";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete, {
	createFilterOptions,
} from "@material-ui/lab/Autocomplete";

import { Grid, ListItemText, MenuItem, Select } from "@material-ui/core";
import { toast } from "react-toastify";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import ButtonWithSpinner from "../ButtonWithSpinner";
import ContactModal from "../ContactModal";

const filter = createFilterOptions({
	trim: true,
});

const NewTicketModal = ({ modalOpen, onClose, initialContact = {}, connections = [], queues = [] }) => {
	const [options, setOptions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [searchParam, setSearchParam] = useState("");
	const [selectedContact, setSelectedContact] = useState(null);
	const [selectedConnection, setSelectedConnection] = useState(null);
	const [selectedQueue, setSelectedQueue] = useState(null);
	const [newContact, setNewContact] = useState({});
	const [contactModalOpen, setContactModalOpen] = useState(false);
	const [filteredQueues, setFilteredQueues] = useState([]);
	const { user } = useContext(AuthContext);
	useEffect(() => {
		if (initialContact?.id !== undefined) {
			setOptions([initialContact]);
			setSelectedContact(initialContact);
		}
	}, [initialContact]);

	useEffect(() => {
		if (modalOpen == true) {
			if (Array.isArray(connections) && connections.length == 1) setSelectedConnection(connections[0].id);
		}
	}, [modalOpen])

	useEffect(() => {
		if (!modalOpen || searchParam.length < 3) {
			setLoading(false);
			return;
		}
		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchContacts = async () => {
				try {
					const { data } = await api.get("contacts", {
						params: { 
							searchParam, 
							searchConnection: connections.find(conn => conn.id == selectedConnection)?.numberConnection 
						},
					});
					setOptions(data.contacts);
					setLoading(false);
				} catch (err) {
					setLoading(false);
					toastError(err);
				}
			};

			fetchContacts();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [searchParam]);

	useEffect(() => {
		if (!selectedConnection || (!Array.isArray(queues) || queues.length == 0)) {
			setFilteredQueues([]);
			return;
		}

		// filtrar filas de acordo com a conexão
		let qs = [];
		for (const q of queues) {
			const idx = q.Whatsapps.findIndex(wpp => wpp.id == selectedConnection);
			if (idx !== -1 && String(q.name).toLowerCase() != 'fila_chatbot') qs.push(q)
		}

		setFilteredQueues(qs);
		if (qs.length == 1) setSelectedQueue(qs[0].id);
	}, [selectedConnection]);

	const handleClose = (ticket = undefined) => {
		onClose(ticket);
		setSearchParam("");
		setOptions([]);
		setSelectedContact(null);
		setSelectedConnection(null);
		setSelectedQueue(null);
		setNewContact({});
		setFilteredQueues([]);
	};

	const handleSaveTicket = async contactId => {
		if (!contactId) return;
		var queueId = selectedQueue !== "" ? selectedQueue : null;
		queueId = user.queues.length === 1 ? user.queues[0].id : queueId;
		if (queueId === "") {
			toast.error("Selecione uma fila");
			return;
		}
		setLoading(true);
		try {
			const { data: ticket } = await api.post("/tickets", {
				contactId: contactId,
				queueId,
				userId: user.id,
				status: "open",
			});
			handleClose(ticket);
		} catch (err) {
			toastError(err);
		}
		setLoading(false);
	};

	const handleSelectOption = (e, newValue) => {
		if (newValue?.number) {
			setSelectedContact(newValue);
		} else if (newValue?.name) {
			let props = { number: '', name: newValue.name };
			if (selectedConnection) {
				props.connection = connections.find(c => c.id == selectedConnection).numberConnection;
				props.connectionId = selectedConnection;
			}
			if (selectedQueue) props.queueId = selectedQueue;
			setNewContact(props);
			setContactModalOpen(true);
		}
	};

	const handleCloseContactModal = () => {
		setContactModalOpen(false);
	};

	const handleAddNewContactTicket = contact => {
		handleSaveTicket(contact.id);
	};

	const createAddContactOption = (filterOptions, params) => {
		const filtered = filter(filterOptions, params);

		if (params.inputValue !== "" && !loading && searchParam.length >= 3) {
			filtered.push({
				name: `${params.inputValue}`,
			});
		}

		return filtered;
	};

	const renderOption = option => {
		if (option.number) {
			let str = `${option.isGroup ? i18n.t('groupPrefix') : ''}${option.name} - ${option.number}`;
			if (option.whatsappName) str += ` (${option.whatsappName})`;
			return str;
		} else {
			return `${i18n.t("newTicketModal.add")} ${option.isGroup ? i18n.t('groupPrefix') : ''}${option.name}`;
		}
	};

	const renderOptionLabel = option => {
		if (option.number) {
			return `${option.isGroup ? i18n.t('groupPrefix') : ''}${option.name} - ${option.number}`;
		} else {
			return `${option.isGroup ? i18n.t('groupPrefix') : ''}${option.name}`;
		}
	};

	return (
		<>
			<ContactModal
				open={contactModalOpen}
				initialValues={newContact}
				onClose={handleCloseContactModal}
				onSave={handleAddNewContactTicket}
				byContactPage={false}
			></ContactModal>
			<Dialog
				open={modalOpen}
				onClose={handleClose}
				maxWidth="sm"
				fullWidth={true}
			>
				<DialogTitle id="form-dialog-title">
					{i18n.t("newTicketModal.title")}
				</DialogTitle>
				<DialogContent dividers>
					<Grid container spacing={2}>
						<Grid xs={6} item>
							<Select
								placeholder="Selecione uma Conexão"
								fullWidth
								displayEmpty
								variant="outlined"
								value={selectedConnection}
								onChange={(e) => setSelectedConnection(e.target.value)}
								style={{ height: "43px" }}
								MenuProps={{
									anchorOrigin: {
										vertical: "bottom",
										horizontal: "left",
									},
									transformOrigin: {
										vertical: "top",
										horizontal: "left",
									},
									getContentAnchorEl: null,
								}}
								renderValue={() => {
									if (selectedConnection == null) return "Selecione uma Conexão";
									const connection = connections.find(c => c.id === selectedConnection);
									return connection.name;
								}}
							>
								{connections?.length > 0 &&
									connections.map((connection, key) => (
										<MenuItem dense key={key} value={connection.id} disabled={String(connection.status).toLowerCase() != 'connected'}>
											<ListItemText primary={String(connection.status).toLowerCase() != 'connected' ? connection.name + ' - Sem conexão' : connection.name} />
										</MenuItem>
									))
								}
							</Select>
						</Grid>
						<Grid xs={6} item>
							<Select
								placeholder="Selecione uma Fila"
								fullWidth
								displayEmpty
								variant="outlined"
								value={selectedQueue}
								onChange={(e) => setSelectedQueue(e.target.value)}
								disabled={selectedConnection == null}
								style={{ height: "43px" }}
								MenuProps={{
									anchorOrigin: {
										vertical: "bottom",
										horizontal: "left",
									},
									transformOrigin: {
										vertical: "top",
										horizontal: "left",
									},
									getContentAnchorEl: null,
								}}
								renderValue={() => {
									if (selectedQueue == null) return "Selecione uma Fila";
									const queue = queues.find(c => c.id === selectedQueue);
									return queue.name;
								}}
							>
								{filteredQueues?.length > 0 &&
									filteredQueues.map((queue, key) => (
										<MenuItem dense key={key} value={queue.id}>
											<ListItemText primary={queue.name} />
										</MenuItem>
									))}
							</Select>
						</Grid>
					</Grid>
					<Grid container spacing={2} hidden={initialContact?.id !== undefined}>
						<Grid xs={12} item>
							<Autocomplete
								fullWidth
								options={options}
								loading={loading}
								clearOnBlur
								disabled={!selectedQueue}
								autoHighlight
								placeholer={i18n.t("newTicketModal.fieldLabel")}
								freeSolo
								clearOnEscape
								getOptionLabel={renderOptionLabel}
								renderOption={renderOption}
								filterOptions={createAddContactOption}
								onChange={(e, newValue) => handleSelectOption(e, newValue)}
								renderInput={params => (
									<>
										<TextField
											{...params}
											paddingTop='10px'
											autoFocus
											placeholder="Digite para pesquisar o contato"
											fullWidth
											margin="normal"
											variant="outlined"
											onChange={e => setSearchParam(e.target.value)}
											InputLabelProps={{
												shrink: true,
											}}
											InputProps={{
												...params.InputProps,
												style: {
													padding: '1px 9px 5px 10px',
													fontSize: '15px',
												},
												endAdornment: (
													<React.Fragment>
														{loading ? (
															<CircularProgress color="inherit" size={20} />
														) : null}
														{params.InputProps.endAdornment}
													</React.Fragment>
												),
											}}
										/>
									</>
								)}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						color="secondary"
						disabled={loading}
						variant="outlined"
					>
						{i18n.t("newTicketModal.buttons.cancel")}
					</Button>
					<ButtonWithSpinner
						variant="contained"
						type="button"
						disabled={!selectedContact}
						onClick={() => handleSaveTicket(selectedContact.id)}
						color="primary"
						loading={loading}
					>
						{i18n.t("newTicketModal.buttons.ok")}
					</ButtonWithSpinner>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default NewTicketModal;
