import React,{ Component } from 'react';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);


const chartContent = ({ pending, open, closed, reopen }) => {
    const data = {
        labels: ['Caixa de entrada', 'Aguardando retorno', 'Finalizados'],
        datasets: [
            {
                label: '# of Votes',
                data: [open, pending, closed, reopen],
                backgroundColor: [
                    '#0262DC', //azul ativo
                    '#EF0202',//vermelho pendentes
                    '#7E7E7E', //cinza fechados
                ],
                borderColor: [
                    '#0262DC', //azul ativo
                    '#EF0202',//vermelho pendentes
                    '#7E7E7E', //cinza fechados
                ],
                borderWidth: 1,
            },
        ],
    };

    return (
        <Pie data={data} />
    );
}

export default chartContent;