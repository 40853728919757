import clsx from "clsx";
import React, { useContext, useEffect, useState } from "react";

import {
  AppBar,
  Badge,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  makeStyles,
} from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from "@material-ui/core/Typography";
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";
import CodeRoundedIcon from "@material-ui/icons/CodeRounded";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import LabelIcon from '@material-ui/icons/Label';
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import QuestionAnswerOutlinedIcon from "@material-ui/icons/QuestionAnswerOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import ShareIcon from '@material-ui/icons/Share';
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import PaidIcon from '@mui/icons-material/Paid';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Link, Link as RouterLink } from 'react-router-dom';
import { KTSVG } from '../../_metronic/helpers';
import Logo from "../assets/logo_dark.png";
import BackdropLoading from "../components/BackdropLoading";
import { Can } from "../components/Can";
import UserModal from "../components/UserModal";
import { AuthContext } from "../context/Auth/AuthContext";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import CheckSupportEmail from "../helpers/CheckSupportEmail";
import { i18n } from "../translate/i18n";
import socket from "../hooks/useSocket";
import api from "../services/api";

function ListItemLink(props) {
  const { icon, primary, to, className } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    <li>
      <ListItem button component={renderLink} className={className}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
    overflow: 'hidden',
    flexGrow: 1,
    //Bugando a interface para mobile
    //[theme.breakpoints.down("sm")]: {
    //  height: "calc(100vh - 56px)",
    //},
  },

  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    justifyContent: "space-between",
    backgroundColor: '#fff',
    color: '#757575',
    padding: '10px'
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    minHeight: "55px",
  },
  appBar: {
    boxShadow: 'unset',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    boxShadow: 'unset',
    marginLeft: drawerWidth,
    width: '100%',
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "overlay",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: {
    minHeight: "0px",
  },
  content: {
    flex: 1,
    overflow: 'overlay'
  },
  container: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  logo: {
    maxHeight: "40px", // Fix IE 11 issue.
  },
  buttonLogoff: {
    margin: "-3px -5px -4px 0px"
  },
  navMenu: {
    listStyle: "none",
    display: "flex",
    position: "absolute",
    left: "85px"
  },
  navMenuAdmin: {
    listStyle: "none"
  },
  navConectionCircle: {
    listStyle: "none",
    display: "flex",
    position: "absolute",
    right: "80px",
    top: "5px"
  },
  navMenuAdminDrop: {
    listStyle: "none",
    display: "flex",
    position: "absolute",
    right: "75px"
  },
  navMenuMain: {
    marginTop: "-45px",
    '@media(max-width: 540px)': {
      display: "none",
    }
  },
  navMenuMainMobile: {
    marginTop: "-49px",
    '@media(min-width: 540px)': {
      display: "none",
    }
  },
  navMenuWidth: {
    '@media(min-width: 540px)': {
      display: "none",
    }
  },
  tabsRoot: {
    borderBottom: '1px solid #e8e8e8',
  },
  tabsIndicator: {
    backgroundColor: '#24ab8b',
  },
  tabRoot: {
    textTransform: 'initial',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing.unit * 4,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#1fa988',
      opacity: 1,
    },
    '&$tabSelected': {
      color: '#24ab8b',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#1fa988',
    },
  },
  tabSelected: {},
  typography: {
    padding: theme.spacing.unit * 3,
  },
  ktsvg: {
    "& svg": {
      stroke: '#bdbdbd!important'
    }
  }
}));

const LoggedInAtendimento = ({ children }, props) => {
  const [connectionWarning, setConnectionWarning] = useState(false);
  const classes = useStyles();
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const { handleLogout, loading } = useContext(AuthContext);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerVariant, setDrawerVariant] = useState("permanent");
  const { user } = useContext(AuthContext);
  const { drawerClose } = props;
  const { whatsApps } = useContext(WhatsAppsContext);
  const [relatoryAnchorEl, setRelatoryAnchorEl] = useState(null);
  const [openRelatory, setOpenRelatory] = React.useState(false);
  var [value, setValue] = React.useState(0);

  var pagina = value
  useEffect(() => {
    if (document.body.offsetWidth > 600) {
      setDrawerOpen(true);
    }
  }, []);

  //verificar a pagina que está e adicionar indicativo no header (Não estava funcionando no código padrão para a pagina de contatos)
  useEffect(() => {
    var path = (window.location.pathname);
    if (path === '/tickets') {
      setValue(0)
    } else if (path === '/contacts') {
      setValue(1)
    } else if (path === '/') {
      setValue(2)
    } else if (path === '/ticketsRelatory') {
      setValue(3)
    } else if (path === '/campaign') {
      setValue(4)
    } else {
      setValue(5)
    }
  });


  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (whatsApps.length > 0) {
        const offlineWhats = whatsApps.filter((whats) => {
          return ((
            whats.status === "qrcode" ||
            whats.status === "PAIRING" ||
            whats.status === "DISCONNECTED" ||
            whats.status === "TIMEOUT" ||
            whats.status === "OPENING"
          ) && whats.adminId == user.company.id);
        });
        if (offlineWhats.length > 0) {
          setConnectionWarning(true);
        } else {
          setConnectionWarning(false);
        }
      }
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [whatsApps]);

  useEffect(() => {
    if (document.body.offsetWidth < 600) {
      setDrawerVariant("temporary");
    } else {
      setDrawerVariant("permanent");
    }
  }, [drawerOpen]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleOpenUserModal = () => {
    setUserModalOpen(true);
    handleCloseMenu();
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const handleClickLogout = () => {
    handleCloseMenu();
    handleLogout(user?.id || '', user?.adminId || '');
  };

  const handleClickRelatory = (event) => {
    setRelatoryAnchorEl(event.currentTarget);
  };

  const handleCloseRelatory = () => {
    setRelatoryAnchorEl(null);
  };

  const handleClickRelatoryMobile = () => {
    setOpenRelatory(!openRelatory);
  };

  if (loading) {
    return <BackdropLoading />;
  }

  return (
    <div 
      className={classes.root}
    >
      <UserModal
        open={userModalOpen}
        onClose={() => setUserModalOpen(false)}
        userId={user?.id}
      />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, drawerOpen && classes.appBarShift)}
        color={process.env.NODE_ENV === "development" ? "inherit" : "primary"}
      >
        <Toolbar variant="dense" className={classes.toolbar}>

          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end'
          }}>
            <img className={classes.logo} src={Logo} alt="WhatsAll" />
            <p style={{
              margin: '0px 0px 0px 5px',
              fontStyle: 'italic',
              color: '#292d41',
              fontSize: '10px'
            }}>{process.env.REACT_APP_VERSION_SYSTEM}</p>
          </div>
          {user.profile === 'adminSuper' ? ''
            : <>
              <div className={classes.navMenuMain} id="navbarItens">
                <div className={classes.navMenu} style={{ display: 'none' }}>
                  <ListItemLink
                    to="/tickets"
                    primary={i18n.t("mainDrawer.listItems.tickets")}
                  />
                  <ListItemLink
                    to="/contacts"
                    primary={i18n.t("mainDrawer.listItems.contacts")}
                  />

                  <ListItemLink
                    to="/"
                    primary="Dashboard"
                  />

                  <ListItemLink
                    to="/ticketsRelatory"
                    primary="Relatório"
                  />
                  {user.campaignManagement &&
                    <>
                      <ListItemLink
                        to="/campaign"
                        primary={i18n.t("mainDrawer.listItems.campaings")}
                      />
                    </>}
                </div>

                <div className={classes.navMenu} style={{ margin: '8px 0px 0px 50px' }}>
                  <Tabs
                    value={pagina}
                    onChange={(event, newValue) => {
                      setValue(newValue);
                    }}

                    classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
                  >
                    <Tab
                      disableRipple
                      style={{ marginTop: '-9px' }}
                      classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                      component={Link} to='/tickets' label="Atendimento"
                    />
                    <Tab
                      disableRipple
                      style={{ marginTop: '-9px' }}
                      classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                      component={Link} to='/contacts' label="Contatos"
                    />
                    <Tab
                      disableRipple
                      style={{ marginTop: '-9px' }}
                      classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                      component={Link} to='/' label="Dashboard"
                    />
                    <Tab
                      disableRipple
                      style={{ marginTop: '-9px' }}
                      classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                      onClick={(event) => { handleClickRelatory(event) }}
                      label={i18n.t("mainDrawer.listItems.relatory.title")}
                    />
                    <Menu
                      anchorEl={relatoryAnchorEl}
                      open={Boolean(relatoryAnchorEl)}
                      onClose={handleCloseRelatory}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left"
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left"
                      }}
                      style={{ marginTop: "40px" }}
                    >
                      <MenuItem dense component={Link} to='/ticketsRelatory'>{i18n.t("mainDrawer.listItems.relatory.services")}</MenuItem>
                      <MenuItem dense component={Link} to='/tagsRelatory'>{i18n.t("mainDrawer.listItems.relatory.tags")}</MenuItem>
                      <MenuItem dense component={Link} to='/generalCampaignsRelatory'>{i18n.t("mainDrawer.listItems.relatory.campaigns")}</MenuItem>
                    </Menu>
                    {user.campaignManagement === true ?
                      <>
                        <Tab
                          disableRipple
                          style={{ marginTop: '-9px' }}
                          classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                          component={Link} to='/campaign' label="Campanhas"
                        />
                      </> : ''}
                  </Tabs>
                </div>
                <>
                  <div className={classes.navMenuAdminDrop}>
                    {user?.queueManagement == true || user?.campaignManagement == true ?
                      <>
                        <IconButton
                          aria-label="account of current user"
                          aria-controls="menu-appbar"
                          aria-haspopup="true"
                          onClick={handleMenu}
                          color="inherit"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24"><g><path d="M0,0h24v24H0V0z" fill="none" /><path d="M19.14,12.94c0.04-0.3,0.06-0.61,0.06-0.94c0-0.32-0.02-0.64-0.07-0.94l2.03-1.58c0.18-0.14,0.23-0.41,0.12-0.61 l-1.92-3.32c-0.12-0.22-0.37-0.29-0.59-0.22l-2.39,0.96c-0.5-0.38-1.03-0.7-1.62-0.94L14.4,2.81c-0.04-0.24-0.24-0.41-0.48-0.41 h-3.84c-0.24,0-0.43,0.17-0.47,0.41L9.25,5.35C8.66,5.59,8.12,5.92,7.63,6.29L5.24,5.33c-0.22-0.08-0.47,0-0.59,0.22L2.74,8.87 C2.62,9.08,2.66,9.34,2.86,9.48l2.03,1.58C4.84,11.36,4.8,11.69,4.8,12s0.02,0.64,0.07,0.94l-2.03,1.58 c-0.18,0.14-0.23,0.41-0.12,0.61l1.92,3.32c0.12,0.22,0.37,0.29,0.59,0.22l2.39-0.96c0.5,0.38,1.03,0.7,1.62,0.94l0.36,2.54 c0.05,0.24,0.24,0.41,0.48,0.41h3.84c0.24,0,0.44-0.17,0.47-0.41l0.36-2.54c0.59-0.24,1.13-0.56,1.62-0.94l2.39,0.96 c0.22,0.08,0.47,0,0.59-0.22l1.92-3.32c0.12-0.22,0.07-0.47-0.12-0.61L19.14,12.94z M12,15.6c-1.98,0-3.6-1.62-3.6-3.6 s1.62-3.6,3.6-3.6s3.6,1.62,3.6,3.6S13.98,15.6,12,15.6z" /></g></svg>
                        </IconButton>
                      </> : ''
                    }
                    <Grid style={{ display: "flex" }}>
                      {whatsApps.map((whats, index) => {
                        if (whats.status === 'CONNECTED') {
                          return (
                            <div className={classes.navConectionCircle} style={{ width: "50%" }} title="Conectado" key={index}>
                              <ListItem>
                                <ListItemIcon>
                                  <CircleIcon style={{ color: 'rgb(0, 255, 0, 0.5)', fontSize: '22px' }} />
                                </ListItemIcon>
                              </ListItem>
                            </div>
                          );
                        } else if (whats.status === 'qrcode' && index === 0) {
                          let numActiveConnections = 0;
                          for (let i = 0; i < whatsApps.length; i++) {
                            if (whatsApps[i].status === 'CONNECTED') {
                              numActiveConnections++;
                            }
                            if (numActiveConnections > 1) {
                              break;
                            }
                          }
                          if (numActiveConnections <= 1) {
                            return (
                              <div className={classes.navConectionCircle} style={{ width: "50%" }} title="Desconectado" key={index}>
                                <ListItem>
                                  <ListItemIcon>
                                    <CircleIcon style={{ color: 'rgb(255, 0, 0)', fontSize: '22px' }} />
                                  </ListItemIcon>
                                </ListItem>
                              </div>
                            );
                          }
                        }
                      })}
                    </Grid>
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorEl}
                      getContentAnchorEl={null}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={menuOpen}
                      onClose={handleCloseMenu}
                    >
                      <div className={classes.navMenuAdmin}>

                        <>
                          <Divider />
                          <ListSubheader>
                            {i18n.t("mainDrawer.listItems.administration")}
                          </ListSubheader>
                          <Can
                            role={user.profile}
                            perform="drawer-admin-items:messagesAPI"
                            yes={() => (
                              <>
                                <ListItemLink
                                  to="/messages-api"
                                  primary={i18n.t("mainDrawer.listItems.messagesAPI")}
                                  icon={<CodeRoundedIcon />}
                                />
                              </>
                            )}
                          />
                          <Can
                            role={user.profile}
                            perform="drawer-admin-items:view"
                            yes={() => (
                              <>
                                <ListItemLink
                                  to="/openingHour"
                                  primary={i18n.t("mainDrawer.listItems.openingHours")}
                                  icon={<AccessTimeIcon />}
                                />
                              </>
                            )}
                          />
                          <Can
                            role={user.profile}
                            perform="drawer-admin-items:view"
                            yes={() => (
                              <>
                                <ListItemLink
                                  to="/users"
                                  primary={i18n.t("mainDrawer.listItems.users")}
                                  icon={<PeopleAltOutlinedIcon />}
                                />
                              </>
                            )}
                          />
                          {user.queueManagement === true ?
                            <>
                              <ListItemLink
                                to="/queues"
                                primary={i18n.t("mainDrawer.listItems.queues")}
                                icon={<AccountTreeOutlinedIcon />}
                              />
                            </> : ''}

                          {user.queueManagement === true ?
                            <>
                              <ListItemLink
                                to="/connections"
                                primary={i18n.t("mainDrawer.listItems.connections")}
                                icon={
                                  <Badge badgeContent={connectionWarning ? "!" : 0} color="error">
                                    <SyncAltIcon />
                                  </Badge>
                                }
                              />
                            </> : ''}
                          <Can
                            role={user.profile}
                            perform="drawer-admin-items:view"
                            yes={() => (
                              <>
                                <ListItemLink
                                  to="/companySignature"
                                  primary={i18n.t("mainDrawer.listItems.packages")}
                                  icon={<PaidIcon />}
                                />
                              </>
                            )}
                          />
                          <Can
                            role={user.profile}
                            perform="drawer-admin-items:view"
                            yes={() => (
                              <>
                                <ListItemLink
                                  to="/conclusionMessages"
                                  primary={i18n.t("mainDrawer.listItems.finalizations")}
                                  icon={<CheckCircleIcon />}
                                />
                              </>
                            )}
                          />
                          <Can
                            role={user.profile}
                            perform="drawer-admin-items:view"
                            yes={() => (
                              <>
                                <ListItemLink
                                  to="/quickAnswers"
                                  primary={i18n.t("mainDrawer.listItems.quickAnswers")}
                                  icon={<QuestionAnswerOutlinedIcon />}
                                />
                                {/*
                                <ListItemLink
                                  to="/chatbot"
                                  primary="Chatbot"
                                  icon={<SmartToyIcon />}
                                />
                           */}
                              </>
                            )}
                          />
                          <Can
                            role={user.profile}
                            perform="drawer-admin-items:settings"
                            yes={() => (
                              <>
                                <ListItemLink
                                  to="/settings"
                                  primary={i18n.t("mainDrawer.listItems.settings")}
                                  icon={<SettingsOutlinedIcon />}
                                />
                              </>
                            )}
                          />
                          <Can
                            role={user.profile}
                            perform="drawer-admin-items:company"
                            yes={() => (
                              <>
                                <ListItemLink
                                  to="/company"
                                  primary={i18n.t("mainDrawer.listItems.company")}
                                  icon={<SettingsOutlinedIcon />}
                                />
                              </>
                            )}
                          />
                        </>
                        <Can
                          role={user.profile}
                          perform="drawer-admin-items:view"
                          yes={() => (
                            <>
                              <ListItemLink
                                to="/tags"
                                primary={i18n.t("mainDrawer.listItems.tags")}
                                icon={<LabelIcon />}
                              />
                            </>
                          )}
                        />
                        <Can
                          role={user.profile}
                          perform="drawer-admin-items:view"
                          yes={() => (
                            <>
                              <ListItemLink
                                to="/integrations"
                                primary={i18n.t("mainDrawer.listItems.integrations")}
                                icon={<ShareIcon />}
                              />
                            </>
                          )}
                        />
                        {
                          CheckSupportEmail(user.email)
                          &&
                          <Can
                            role={user.profile}
                            perform="drawer-admin-items:view"
                            yes={() => (
                              <>
                                <ListItemLink
                                  to="/chatbot"
                                  primary={i18n.t("mainDrawer.listItems.chatbot")}
                                  icon={<KTSVG path='/media/icons/duotune/technology/teh011.svg' className={classes.ktsvg} />}
                                />
                              </>
                            )}
                          />
                        }
                      </div>
                    </Menu>
                  </div>
                </>
              </div>
              <div className={classes.navMenuMainMobile} id="navbarItensMobile">
                <div className={classes.navMenuAdminDrop}>

                  {user.queueManagement == true || user.campaignManagement == true ?
                    <>
                      <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="inherit"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24"><g><path d="M0,0h24v24H0V0z" fill="none" /><path d="M19.14,12.94c0.04-0.3,0.06-0.61,0.06-0.94c0-0.32-0.02-0.64-0.07-0.94l2.03-1.58c0.18-0.14,0.23-0.41,0.12-0.61 l-1.92-3.32c-0.12-0.22-0.37-0.29-0.59-0.22l-2.39,0.96c-0.5-0.38-1.03-0.7-1.62-0.94L14.4,2.81c-0.04-0.24-0.24-0.41-0.48-0.41 h-3.84c-0.24,0-0.43,0.17-0.47,0.41L9.25,5.35C8.66,5.59,8.12,5.92,7.63,6.29L5.24,5.33c-0.22-0.08-0.47,0-0.59,0.22L2.74,8.87 C2.62,9.08,2.66,9.34,2.86,9.48l2.03,1.58C4.84,11.36,4.8,11.69,4.8,12s0.02,0.64,0.07,0.94l-2.03,1.58 c-0.18,0.14-0.23,0.41-0.12,0.61l1.92,3.32c0.12,0.22,0.37,0.29,0.59,0.22l2.39-0.96c0.5,0.38,1.03,0.7,1.62,0.94l0.36,2.54 c0.05,0.24,0.24,0.41,0.48,0.41h3.84c0.24,0,0.44-0.17,0.47-0.41l0.36-2.54c0.59-0.24,1.13-0.56,1.62-0.94l2.39,0.96 c0.22,0.08,0.47,0,0.59-0.22l1.92-3.32c0.12-0.22,0.07-0.47-0.12-0.61L19.14,12.94z M12,15.6c-1.98,0-3.6-1.62-3.6-3.6 s1.62-3.6,3.6-3.6s3.6,1.62,3.6,3.6S13.98,15.6,12,15.6z" /></g></svg>
                      </IconButton>
                    </> : ''
                  }
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={menuOpen}
                    onClick={(event) => {
                      if (event.target.classList.contains("relatorySubMenu")) { return; }
                      handleCloseMenu();
                      setOpenRelatory(false);
                    }}
                  >
                    <div className={classes.navMenuWidth} >
                      <ListItemLink
                        to="/"
                        primary="Dashboard"
                      />
                      <ListItemLink
                        to="/tickets"
                        primary={i18n.t("mainDrawer.listItems.tickets")}
                      />
                      <ListItemLink
                        to="/contacts"
                        primary={i18n.t("mainDrawer.listItems.contacts")}
                      />
                      <ListItem onClick={() => { handleClickRelatoryMobile() }} className="relatorySubMenu" >
                        <ListItemText
                          disableTypography
                          primary={<Typography variant="span" className="relatorySubMenu">{i18n.t("mainDrawer.listItems.relatory.title")}</Typography>}
                          className="relatorySubMenu"
                        />
                        {openRelatory ? <ExpandLess className="relatorySubMenu" /> : <ExpandMore className="relatorySubMenu" />}
                      </ListItem>
                      <Collapse in={openRelatory} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          <ListItemLink to="/ticketsRelatory" primary={i18n.t("mainDrawer.listItems.relatory.services")} />
                        </List>
                        <List component="div" disablePadding>
                          <ListItemLink to="/tagsRelatory" primary={i18n.t("mainDrawer.listItems.relatory.tags")} />
                        </List>
                        <List component="div" disablePadding>
                          <ListItemLink to="/generalCampaignsRelatory" primary={i18n.t("mainDrawer.listItems.relatory.campaigns")} />
                        </List>
                      </Collapse>
                      {user.campaignManagement === true ?
                        <>
                          <ListItemLink
                            to="/campaign"
                            primary={i18n.t("mainDrawer.listItems.campaings")}
                          />
                        </> : ''}
                    </div>
                    <>
                      <Divider />
                      <ListSubheader>
                        {i18n.t("mainDrawer.listItems.administration")}
                      </ListSubheader>
                      <Can
                        role={user.profile}
                        perform="drawer-admin-items:messagesAPI"
                        yes={() => (
                          <>
                            <ListItemLink
                              to="/messages-api"
                              primary={i18n.t("mainDrawer.listItems.messagesAPI")}
                              icon={<CodeRoundedIcon />}
                            />
                          </>
                        )}
                      />
                      {user.queueManagement === true ?
                        <>
                          <ListItemLink
                            to="/queues"
                            primary={i18n.t("mainDrawer.listItems.queues")}
                            icon={<AccountTreeOutlinedIcon />}
                          />
                        </> : ''}
                      <Can
                        role={user.profile}
                        perform="drawer-admin-items:view"
                        yes={() => (
                          <>
                            <ListItemLink
                              to="/openingHour"
                              primary={i18n.t("mainDrawer.listItems.openingHours")}
                              icon={<AccessTimeIcon />}
                            />
                          </>
                        )}
                      />
                      <Can
                        role={user.profile}
                        perform="drawer-admin-items:view"
                        yes={() => (
                          <>
                            <ListItemLink
                              to="/users"
                              primary={i18n.t("mainDrawer.listItems.users")}
                              icon={<PeopleAltOutlinedIcon />}
                            />
                          </>
                        )}
                      />
                      {user.queueManagement === true ?
                        <>
                          <ListItemLink
                            to="/connections"
                            primary={i18n.t("mainDrawer.listItems.connections")}
                            icon={
                              <Badge badgeContent={connectionWarning ? "!" : 0} color="error">
                                <SyncAltIcon />
                              </Badge>
                            }
                          />
                        </> : ''}
                      <Can
                        role={user.profile}
                        perform="drawer-admin-items:view"
                        yes={() => (
                          <>
                            <ListItemLink
                              to="/companySignature"
                              primary={i18n.t("mainDrawer.listItems.packages")}
                              icon={<PaidIcon />}
                            />
                          </>
                        )}
                      />
                      <Can
                        role={user.profile}
                        perform="drawer-admin-items:view"
                        yes={() => (
                          <>
                            <ListItemLink
                              to="/conclusionMessages"
                              primary={i18n.t("mainDrawer.listItems.finalizations")}
                              icon={<CheckCircleIcon />}
                            />
                          </>
                        )}
                      />
                      <Can
                        role={user.profile}
                        perform="drawer-admin-items:view"
                        yes={() => (
                          <>
                            <ListItemLink
                              to="/quickAnswers"
                              primary={i18n.t("mainDrawer.listItems.quickAnswers")}
                              icon={<QuestionAnswerOutlinedIcon />}
                            />
                            {/*
                                <ListItemLink
                                  to="/chatbot"
                                  primary="Chatbot"
                                  icon={<SmartToyIcon />}
                                />
                           */}
                          </>
                        )}
                      />
                      <Can
                        role={user.profile}
                        perform="drawer-admin-items:settings"
                        yes={() => (
                          <>
                            <ListItemLink
                              to="/settings"
                              primary={i18n.t("mainDrawer.listItems.settings")}
                              icon={<SettingsOutlinedIcon />}
                            />
                          </>
                        )}
                      />
                      <Can
                        role={user.profile}
                        perform="drawer-admin-items:company"
                        yes={() => (
                          <>
                            <ListItemLink
                              to="/company"
                              primary={i18n.t("mainDrawer.listItems.company")}
                              icon={<SettingsOutlinedIcon />}
                            />
                          </>
                        )}
                      />
                      <Can
                        role={user.profile}
                        perform="drawer-admin-items:view"
                        yes={() => (
                          <>
                            <ListItemLink
                              to="/tags"
                              primary={i18n.t("mainDrawer.listItems.tags")}
                              icon={<LabelIcon />}
                            />
                          </>
                        )}
                      />
                      <Can
                        role={user.profile}
                        perform="drawer-admin-items:view"
                        yes={() => (
                          <>
                            <ListItemLink
                              to="/integrations"
                              primary={i18n.t("mainDrawer.listItems.integrations")}
                              icon={<ShareIcon />}
                            />
                          </>
                        )}
                      />
                      {
                        CheckSupportEmail(user.email)
                        &&
                        <Can
                          role={user.profile}
                          perform="drawer-admin-items:view"
                          yes={() => (
                            <>
                              <ListItemLink
                                to="/chatbot"
                                primary={i18n.t("mainDrawer.listItems.chatbot")}
                                icon={<KTSVG path='/media/icons/duotune/technology/teh011.svg' className={classes.ktsvg} />}
                              />
                            </>
                          )}
                        />
                      }
                    </>

                  </Menu>
                </div>
              </div>
            </>}
          <IconButton onClick={() => { handleClickLogout() }}>
            <div title="Sair" className={classes.buttonLogoff}>
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-box-arrow-right" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z" />
                <path fillRule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
              </svg>
            </div>
          </IconButton>
        </Toolbar >
      </AppBar >
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        {children ? children : null}
      </main>
    </div >
  );
};

export default LoggedInAtendimento;